import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import {
  GET_ALL_FOLLOWUP,
  LEADS_HISTORY_QUERY,
} from "../../../services/leads.gql";
import { useApolloClient } from "@apollo/client";
import { Tab, Tabs } from "react-bootstrap";
import DateFormate from "dateformat";

const FollowUpHistoryForm = ({activeTab= "all"}) => {
  const [followUpHistory, setFollowUpHistory] = useState({});
  const [key, setKey] = useState(activeTab);

  const { masterdata, lead, remarksHistory, leadDetail } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead,
      leadDetail: lead?.leadDetail?.id,
      remarksHistory: lead.remarksHistory || [],
    })
  );

  const client = useApolloClient();

  const getActivityHistoryData = async (lead_id, user_id) => {

      const getAllFollowUpPromise = new Promise(resolve => resolve(executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client)));
      const leadsHistoryPromise = new Promise(resolve => resolve(executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client)));

      const [allFollowsData, leadsHistoryData] = await Promise.all([getAllFollowUpPromise, leadsHistoryPromise]);

      setFollowUpHistory({
        followupdata: allFollowsData?.data?.get_followup,
        loanhistorydata: leadsHistoryData?.data?.get_history
      });
  };

  useEffect(() => {
    const lead_id = lead?.leadDetail?.id || "";
    const user_id = lead?.leadDetail?.customer?.customer_id;
    getActivityHistoryData(lead_id, user_id);
  }, [lead]);

  return (
    <>
      <div 
      className="fullhisrorycard"
      >
        <div 
        className="fullhisrorybox"
        >
          <h2 style={{ marginTop: 20, marginBottom: 20 }}>Activity History</h2>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="all" title="All">

              {/* STATUS UPDATE */}
              {followUpHistory?.loanhistorydata?.map((item, index) => (
                <div className="followupcard" key={index}>
                  <div className="followupflex">
                    <div>{item.title}</div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div> Updated By : </div>
                        <div style={{ fontWeight: 500, marginLeft: 5 }}>
                          {item.user_details.added_by_name}
                        </div>
                      </div>
                      <div>
                        <div
                          // className="date"
                          style={{ fontSize: 13, color: "#000" }}
                        >
                          <span>
                            {item.created_date
                              ? DateFormate(item.created_date, "dd mmm")
                              : ""}
                            <span
                              style={{
                                marginLeft: 10,
                                fontSize: 9,
                                color: "#bbfbfbf",
                              }}
                            >
                              {item.created_date
                                ? DateFormate(item.created_date, "h:MM TT")
                                : ""}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <strong>Followup Pending With</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.followup_with}</div>
                  </div>
                  <div>
                    <strong>Comments</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.comment}</div>
                  </div>
                </div>
              ))}

              {/* FOLLOW UPS */}
              {followUpHistory?.followupdata?.map((item, index) => (
                <div className="followupcard" key={index}>
                  <div className="followupflex">
                    <div>{item.followup_type}</div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div> Updated By : </div>
                        <div style={{ fontWeight: 500, marginLeft: 5 }}>
                          {""}
                        </div>
                      </div>
                      <div>
                        <div
                          // className="date"
                          style={{ fontSize: 13, color: "#000" }}
                        >
                          <span>
                            {item.followup_date
                              ? DateFormate(item.followup_date, "dd mmm")
                              : ""}
                            <span
                              style={{
                                marginLeft: 10,
                                fontSize: 9,
                                color: "#bbfbfbf",
                              }}
                            >
                              {item.followup_date
                                ? DateFormate(item.followup_date, "h:MM TT")
                                : ""}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <strong>Comments</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.comment}</div>
                  </div>
                </div>
              ))}
            </Tab>
            <Tab
              eventKey="followups"
              title="Follow Ups"
            >
              {followUpHistory?.followupdata?.map((item, index) => (
                <div className="followupcard" key={index}>
                  <div className="followupflex">
                    <div>{item.followup_type}</div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div> Updated On : </div>
                        <div style={{ fontWeight: 500, marginLeft: 5 }}>
                          {""}
                        </div>
                      </div>
                      <div>
                        <div
                          // className="date"
                          style={{ fontSize: 13, color: "#000" }}
                        >
                          {/* { item.followup_status==='2' ?
                            <span>
                              {item.followup_date
                                ? DateFormate(item.updated_date, "dd mmm")
                                : ""}
                              <span
                                style={{
                                  marginLeft: 10,
                                  fontSize: 9,
                                  color: "#bbfbfbf",
                                }}
                              >
                                {item.followup_date
                                  ? DateFormate(item.updated_date, "h:MM TT")
                                  : ""}
                              </span>
                            </span> : 
                            <span>
                              {item.followup_date
                                ? DateFormate(item.followup_date, "dd mmm")
                                : ""}
                              <span
                                style={{
                                  marginLeft: 10,
                                  fontSize: 9,
                                  color: "#bbfbfbf",
                                }}
                              >
                                {item.followup_date
                                  ? DateFormate(item.followup_date, "h:MM TT")
                                  : ""}
                              </span>
                            </span>
                          } */}
                          <span>
                              {item.followup_date
                                ? DateFormate(item.updated_date, "dd mmm")
                                : ""}
                              <span
                                style={{
                                  marginLeft: 10,
                                  fontSize: 9,
                                  color: "#bbfbfbf",
                                }}
                              >
                                {item.followup_date
                                  ? DateFormate(item.updated_date, "h:MM TT")
                                  : ""}
                              </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <strong>Followup Status</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.followup_status==='2' ? 'Done': item.followup_status==='1' ? 'Reschedule' :' Created'}</div>
                  </div>
                  <div>
                    <strong>Pendency On</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.followup_with}</div>
                  </div>
                  <div>
                    <strong>Comments</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.comment}</div>
                  </div>
                </div>
              ))}
            </Tab>
            <Tab
              eventKey="status"
              title="Status Update"
            >
              {followUpHistory?.loanhistorydata?.map((item, index) => (
                <div className="followupcard" key={index}>
                  <div className="followupflex">
                    <div>{item.title}</div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div> Updated By : </div>
                        <div style={{ fontWeight: 500, marginLeft: 5 }}>
                          {item.user_details.added_by_name}
                        </div>
                      </div>
                      <div>
                        <div
                          // className="date"
                          style={{ fontSize: 13, color: "#000" }}
                        >
                          <span>
                            {item.created_date
                              ? DateFormate(item.created_date, "dd mmm")
                              : ""}
                            <span
                              style={{
                                marginLeft: 10,
                                fontSize: 9,
                                color: "#bbfbfbf",
                              }}
                            >
                              {item.created_date
                                ? DateFormate(item.created_date, "h:MM TT")
                                : ""}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <strong>Followup Pending With</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.followup_with}</div>
                  </div>
                  <div>
                    <strong>Comments</strong>
                  </div>
                  <div className="folloupcomment">
                    <div>{item.comment}</div>
                  </div>
                </div>
              ))}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default FollowUpHistoryForm;
