import React, { useState } from "react";
import SupportPartnerInfo from "./SupportPartnerInfo";
import SupportSearchFilter from "./SupportSearchFilter";
import SupportListing from "./SupportListing";

const SupportPartnerListing = () => {
  const [searcFilter, setSearchFilter] = useState({});
  const [buttonClick, setButtonClick] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchFilters = (value) => {
    setSearchFilter(value);
  };

  const handleButtonClick = () => {
    setButtonClick((prev) => prev + 1);
  };

  const handleLoading = (isLoadingApply) => {
    setIsLoading(isLoadingApply);
  }

  return (
    <>
      <div className="container-fluid">
        <SupportPartnerInfo handleSearchFilters={handleSearchFilters} />
        <SupportSearchFilter
          handleSearchFilters={handleSearchFilters}
          handleButtonClick={handleButtonClick}
          handleExportLoading={handleLoading}
        />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35vh",
              width: "100%",
            }}
          >
            <div className="loading result-wrap"></div>
          </div>
        ) : (
          <SupportListing searcFilter={searcFilter} buttonClick={buttonClick} />
        )}
      </div>
    </>
  );
};

export default SupportPartnerListing;
