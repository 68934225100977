import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import leadReducer from './reducers/leadReducer';
import masterDataReducer from './reducers/masterDataReducer'
import { loadStateFromLocalStorage, saveStateToLocalStorage } from './localStorage';

const persistedState = loadStateFromLocalStorage();

const store = configureStore({
  reducer: {
    user: userReducer,
   lead: leadReducer,
    masterdata: masterDataReducer
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveStateToLocalStorage(store.getState());
});



export default store;