import { gql } from "@apollo/client";

export const BANK_ONBOARDING_PAYIN_LIST = (page_number) =>  gql`
  query payin_config_list($filter_params: ConfigPayinFiltersArgs!) {
    payin_config_list(filter_params: $filter_params, page_no: ${page_number}) {
      payinlist {
        financer_id
        bank_name
        is_on_payin
        is_partnership
        logo
        total_payout
      }
      pagination {
      totalpage
      totalrecords
      nextpage
      prevpage
      __typename
    }
    total_count
    productData {
        product_type
        bank_id
        dsa_code
    }
    }
  }
`;


export const UPDATE_BANK_ONBOARDING_PAYIN = gql`
  mutation save_on_payin($addpayinconfigargs: AddPayinConfigArgs!) {
    save_on_payin(addpayinconfigargs: $addpayinconfigargs)
  }
`;

export const MIS_LIST = (page_number) => gql`
  query mis_list($filter_params: LeadMisFiltersArgs!) {
    mis_list(filter_params: $filter_params, page_no: ${page_number}) {
      list {
        id
        lead_id
        tranche_id
        bank_id
        loan_type
        disbursed_amount
        monthly_cycle_payin
        payin_base_per
        base_payin_amount
        booster_payin_per
        booster_payin_amount
        total_amount
        booster_payin_confirmed
        confirmed_amount
        comments
        created_at
        is_calculated
      }
      pagination {
        page_no
        prevpage
        totalpage
        totalrecords
        nextpage
      }
    }
  }
`;

export const UPLOAD_MIS_LIST = gql`
  mutation mis_upload($uploadPaymentArgs: UploadMisArgs!) {
    mis_upload(uploadPaymentArgs: $uploadPaymentArgs)
  }
`;


export const REPORTING_STATUS = (user_id) => gql`
  query{
    reporting_status(api_called_by:web,user_id:${user_id},report_filter_params:{}){
      id
      month
      month_name
      year
      created_date
      reporting_status
      total_lead_count
      total_disbursed_lead
      total_disbursed_amount
    }
      masterdata(api_called_by:web,is_partnership:"0"){
        report_month_list{
          id
          label
        }
        report_year_list{
          id
          label
        }
        report_status_list{
          id
          label
        }
      }
  }
`;
export const LOAN_ID_REPORT = (report_id, user_id) => gql`
query{
  loan_id_report(api_called_by:web, user_id:${user_id}, report_id:${report_id}){
    lead_id
    created_date
    login_date
    disbursed_date
    disbursed_amount
    tranche_id
    fulfillment_type
    product_type
    product_sub_type
    partner_id
    partner_name
    sub_partner_name
    rm_name
    customer_name
    bank_name
    transaction_date
  }
}
`;

export const CLOSED_REPORTING = (user_id, year) => gql`
  query{
    get_closed_reporting_month(api_called_by:web,user_id:${user_id},year:${year}){
      months
      year
    }
  }
`;