import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IS_USER_ACCESS_PAGE,
} from "../../../../config/constants";

const ActionButtonComponent = (props) => {

  let import_partner_lead = IS_USER_ACCESS_PAGE(
    "partner",
    "import_partner_lead"
  )?.is_edit_access;

  const handleActiveButton = (tab) => {
    props.handleTabChange(tab)
  };
  let parter_dashboard = IS_USER_ACCESS_PAGE("discarded_tab", "discarded_tab")?.is_edit_access;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", justifyContent: "start", marginLeft: 10 }}>
        {props.activeTab === 'leads' || props.activeTab === 'discarded'||  props.activeTab === 'registered'|| props.activeTab === 'chatList' ? (
          <div>
          <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth: props.activeTab === "leads" ? "2px" : "0px",
            }}
            onClick={() => handleActiveButton("leads")}
          >
            Leads
          </button>
         {parter_dashboard === 1 && <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth: props.activeTab === 'discarded' ? "2px" : "0px",
            }}
            onClick={() => handleActiveButton('discarded')}
          >
            Discarded
          </button>}
          <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth: props.activeTab === "registered" ? "2px" : "0px",
            }}
            onClick={() => handleActiveButton("registered")}
          >
            Registered
          </button>
          {/* <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth: props.activeTab === "chatList" ? "2px" : "0px",
            }}
            onClick={() => handleActiveButton("chatList")}
          >
           Unread Message
          </button> */}
        </div>
        ) : 
          <button
          style={{
            ...styles.wiredButton,
          }}
          onClick={() => handleActiveButton("leads")}
        >
          Result
        </button>
        }
        
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {import_partner_lead === 1 && (
          <Link to={"/pre-register-lead-import"} target="_blank">
            <div>
              <button
                style={styles.addPartnerButton}
                className="submit mrg-r10"
              >
                Import Leads
              </button>
            </div>
          </Link>
        )}

        <Link to={"/partner-register?pre_leads"} target="_blank">
          <div>
            <button style={styles.addPartnerButton} className="submit">
              Add Partner Lead
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  addPartnerButton: {
    backgroundColor: "#6147FF",
    color: "#fff",
    borderRadius: "10px 10px 0 0",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
  },
  wiredButton: {
    backgroundColor: "transparent",
    color: "#6147FF",
    borderTop: "0px",
    borderLeft: "0px",
    borderRight: "0px",
    borderBottom: "2px solid #6147FF",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    marginRight: "10px",
  },
};
export default ActionButtonComponent;
