import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MultiSelect from "../elements/MultiSelect.js";
import { executeGraphQLMutation,executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";
import {
  BANK_ONBOARDING_PAYIN_LIST,
  UPDATE_BANK_ONBOARDING_PAYIN,
} from "../../services/mis.gql";
import { DELETE_PAYOUT_POINT, GET_PAYOUT_POINTS_WITH_FILTERS} from "../../services/payout.gql";
import { Add_BANK, SAVE_PRODUCT_DSA_CODE, FINEX_BANK_LIST, FINEX_MASTER_LIST } from "../../services/scheme.gql";
import CheckBox from "../elements/Radiobox";
import { toast } from "react-toastify";
import Select from "react-select";
import InputField from "../elements/InputField";
import secureStorage from "../../config/encrypt";
import { Link, NavLink, Navigate } from "react-router-dom";
import Pagination from "../elements/pagination";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import Modal from "../elements/payoutModal";
import BookingPoint from "../bank/BookingPoint";

const BankOnboarding = (props) => {

  
  const [bankOnboardList, setBankOnboardList] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [payout_points_list, setPayoutPointsList] = useState([]);
  const [paginationdata, setPaginations] = useState({}); 
  const [productData,setProductData] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [product_type, setProductType] = useState([]);  
  const [copySuccess, setCopySuccess] = useState('');
  const [refreshList, setRefreshList] = useState(false);
  const [bankId, setBankId] = useState(null);
  
  

  
  let bank_create =  IS_USER_ACCESS_PAGE("payout", "bank_create")?.is_edit_access;

  const getBankOnboardingPayinList = async (filter_params,page_number = 1) => {
    try {        
      const variables = { filter_params };
      variables.page_no = page_number;
      const response = await executeGraphQLMutation(
        BANK_ONBOARDING_PAYIN_LIST(page_number),
        variables
      );
      if (response.data?.payin_config_list[0]) {
        const payInList = response.data?.payin_config_list[0]?.payinlist;          
      //  console.log("payInList" , payInList);
       // setBankOnboardPoints(mappedObj);        
        setBankOnboardList(payInList);
        setPaginations(response?.data?.payin_config_list[0]?.pagination)
        setProductData(response?.data?.payin_config_list[0]?.productData)
      }else{
        setBankOnboardList([]);
        setPaginations({})
        setProductData({})
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  const afterLoginSetState = (loginResponse) => {
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
        return <Navigate to={"/"} />;
    }
  };

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
        return <Navigate to={"/login"} />;
    }
  };

  useEffect(() => {
    updateReduxData();
    getBankOnboardingPayinList(filterParams);
    getFinexMasterData();
  }, []);

  useEffect(() => {
    if(refreshList===true){
      getBankOnboardingPayinList(filterParams,1)
      getPayoutPoints(1, bankId)
      setRefreshList(false)
    }
  }, [refreshList])

 
  const handleFilterByDSA = (event) => {
    const { value } = event.target;

    setFilterParams((current) => ({
      ...current,
      dsa_code: value.toUpperCase(),
    }));
  };

  

  const resetFilter = () => {
    window.location.reload();
  };

  const getPayoutPoints = async (page_no,bank_id) => {
    try {
        const filterData = {};
        filterData.page_no = page_no
        filterData.bank_id = +bank_id
        const responseData = await executeGraphQLMutation(GET_PAYOUT_POINTS_WITH_FILTERS, {
            "filter_params": filterData
        })
        if (responseData?.data.payout_points_list) {
            setPayoutPointsList(responseData?.data?.payout_points_list[0]?.data)
           // setPaginations(responseData?.data?.payout_points_list[0]?.pagination)
        }else{
            setPayoutPointsList([])
            setPaginations([])
        }

    } catch (error) {
      console.log(error)
    }
}

const getFinexMasterData = async () => {
  const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
  if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const data = masterData.data.masterdata;
      const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
          bank_list.map(banks => {
              bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
          })
          setFinancers(bankData)
          if (products && products.length > 0) {
              products.map(ptype => {
                  producttypedata.push({ id: ptype.id, name: ptype.label })
              })
              setProductType(producttypedata)
          }
      }
  }
}


  const handleShowDetail = (event) => {    
    const id = event.target.getAttribute("dataid");
   // console.log("bank_id",id);
    setBankId(id)
    getPayoutPoints(1,id);   
    setModalVisiblity(true);
  }
  const handleFilterApply = () => {
    getBankOnboardingPayinList(filterParams);
  };

  const onPaginate = (pageNumber) => {    
    getBankOnboardingPayinList(filterParams,pageNumber);
   // getPayoutPoints(pageNumber)
};

const handleMultiSelectChange = (
  fieldName,
  selectedOptArr,
  isMultiSel,
  OptVal,
  OptLabel
) => {
  const selectedBanks = selectedOptArr.map((obj) => obj[OptVal]);
  setFilterParams((current) => ({
    ...current,
    financer_id: selectedBanks,
  }));
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    setCopySuccess('Copied!');
  } catch (err) {
    setCopySuccess('Failed to copy!');
  }
};


  return (
    <>
    {modalVisiblity && (
        <>
          <div className="view-timeline-popup">
            <Modal
              show={modalVisiblity}
              removeHeight={true}
              handleClose={() => setModalVisiblity(false)}
            >
              <BookingPoint payout_points_list={payout_points_list} setRefreshList={setRefreshList}/>
            </Modal>
          </div>
        </>
      )}

      <div className="container-fluid">
        <h2 className="top-heading">Bank Onboarding</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select Financer</label>
              <MultiSelect 
                options={financers}
                placeholder={"Banks"}
                name={'financier_id'}
                label="Bank"
                optionLabel="name"
                optionValue="id"
                id="multiSelect"
                values={filterParams["financer_id"] || []}
                onChange={handleMultiSelectChange}
                isMulti
              /> 
            </div>
            {/* <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "payout_per",
                  type: "text",
                  name: "payout_per",
                  autocompleate: "off",
                  label: "Enter DSA Code",
                  placeholder: "DSA Code",
                }}
                onChange={handleFilterByDSA}
                value={filterParams["dsa_code"] || ""}
              />

            
            </div> */}

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleFilterApply}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilter}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="text-right" style={{margin:"30px 0px"}}>
        {bank_create === 1 && (
                          <NavLink to="/bank/addpartnership" className="add-schemebtn" style={{marginRight: "10px"}}>
                          {('Make Partner')}
                      </NavLink>
                    )}

                    {bank_create === 1 && (
                          <NavLink to="/bank/addpayoutpoint" className="add-schemebtn">
                          {('Add Payout points')}
                      </NavLink>
                    )}
                         
                        </div>

        <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize" style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th>{"Financer Id"}</th>
                    <th>{"Bank Name"}</th>
                    <th>{"Is Partner"}</th>
                    <th>{"Payout Pts"}</th>
             
                    <th>{"logo"}</th>
                  
                    {/* <th>{"Action"}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {bankOnboardList.map((point, index) => (
                    <>
                      <tr key={index}>
                        <td>{point.financer_id}</td>
                        <td>{point.bank_name}</td>
                        <td>{point.is_partnership == 1 ? "Yes" : "No"}</td>
                        <td>
                        {point.total_payout >0 ?  <a onClick={handleShowDetail} dataid={point.financer_id}>  {point.total_payout}</a> : 0}
                        </td>
                        {/* <td>
                        {point.hl_dsa_code} {point.hl_dsa_code ?  <i  className="ic-content_copy icons-normal" onClick={()=>copyToClipboard(point.hl_dsa_code)}  style={{cursor:"pointer"}} title="copy" ></i> : null }
                        </td>
                        <td>
                        {point.lap_dsa_code} {point.lap_dsa_code ?  <i  className="ic-content_copy icons-normal" onClick={()=>copyToClipboard(point.lap_dsa_code)}  style={{cursor:"pointer"}} title="copy" ></i> :null }
                        </td>
                        <td>{point.payout_calc_date}</td>
                        <td>{point.payout_calc_amount}</td> */}
                        <td>
                        <img src={point.logo} height="30" />
                        
                        </td>
                        {/* <td> */}
                      
                          {/*
                            <CheckBox
                              type="checkbox"
                              name="checkall"
                              id={`${point.financer_id}_${point.is_on_payin}`}
                              checked={
                                isNaN(
                                  +bankOnboardPoints[point.financer_id]
                                    ?.is_on_payin
                                )
                                  ? 0
                                  : +bankOnboardPoints[point.financer_id]
                                      ?.is_on_payin
                              }
                              onChange={handleChange}
                            />*/
                          }
                        {/* </td> */}
                        { /* <td>
                        <div className="btn-group btn-group-small mrg-r5">
                                                    <div className="btn btn-default">
                                                        <i  className="ic-content_copy icons-normal" title="Copy Duplicate"></i>
                                                    </div>
                                                   
                                                </div>
                          <div>
                            <button
                              className="add-schemebtn"
                              onClick={handleUpdateOnboardPoint}
                              disabled={
                                bankOnboardPoints[point.financer_id]?.save_btn
                              }
                              type="button"
                              id={point.financer_id}
                            >
                              Update
                            </button>
                          </div>
                        
                        </td> */}
                      </tr>
                                     
                    </>
                  ))}
 { 
                                        <tr>
                                        <td colSpan={4}> <Pagination pageCount={paginationdata && paginationdata.totalpage ? paginationdata.totalpage : 0} activePage={1} onPaginate={onPaginate} /></td>
                                        <td>Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords : 0} </td>
                                    </tr>


                                      }
                                      
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(BankOnboarding)
);
