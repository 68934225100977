import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { DASHBOARD_QUERY, INSURANCE_DASHBOARD_QUERY } from '../../services/dashboard.gql';
import { useApolloClient } from '@apollo/client';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import crypto from "../../config/crypto";
import Loader from '../elements/Loader';
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE,MAKER_ROLE_ID} from '../../config/constants';
import Modal from '../elements/Modal';
import LeadsCreateForm from '../leads/bulk-create-leads/LeadsCreateForm';
import { GET_USER_LIST } from "../../services/role.gql";
import Select from 'react-select';
import MisUpload from '../insurance/MisUpload';

const Dashboard = () => {

  const [dashboard, setDashboard] = useState([]);
  const [insuranceDashboard, setInsuranceDashboard] = useState([]);
  const [fulfillment_type, setFulfillmentType] = useState('self');
  const [isLoading, setIsLoading] = useState(false);
  const [showLeadCreate,setShowLeadCreate] = useState(false);
  const [showMISUpload,setShowMISUpload] = useState(false);
  const [userList, setUserList] = useState([]);
  const [assignTo, setAssignTo] = useState({assign_to:0});
  const [activeTab, setActiveTab] = useState("loan");
  const randomCardColorId = () => Math.floor(Math.random() * 5) + 1;
  const card_id = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'one',
    7: 'two',
    8: 'five',
    9: 'three',
    10: 'four',
    11: 'two',
    12: 'five',
    13: 'three',
    14: 'one',
    15: 'four',
    16: 'five',
    17: 'two',
    18: 'three'
  }
  const client = useApolloClient();
  const user_information = useSelector((state) => state.user.user_information);

  const accessCondition = IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_edit_access
  || IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_access_page;


  const UserInfo = JSON.parse(user_information);
  let name = UserInfo?.name ? UserInfo.name : "";
  let userId = (UserInfo && UserInfo.id) ? UserInfo.id : 1;

  let selfAccess = IS_USER_ACCESS_PAGE("self_fullfill_bucket", "self_fullfill_bucket")?.is_edit_access;
  let ambakAccess = IS_USER_ACCESS_PAGE("ambak_fullfill_backet", "ambak_fullfill_backet")?.is_edit_access; 
  let leadAccess = IS_USER_ACCESS_PAGE("create_lead", "create_lead").is_edit_access;
  let leadAssignAccess = IS_USER_ACCESS_PAGE("assign_lead", "assign_lead").is_edit_access; 

  useEffect(() => {
    if(USERS_FOR_VIEW_AMBAK_LEADS.includes(UserInfo?.email) || selfAccess !== 1) {
      setFulfillmentType('ambak');
      getDashboardCount('ambak', 0, activeTab);
    } else {
      getDashboardCount(fulfillment_type, 0, activeTab);
    }
    getUserList();
  }, [user_information, userId]); 

  const getUserList = async () => {
    let user_list = [],userlist=[];
    await executeGraphQLQuery(GET_USER_LIST(5,1),client).then((response) => {
      if (response && response.data && response.data.get_user_list) {
        user_list = response.data.get_user_list?.filter(obj => obj.status === '1');
        user_list && user_list?.map((v)=>{
          userlist.push({value:v.user_id,label:v.name,role_id:v.role_assigned_ids})
          return v;
        })
        setUserList(userlist);
      }
     }).catch((error) => {
      console.log(error);
    });
  };


  const getFullFillmentType = (fulfillment_type) => {
    setFulfillmentType(fulfillment_type);
    getDashboardCount(fulfillment_type, 0, activeTab);
  }

  const getDashboardCount = async (fulfillment_type,assign_to = 0, activeTab) => {
    if (userId) {
      setIsLoading(true);
      if (activeTab === "loan") {
        executeGraphQLQuery(DASHBOARD_QUERY(userId, fulfillment_type, assign_to), client, 1)
        .then((res) => {
          const response = res.data?.lead_dashboard?.map((data) => ({
            ...data,
            color_id: randomCardColorId()
          }));
          setDashboard(response);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
      } else if (activeTab === "insurance") {
        executeGraphQLQuery(INSURANCE_DASHBOARD_QUERY(fulfillment_type), client)
        .then((res) => {
          const response = res.data?.insurance_lead_dashboard?.map((data) => ({
            ...data,
            color_id: randomCardColorId()
          }));
          setInsuranceDashboard(response);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
      }
    }
  }


  const showModalViewTimeLine = () => {
    setShowLeadCreate(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setShowLeadCreate(false);
    document.body.classList.remove("overflow-hidden");
    window.location.reload();
  };
  
  const showMISUploadModal = () => {
    setShowMISUpload(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideMISUploadModal = () => {
    setShowMISUpload(false);
    document.body.classList.remove("overflow-hidden");
    window.location.reload();
  };

  let dashboardList  = (fulfillment_type =='self') ? dashboard?.filter(ob=>ob.sub_type !='website') : dashboard; 
  let maker_user_list = userList.filter(data => data.role_id.includes(MAKER_ROLE_ID) && data.value != UserInfo?.id); 

  const handleChange = async (n, v, isMulti, opt, optl) => {
         getDashboardCount('ambak',v.value, activeTab);
        setAssignTo({[n]:v.value});
  }

  const base64EncodeId = (id) => {
    return btoa(id.toString());
  }

  const handleSelectTab = (tab) => {
    setActiveTab(tab);
  }
  
  return (
    <div className="dashboard-bg">
      <div className="dashboard-outer">
        <div className="heading">
          <span className="subheading">Hello {name},</span>
          {
            leadAccess === 1 || leadAssignAccess  === 1 ? 
            <span className='createleads'>
              <div className='createbtn' onClick={showMISUploadModal} style={{ marginRight: "10px" }}> Upload MIS </div>
              <div className='createbtn' onClick={showModalViewTimeLine}> Create And Assign Leads </div>
            </span>
            : null
          }
          
          <h1>Welcome to Ambak Home Loan</h1>
        </div>
        {(!USERS_FOR_VIEW_AMBAK_LEADS.includes(UserInfo?.email) ?
        <div className='dashboardcommon'>
          <div className="commonbtn">
          <button className={`self-btn ${fulfillment_type === 'self' ? 'active' : ''} ${selfAccess === 1 ? '' : 'hidden'}`} onClick={() => getFullFillmentType('self')}>Self Fulfillment</button>
          <button className={`self-btn ${fulfillment_type === 'ambak' ? 'active' : ''} ${ambakAccess === 1 ? '' : 'hidden'}`} onClick={() => getFullFillmentType('ambak')}>Ambak Fulfillment</button>
          </div>
        </div>:"")}
       { fulfillment_type === 'ambak' ? (
        <div className='commonselectbtn'>
                <fieldset className="single-select" style={{width:"220px", marginBottom:"12px"}}>
                  <div className="material" >            
                    <Select
                      options={accessCondition ===1 ? maker_user_list : []}
                      placeholder={"Select E2E Sales"}
                      name={'assign_to'}
                      optionLabel="label"
                      optionValue="value"
                      className='react-select-table'
                      classNamePrefix='react-select-table'
                      value={userList.filter((v) => (v.value === assignTo.assign_to)) }
                      onChange={(e) => handleChange("assign_to", e)}
                    />
                  </div>
                  </fieldset>
              </div> 
       ) : ""}


        <div className="dashboiuiuard-toggle-tab">
          
          {isLoading ? <Loader /> : <></>}
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>

            <Nav variant="pills" className="flex-column" style={{ justifyContent: "center" }}>
              <div className="tab-list">
                <Nav.Item>
                  <Nav.Link eventKey="loan" onClick={() => getDashboardCount(fulfillment_type, 0, "loan")}>Loan</Nav.Link>
                </Nav.Item>
                 <Nav.Item>
                  <Nav.Link eventKey="insurance" onClick={() => getDashboardCount(fulfillment_type, 0, "insurance")}>Insurance</Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
            <Tab.Content>
              { fulfillment_type == "self" ?
              <Tab.Pane key="loan" eventKey="loan">
                <div className="dashboard-card">
                  {dashboardList?.map((reportList, index) => (
                    <React.Fragment key={index}>
                      <Link
                        to={reportList.lead_count > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode( (reportList?.filter_params?.source ? 'source-'+reportList?.filter_params?.source : reportList?.filter_params?.is_revisit ? 'is_revisit-'+reportList?.filter_params?.is_revisit : reportList?.filter_params?.is_otp_verified ?  "is_otp_verified-"+reportList?.filter_params?.is_otp_verified : null)  || reportList?.filter_params?.status_id || 'followup' )}` : ''}
                      >
                        <div
                          className={`card color-${card_id[index + 1]}`}
                        >
                          <div className="roung-bg">
                            {reportList.card_styles && (
                              <i
                                className={
                                  reportList.card_styles.icon_className
                                }
                              >
                                {reportList.card_styles.max_icon_paths
                                  ? Array.from(
                                    Array(
                                      reportList.card_styles.max_icon_paths
                                    ).keys()
                                  ).map((i) => (
                                    <i className={`path${i + 1}`} key={i}></i>
                                  ))
                                  : null}
                              </i>
                            )}
                          </div>
                          <span className="count-txt">
                            {reportList.lead_count ? reportList.lead_count : 0}
                          </span>
                          <span className="card-txt">{reportList.name === 'Submitted' ? 'New Application' : reportList.name}</span>
                        </div>
                      </Link>
                    </React.Fragment>
                  ))}
                </div>
              </Tab.Pane> :

              <Tab.Pane eventKey="loan" key="loan">
                <div className='dashboardforyou'>
                  <h1 className='dashboardheading'>For You</h1>
                  <div className='dashboard-cardnew'>
                    {dashboardList?.map((reportList, index) => {
                      let linkUrl = (reportList?.filter_params?.source ? 'source-'+reportList?.filter_params?.source : reportList?.filter_params?.is_revisit ? 'is_revisit-'+reportList?.filter_params?.is_revisit : reportList?.filter_params?.is_otp_verified ?  "is_otp_verified-"+reportList?.filter_params?.is_otp_verified : null) || reportList?.filter_params?.status_id || reportList?.name || 'followup' ; 

                      return <React.Fragment key={index}>
                      { (reportList.name=="Follow Ups" && reportList.header=="Overdue") || (reportList.name=="New Leads" && reportList.header=="Fresh") || (reportList.name=="Contacted" && reportList.header=="Interested") || (reportList.name=="Pre Login" && reportList.header=="Qualified") ?
                      <Link to={null}>
                        <div className={`card color-${card_id[index + 1]}`}>
                          <div className="roung-bg">
                            {reportList.card_styles && (
                              <i
                                className={
                                  reportList.card_styles.icon_className
                                }
                              >
                                {reportList.card_styles.max_icon_paths
                                  ? Array.from(
                                    Array(
                                      reportList.card_styles.max_icon_paths
                                    ).keys()
                                  ).map((i) => (
                                    <i className={`path${i + 1}`} key={i}></i>
                                  ))
                                  : null}
                              </i>
                            )}
                          </div>
                          {reportList.name=="Follow Ups" ?
                            <span className="count-txt">
                              <div className='count-text-flex'> 
                                  <div className='count-show-text cl-black'>
                                  <Link to={reportList.lead_count > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(2)}` : ''}>
                                    <div >{reportList?.lead_count}</div> 
                                    <div className='statustextfont'>{reportList.header}</div>  
                                  </Link>
                                  </div>
                                <div className='count-show-text cl-black'>
                                <Link to={dashboardList?.filter(data=>data?.header === "Upcoming").map(v=>v.lead_count)[0] > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(1)}` : ''}>
                                  <div>{dashboardList.filter(data=>data.header === "Upcoming").map(v=>v.lead_count)[0]}</div>
                                  <div className='statustextfont'>{dashboardList.filter(data=>data.header === "Upcoming").map(v=>v.header)[0]}</div>
                                  </Link>
                                </div>
                              </div>
                            </span>:""
                          }
                          {reportList.name=="New Leads" ? 
                            <span className="count-txt">
                              <div className='count-text-flex'>
                                <div className='count-show-text cl-black'>
                                <Link to={reportList.lead_count > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(5)}` : ''}>
                                  <div >{reportList?.lead_count}</div>
                                  <div className='statustextfont'>{reportList.header}</div>
                                  </Link>
                                </div>
                                <div className='count-show-text cl-black'>
                                <Link to={dashboardList?.filter(data=>data?.header == "Not Connected").map(v=>v.lead_count)[0] > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(6)}` : ''}>
                                  <div>{dashboardList.filter(data=>data.header == "Not Connected").map(v=>v.lead_count)[0]}</div>
                                  <div className='statustextfont'>{dashboardList.filter(data=>data.header == "Not Connected").map(v=>v.header)[0]}</div>
                                  </Link>
                                </div>
                              </div>
                            </span>:""
                          }
                          {(reportList.name=="Contacted") ? 
                            <span className="count-txt">
                              <div className='count-text-flex'>
                                <div className='count-show-text cl-black'>
                                <Link to={reportList.lead_count > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(7)}` : ''}>
                                  <div >{reportList?.lead_count}</div>
                                  <div className='statustextfont'>{reportList.header}</div>
                                  </Link>
                                </div>
                                <div className='count-show-text cl-black'>
                                 <Link to={dashboardList?.filter(data=>data?.header == "Not Interested").map(v=>v.lead_count)[0] > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(8)}` : ''}>
                                  <div>{dashboardList.filter(data=>data.header == "Not Interested").map(v=>v.lead_count)[0]}</div>
                                  <div className='statustextfont'>{dashboardList.filter(data=>data.header == "Not Interested").map(v=>v.header)[0]}</div>
                                  </Link>
                                </div>
                              
                              </div>
                            </span>:""
                          }
                          {
                            (reportList.name=="Pre Login" && reportList.header=="Qualified") ? 
                            <span className="count-txt">
                              <div className='count-text-flex'>
                                {(reportList.header=="Qualified") ?
                                <div className='count-show-text cl-black'>
                                  <Link to={reportList.lead_count > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(3)}` : ''}>
                                  <div >{reportList?.lead_count}</div>
                                  <div className='statustextfont'>{reportList.header}</div>
                                  </Link>
                                </div>:""}
                                <div className='count-show-text cl-black'>
                                <Link to={dashboardList?.filter(data=>data?.header == "Docs Collected").map(v=>v.lead_count)[0] > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}/${crypto.encode(4)}` : ''}>
                                  <div>{dashboardList.filter(data=>data.header == "Docs Collected").map(v=>v.lead_count)[0]}</div>
                                  <div className='statustextfont'>{dashboardList.filter(data=>data.header == "Docs Collected").map(v=>v.header)[0]}</div>
                                  </Link>
                                </div>
                              </div>
                            </span>:""
                          }
                          <span className="card-txt">{reportList.name === 'Submitted' ? 'New Application' : reportList.name}</span>
                        </div>
                      </Link>:""
                      }
                      </React.Fragment>
                    })}
                  </div>
                </div>
                <div className='dashboardflex'>
                  {<div className='dashboardnewcard'>
                    <h1 className='dashboardheading'>Post-Login</h1>
                    <div className="dashboard-card">
                      {dashboardList?.map((reportList, index) => {
                      let linkUrl = (reportList?.filter_params?.source ? 'source-'+reportList?.filter_params?.source : reportList?.filter_params?.is_revisit ? 'is_revisit-'+reportList?.filter_params?.is_revisit : reportList?.filter_params?.is_otp_verified ?  "is_otp_verified-"+reportList?.filter_params?.is_otp_verified : null) || reportList?.filter_params?.status_id || reportList?.name || 'followup' ; 
 
                      let newUrl = (reportList.name =="Website Lead") ? '?source=5': (reportList.name =='ReVisited') ? '?is_revisit=1' : (reportList.name =='OTP Verified') ? '?is_otp_verified=1' : '';
                      
                      return <React.Fragment key={index}>
                        { reportList.name=="Logged In" || reportList.name=="Sanctioned" || reportList.name=="Disbursed" || reportList.name=="Lost" || reportList.name=="Website Lead" || reportList.name=="ReVisited" || reportList.name=="OTP Verified" ?
                          <Link to={reportList.lead_count > 0 ? `/lead-list/${fulfillment_type}/${base64EncodeId(assignTo.assign_to)}/${crypto.encode(linkUrl)}`+newUrl : ''}>
                            <div className={`card color-${card_id[index + 1]}`}>
                              <div className="roung-bg">
                                {reportList.card_styles && (
                                  <i className={reportList.card_styles.icon_className}>
                                    {reportList.card_styles.max_icon_paths
                                      ? Array.from(
                                        Array(
                                          reportList.card_styles.max_icon_paths
                                        ).keys()
                                      ).map((i) => (
                                        <i className={`path${i + 1}`} key={i}></i>
                                      ))
                                      : null}
                                  </i>
                                )}
                              </div>
                              <span className="count-txt">
                                {reportList.lead_count ? reportList.lead_count : 0}
                              </span>
                              <span className="card-txt">{reportList.name === 'Submitted' ? 'New Application' : reportList.name}</span>
                            </div>
                          </Link>: ""
                        }
                        </React.Fragment>
                      })}
                    </div>
                  </div>}
                </div>
              </Tab.Pane> }

              {/* INSURANCE */}
              <Tab.Pane key="insurance" eventKey="insurance">  
                <div className="dashboard-card">
                  {insuranceDashboard?.map((reportList, index) => (
                    <React.Fragment key={index}>
                      <Link to={""} >
                        <div
                          className={`card color-${card_id[index + 1]}`}
                        >
                          <div className="roung-bg">
                            {reportList.card_styles && (
                              <i
                                className={
                                  reportList.card_styles.icon_className
                                }
                              >
                                {reportList.card_styles.max_icon_paths
                                  ? Array.from(
                                    Array(
                                      reportList.card_styles.max_icon_paths
                                    ).keys()
                                  ).map((i) => (
                                    <i className={`path${i + 1}`} key={i}></i>
                                  ))
                                  : null}
                              </i>
                            )}
                          </div>
                          <span className="count-txt">
                            {reportList.lead_count ? reportList.lead_count : 0}
                          </span>
                          <span className="card-txt">{reportList.name === 'Submitted' ? 'New Application' : reportList.name}</span>
                        </div>
                      </Link>
                    </React.Fragment>
                  ))}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <Modal show={showLeadCreate} handleClose={hideModalViewTimeLine} >
          <LeadsCreateForm/>
        </Modal>
        
        {/* Upload MIS modal */}
        <Modal show={showMISUpload} handleClose={hideMISUploadModal} >
          <MisUpload/>
        </Modal>

      </div>
    </div>
  )
}
export default Dashboard;