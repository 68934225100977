const conf = {
    "DEALER_SUPPORT_EMAIL": "help@ambak.com",
    "DEALER_SUPPORT_PHONE":  "805-805-8009",
    "SITE_NAME": "ambak.com",
    "SITE_ADDRESS":  "Ardee City Sector-52 Gurgaon Haryana 122002",
    "LOGO": "logo.svg",
    "MOBILE_LOGIN_IMAGE": "mobile_login2.png",
    "GOOGLE_PLAY": "",
    "APP_STORE":  "",
    "MOBILE_VALIDATION":"^[6-9]\\d{9}$",
    "BANK_ACCOUNT_NUMBER" : "^[0-9a-zA-Z]+$",
    "BRANCH_NAME" : "^[0-9a-zA-Z,.(),' ']+$",
    "BENIFICIERY_NAME" : "^[0-9a-zA-Z,' ']+$",
    "CURRENCY_SYMBOL": "INR. ",
    "CURRENCY_SEPARATOR": ".",
    "CURRENCY_DECIMAL_SEPARATOR": ",",
    "COUNTRY_CODE": "ind",
    "SITE_COPYRIGHT": "copyright_id",
    "INVENTORY_REG_DETAIL":true,
    "MAKE_MONTH":true,
    "REG_MONTH":true,
    "TRANSLATION_REG_YEAR_LABEL":"reg_year",
    "STOCK_LIST_CLASSIFIED_TOOL_TIP":"classified_icon_msg",
    "ALLOW_WHATSAPP_SHARE": true,
    "CAR_MAKE_YEAR_MONTH_LABEL":"make_month_year_title_id",
    "VALID_MOBILE_MSG":"valid_mobile",
    "CAR_SITE_MSG":"car_site",
    "DP_BUDGET": true,
    "FAV_ICON": "favicon.ico",
    "MOBILE_PREFIX" : "",
    "TYPE": "oto",
    "DEFAULT_CATEGORY_FLAG":true,
    "MIN_STOCK_IMAGE_LIMIT": 4,
    "DISABLE_CERTIFICATION_STATUS_FIELD": false,
    "DISABLE_REGISTRATION_NUMBER_TYPE": false,
    "WEBSITE_NAME": "ambak.com",
    "SITE_ID_DURING_CLASSIFIED":true,
    "MIN_CAR_PRICE": 10000000,
    "BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG":true

};
// const conf = allConf[process.env.REACT_APP_CONFIG_FILE_NAME] ? allConf[process.env.REACT_APP_CONFIG_FILE_NAME] : allConf.demo;
export const API_URL = {
    GETWAY_API: process.env.REACT_APP_GETWAY_API,
    GOOGLE_AUTH_URL:process.env.GOOGLE_AUTH_URL || "http://stage-sos.ambak.com/google?redirect=",
    FINEX_API_URL:process.env.FINEX_API_URL || "https://api-stage.ambak.com/finex/api/v1"
}

export const OWNER = [
    { value: '0', label: { 'en': '1st Owner', 'id': '1st Owner'} },
    { value: '1', label: { 'en': '2nd Owner', 'id': '2nd Owner' } },
    { value: '2', label: { 'en': '3rd Owner', 'id': '3rd Owner' } },
    { value: '3', label: { 'en': '4th Owner', 'id': '4th Owner' } },
    { value: '4', label: { 'en': 'Four +', 'id': 'Four +' } }
];

export const MONTH = [
    { value: 1, label: {en: 'Jan', id: 'Jan' }},
    { value: 2, label: {en: 'Feb', id: 'Feb' } },
    { value: 3, label: {en: 'Mar', id: 'Mar' } },
    { value: 4, label: {en: 'Apr', id: 'Apr' } },
    { value: 5, label: {en: 'May', id: 'May' } },
    { value: 6, label: {en: 'June', id: 'June' } },
    { value: 7, label: {en: 'July', id: 'July' } },
    { value: 8, label: {en: 'Aug', id: 'Aug' } },
    { value: 9, label: {en: 'Sep', id: 'Sep' } },
    { value: 10, label: {en: 'Oct', id: 'Oct' } },
    { value: 11, label: {en: 'Nov', id: 'Nov' } },
    { value: 12, label: {en: 'Dec', id: 'Dec' } }
];

export const APIV = 2;


export const STOCK_PAGE_LIMIT = 100;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
export const PASSWORD_VALIDATION=/^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/;
export const MOBILE_VALIDATION=new RegExp(conf.MOBILE_VALIDATION);
export const BANK_ACCOUNT_NUMBER=new RegExp(conf.BANK_ACCOUNT_NUMBER);
export const BRANCH_NAME=new RegExp(conf.BRANCH_NAME);
export const BENIFICIERY_NAME=new RegExp(conf.BENIFICIERY_NAME);
export const CONF_VAL =conf;
export const BUMP_UP_LIMIT = 1 ;
export const BUMP_UP_EXPIRY_HOURS = 2 ;
export const DESCRIPTION_FIELD_MIN_LENGTH = 100 ;
export const TOTAL_MGF_YEAR = 1970;
export const MIN_STOCK_IMAGE_LIMIT = conf.MIN_STOCK_IMAGE_LIMIT;
export const DISABLE_CERTIFICATION_STATUS_FIELD = conf.DISABLE_CERTIFICATION_STATUS_FIELD;
export const DISABLE_REGISTRATION_NUMBER_TYPE = conf.DISABLE_REGISTRATION_NUMBER_TYPE;
export const WEBSITE_NAME = conf.WEBSITE_NAME;
export const CURRENCY_SYMBOL = conf.CURRENCY_SYMBOL;
export const COUNTRY_CODE = conf.COUNTRY_CODE;
export const SITE_ID_DURING_CLASSIFIED = conf.SITE_ID_DURING_CLASSIFIED;
export const BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG = conf.BUYER_ENQUIRY_MULTI_SELECT_ENABLE_FLAG;
export const DEALER_WALLET_ID = 39;
export const FINEX_BACKEND_API_KEY = "VK7KD-DDOPQ-DE97AM-83MPHT-3V66TFI-FI345";
//export const FINEX_BACKEND_API_KEY = "";
export const API_KEY_PARTNER = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJtb2JpbGUiOiI5NTQwODA0NjQ2Iiwia2luZCI6IjYwMDAwIiwiaWF0IjoxNjk1NzM5ODExLCJleHAiOjE2OTgzMzE4MTF9.Q3Bz7klUL2FhljEpApf2accUrEROOsVP-7dGTtOKOUA";

export const RoleId = {
	"1": "Agent"
}
export const OWNER_ID = 9

export const BursaDetails = [
    {
        id: 0,
        label: "Non-Bursa",
    },
    {
        id: 1,
        label: "Bursa",
    },
];
