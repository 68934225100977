import React, { useEffect, useState, useRef } from "react";
import { toast } from 'react-toastify';
import MasterService from "../../services/MasterService";
import { Add_SCHEME, Add_Scheme_Range, GET_SCHEMA_LIST_BYID, Update_SCHEME, GET_SCHEMA_LIST_BY_CODE } from "../../services/scheme.gql";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { NavLink,Link,useParams,Navigate } from 'react-router-dom';
import DateFormate from 'dateformat';
//import PayoutRanges from "./PayoutRanges";
import secureStorage from '../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AddNewPartner from "./AddNewPartner";
import AddNewBank from "./AddNewBank";

const AddPartnerShip = (props) => {

    const param = useParams()
    // console.log(props.match.params)
    const scheme_code = param.scheme_id_hash ? param.scheme_id_hash : "";
    const currentTab = param.tab ? param.tab : "new_bank";
    const [formData, setFormData] = useState({
        city_id: [],
        scheme_type: "base",
        scheme_name: "",
        start_date: "",
        end_date: "",
        scheme_for: ""
    });
   
    const [selectstatus, setselectstatus] = useState("");    
    const [errors, setErrors] = useState("");
    const [activeTab, setActiveTab] = useState(param.tab ? param.tab : "new_bank");
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);
    const childRef = useRef(null);

    useEffect(() => {
        //getCityList()
        if (scheme_code && scheme_code != "") {
            getSchemeListBYCode(scheme_code)
        }
        setActiveTab(currentTab)
        // updateReduxData()
       
    }, []);

   

    const getSchemeListBYCode = async (scheme_code) => {
        const schemeData = await executeGraphQLQuery(GET_SCHEMA_LIST_BY_CODE(scheme_code));
        if (schemeData && schemeData.data && schemeData.data.schemeByCode) {
            setFormData(schemeData.data.schemeByCode);
        }
    }    

    const handleTabClick = (tabId) => {
       setActiveTab(tabId);
        let url ="";
        if(scheme_code){
         url = "/bank/"+scheme_code+"/"+tabId;
        }else{
            url = "/bank/"+tabId; 
        }
        console.log("url",url);
       return (<Navigate to={url} />);
     //   window.location.href = url;
    };

    return (

        <>

            <div className="container-fluid">
                <div className="search-wrap">
                
                <div>
                     {/* {JSON.stringify(formData)} */}
                    <ul className="list-inline nav-tabs">
                        
                        <li
                            className={activeTab === 'new_bank' ? 'tabs-item active' : 'tabs-item'}
                            onClick={() => handleTabClick('new_bank')}
                        >
                            Add New Bank
                        </li>
                        
                        <li
                            className={activeTab === 'new_partner_ship' ? 'tabs-item active' : 'tabs-item'}
                            onClick={() => handleTabClick('new_partner_ship')}
                        >
                           Add New Partner Ship
                        </li> 
                        
                    </ul>
            </div>
            </div>
                    <div className="tab-content">
                        {activeTab === 'new_bank' && <div>
                            <AddNewBank ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick}></AddNewBank>
                        </div>
                        }
                        {activeTab === 'new_partner_ship' && <div>
                            <AddNewPartner ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick}></AddNewPartner>
                        </div>                       
                        }
                    </div>
               
                </div>
           

        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(AddPartnerShip));
//export default AddScheme;