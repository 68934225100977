import React from 'react';
import { withFormsy, addValidationRule } from 'formsy-react';
import { PatternFormat, NumericFormat } from 'react-number-format';
import "react-datepicker/dist/react-datepicker.css";

// Added common validation rule for all phone numbers
addValidationRule("isValidPhoneNumber", (values, value) => {
  if (value) {
    value = value.trim();
    if (value[0] !== '9' && value[0] !== '8' && value[0] !== '7' && value[0] !== '6') {
      return "Phone number must start with 6 to 9";
    }
    if (value.length > 10) {
      return "Phone number must be of 10 digits";
    }
  }
  return true;
});

addValidationRule("isValidTelephonePhoneNumber", (values, value) => {
  if (value) {
    value = value.trim();
    if (value.length !== 11) return "Must be of 11 digits";
  }
  return true;
})

addValidationRule("isValidPinCode", (values, value) => {
  if (value) {
    value = value.trim();
    const pinValidet = /^\d{1,6}$/.test(value); // Allow up to 6 digits
    if (!pinValidet) return "Invalid pincode.";
  }
  return true;
});

addValidationRule("isValidAddharCard", (values, value) => {
  if (value) {
    value = value.trim().replace(/\s/g,''); 
    // const addharValidet = /^\d{12}$/.test(value);
    const addharValidet = /^[X]{8}\d{4}$/.test(value);
    if(!addharValidet) return "Invalid aadhar number(should be like XXXXXXXX1234)"
  }
  return true;
})

addValidationRule("isValidnoOfEMI", (values, value) => {
  if (value) {
    value = value;
    if (value > 360){
      return "No of Emil should not be more than 360"; 
    }  
  }
  return true;
})

addValidationRule("isValidAnnualIncome", (values, value) => {
  if (value) {
    value = value.trim();
    if (value < 199999){
      return "Min value should be more than 199999"; 
    }    
  }
  return true;
})

addValidationRule("allowNonNegativeNumberOnly", (values, value) => {
  if (value) {
    value = value.trim();
    if (value < 0){
      return "Min value should be more than 0"; 
    }    
  }
  return true;
})

addValidationRule("isValidPropertyValue", (values, value) => {
  if (value) {
    value = value.trim();
    if (value < 0){  
      return "Min value should be more than 1000000";  
    }   
  }
  return true;
})

// addValidationRule("isValidAnnualIncome", (values, value) => {
//   if (value) {
//     value = value.trim();
//     const annualIncomeValidet = /^\d+$/.test(value);
//     if(!annualIncomeValidet) return "Amount not string must be digit. !"
//   }
//   return true;
// })

addValidationRule("isValidPanCard", (values, value) => {
  if (value) {
    value = value.trim();
    const panValidet = /^[A-Z]{3}[CHFATBLJGP][A-Z]\d{4}[A-Z]$/.test(value); // Ensure fourth letter matches specified characters
    if (!panValidet) return "Invalid PAN card number.";
  }
  return true;
});

// addValidationRule("isValidPanCard", (values, value) => {
//   if (value) {
//     value = value.trim();
//     if (value.length !== 10) return "Invalid PAN card number length.";
    
//     const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
//     if (!panPattern.test(value)) return "Invalid PAN card number pattern.";

//     const fourthLetter = value.charAt(3);
//     const validFourthLetters = ['C','H','F','A','T','B','L','J','G','P'];
//     if (!validFourthLetters.includes(fourthLetter)) return "Invalid fourth letter.";

//     // Store field based on fourth letter
//     let fieldValues = {
//       C: "Company",
//       P: "Person",
//       H: "HUF (Hindu Undivided Family)",
//       F: "Firm",
//       A: "Association of Persons (AOP)",
//       T: "AOP (Trust)",
//       B: "Body of Individuals (BOI)",
//       L: "Local Authority",
//       J: "Artificial Judi",
//       G: "Government"
//     };
//     let storedField = fieldValues[fourthLetter];
    
//     // Determine 5th character value
//     let fifthCharacter = value.charAt(4);
//     if (fourthLetter === 'P') {
//       const nameStartsWith = values.last_name ? values.last_name.charAt(0).toUpperCase() : values.first_name ? values.first_name.charAt(0).toUpperCase() : "";
//       if (nameStartsWith !== fifthCharacter) {
//         return "Invalid Pancard number";
//       }
//     } else {
//       const nameStartsWith = values.first_name ? values.first_name.charAt(0).toUpperCase() : "";
//       if (nameStartsWith !== fifthCharacter) {
//         return "Invalid Pancard number";
//       }
//     }
//     values.hiddenField = storedField;
//     return true;
//   }
//   return true; 
// });

const FormsyInputField = (props) => {
    const changeHandler = event => {
      const { value, id } = event.target;
      if (!props.errorMessage && value && document?.getElementById(id)?.style) {
        document.getElementById(id).style.border = "1px solid #bbbbbb";
      }
      if (typeof props.onChange === "function") { 
        if(props.maxLength && event.target.value.length > +props.maxLength){
          return true
        } 
        props.onChange(event);
      }
      props.setValue(event.target.value);
    };

    const patternFormatChangeHandler = (data) => {
      if (typeof props.onChange === "function") {
        props.onChange(data);
      }
      props.setValue(data.value);
    }
    
    const blurHandler = event => {
      const { value, id } = event.target;
      let errorElement = document.getElementById(id)?.parentNode.querySelector(".error");
      if (props.isRequired && value === '') {
        document.getElementById(id).style.border = "1px solid red";
      }
      let formField = document.getElementById(id)?.closest(".form-field");
      if (value === "") {
        if (errorElement) errorElement.classList.remove("hide");
        if (formField) formField.classList.remove("active");
      } else {
        if (errorElement) errorElement.classList.add("hide");
        if (formField) formField.classList.add("active");
      }
    }

    const newPropsValue = (props)=>{
      let newProps =  {...props}
      delete newProps.validate;
      delete newProps.allowEmptyFormatting
      delete newProps.showRequired
      delete newProps.showError
      delete newProps.setValue
      delete newProps.setValidations 
      delete newProps.resetValue
      delete newProps.isValid
      delete newProps.isRequired
      delete newProps.isPristine
      delete newProps.isFormSubmitted
      delete newProps.hasValue
      delete newProps.errorMessages
      delete newProps.errorMessage
      delete newProps.validationErrors
      delete newProps.innerRef
      delete newProps.runValidation
      delete newProps.isValidValue
      delete newProps.isFormDisabled
      delete newProps.detachFromForm
      delete newProps.attachToForm
      delete newProps.validationError  
     return  newProps
    }

    const focusHandler = event => {
      const { id } = event.target;
      let formField = document.getElementById(id)?.closest(".form-field");
      if (formField) formField.classList.add("active");
    };

    let label = props.label;
    // To elipsize long label
    let labeltxtClass= (label && label.length && label.length >=25) && props.col!="col-md-12" ? 'label-txt':'';
    if (props.isRequired) label += " *";

    let Formatter = props.type === "pattern-format" ? PatternFormat : NumericFormat; 

    let newProps = newPropsValue(props) 
    return (
        <>
            {["text","date","datetime-local","textarea","password","number","radio"].includes(props.type)? (
              <div className="material">
                <input
                  // {...props}
                  {...newProps}
                  autoComplete="new-off"
                  style={{ border: props.errorMessage ? "1px solid red" : "" }}
                  className={
                    props.className
                      ? props.className
                      : "form-input"
                  }
                  title={labeltxtClass ? label : ""}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  onFocus={focusHandler}
                />
                {props.errorMessage ? (
                  <div className="error-msg-txt">{props.errorMessage}</div>
                ) : (
                  ""
                )}
                 <label data-label={label} className={`form-label ${labeltxtClass}`}></label>
              </div>
            ) : ["pattern-format", "number-format"].includes(props.type) ? (
              <div className="material">
                <Formatter
                 // {...props}
                  {...newProps}
                  style={{ border: props.errorMessage ? "1px solid red" : "" }}
                  className={
                      props.className
                        ? props.className
                        : "form-input"
                    }
                  format={props.type === "pattern-format" ? props.format : null}
                  title={labeltxtClass ? label : ""}
                  onValueChange={(data,custom_event) => patternFormatChangeHandler(data, custom_event)}
                  onBlur={blurHandler} 
                  onFocus={focusHandler}
                 // decimalScale={3}
                />
                {props.errorMessage ? (
                  <div className="error-msg-txt">{props.errorMessage}</div>
                ) : (
                  ""
                )}
                <label data-label={label} className={`form-label ${labeltxtClass}`}></label>
              </div>
            ) : null}
        </>
    )
}

export default withFormsy(FormsyInputField);