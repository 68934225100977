import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MasterService } from "../../../../services";

const SupportPartnerInfo = ({ handleSearchFilters }) => {
  const SOURCE = "support";
  const user_information = JSON.parse(localStorage.getItem("user_information"));
  const [followupAndStatusCounts, setFollowupAndStatusCounts] = useState({});
  const [dealerStatusCounts, setDealerStatusCounts] = useState({});
  const [filterData, setFilterData] = useState({});

  const getFollowAndStatusCounts = async () => {
    try {
      const user_information = JSON.parse(
        localStorage.getItem("user_information")
      );
      const reqBody = {
        sourceType: SOURCE,
        role_names: user_information.role_names || [],
        supportId: user_information.id,
      };
      const response = await MasterService.post(
        "partner/partner-support/get-followup-and-status-count",
        reqBody
      );
      if (response.data.status === 200) {
        setFollowupAndStatusCounts(response.data?.data);
      } else {
        console.log(response.data);
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getDealerStatusCounts = async () => {
    try {
      let loginResponseString = localStorage.getItem("user_information");
      let user_role_type = "";
      if (loginResponseString) {
        const loginResponse = JSON.parse(loginResponseString);
        if (
          loginResponse.role_names &&
          Array.isArray(loginResponse.role_names)
        ) {
          if (loginResponse.role_names.includes("Partner Support Executive")) {
            user_role_type = "partner_support_executive";
          } else if (loginResponse.role_names.includes("Partner Admin")) {
            user_role_type = "partner_admin";
          }
        }
      }
      const reqBody = {
        list_type: "",
        page_no: 1,
        status: "",
        list_type: "saathi_support",
      };
      if (user_role_type) {
        reqBody.user_type = user_role_type;
      }
      const response = await MasterService.post(
        "dealer/dealer/count_dealer_status",
        reqBody
      );
      if (response.data.status === 200) {
        setDealerStatusCounts(response.data.data);
      } else {
        console.log(response.data);
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getFollowAndStatusCounts();
    getDealerStatusCounts();
  }, []);

  // useEffect(() => {
  //   handleSearchFilters(filterData);
  // }, [filterData, handleSearchFilters]);

  const handleStatusClick = (key, value, event) => {
    // const selectedDiv = event.target;
    // const parentElement = selectedDiv.parentElement;
    // const allChildren = parentElement.getElementsByTagName("*");

    // const isAnyActive = document.querySelectorAll(".count-active");
    // if (isAnyActive.length) {
    //   isAnyActive[0].classList.remove("count-active");
    //   isAnyActive[1].classList.remove("count-active");
    // }
    // allChildren[0].classList.add("count-active");
    // allChildren[1].classList.add("count-active");
    // let extraKeys = {};
    // if (
    //   key === "list_type" &&
    //   (value === "ready_for_verification" ||
    //     value === "doc_pending" ||
    //     value === "rejected")
    // ) {
    //   extraKeys["status"] = "4";
    //   extraKeys["status_id"] = "4";
    // }
    // setFilterData({
    //   [key]: value,
    //   isForced: true,
    //   ...extraKeys,
    // });
  };

  return (
    <>
      <div className="search-wrap">
        <div className="supportinfo">
          <div className="supportcountinfo">
            <div className="supportcount">
              <div className="supportheading">My Saathis</div>
              <div className="supportheading">
                {dealerStatusCounts?.sathisSupport || "N/A"}
              </div>
            </div>
            <div className="supportcount">
              <div className="supportheading">-</div>
              <div className="supportheading">N/A</div>
            </div>
            <div className="supportcount">
              <div className="supportheading">-</div>
              <div className="supportheading">N/A</div>
            </div>
          </div>

          <div className="supportfollowup">
            <div className="supportheading">Status</div>

            <ul className="supportlistingstatus">
              <li>
                <div onClick={(e) => handleStatusClick("call_status", 1, e)}>
                  <div>{followupAndStatusCounts?.connected || "N/A"}</div>
                  <div>Connected</div>
                </div>
              </li>
              <li>
                <div onClick={(e) => handleStatusClick("call_status", 2, e)}>
                  <div>{followupAndStatusCounts?.not_connected || "N/A"}</div>
                  <div>Not Connected</div>
                </div>
              </li>
              <li>
                <div onClick={(e) => handleStatusClick("call_status", 3, e)}>
                  <div>
                    {followupAndStatusCounts?.call_unattempted || "N/A"}
                  </div>
                  <div>Unattempted</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="supportfollowup">
            <div className="supportheading">Followups</div>

            <ul className="supportlistingstatus">
              <li>
                <div
                  onClick={(e) =>
                    handleStatusClick("followup_category", "overdue", e)
                  }
                >
                  <div>{followupAndStatusCounts?.overdue || "N/A"}</div>
                  <div>Overdue</div>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) =>
                    handleStatusClick("followup_category", "today", e)
                  }
                >
                  <div>{followupAndStatusCounts?.today || "N/A"}</div>
                  <div>Due today</div>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) =>
                    handleStatusClick("followup_category", "upcoming", e)
                  }
                >
                  <div>{followupAndStatusCounts?.upcoming || "N/A"}</div>
                  <div>Upcoming</div>
                </div>
              </li>
            </ul>
          </div>

          {/* <div className="supporttickets">
            <div className="supportheading">Saathis with Tickets</div>

            <ul>
              <li>
                <a href="#">
                  <div>40</div>
                  <div>(40 open queries)</div>
                </a>
              </li>
            </ul>
          </div> */}

          <div className="supportstatus">
            <div className="supportheading">Verifcation Status</div>

            <ul className="supportlistingstatus">
              <li>
                <div onClick={(e) => handleStatusClick("status", "1", e)}>
                  <div>{dealerStatusCounts?.active}</div>
                  <div>Verified</div>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) =>
                    handleStatusClick("list_type", "ready_for_verification", e)
                  }
                >
                  <div>{dealerStatusCounts?.readyforVerification}</div>
                  <div>Ready for verification</div>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) =>
                    handleStatusClick("list_type", "doc_pending", e)
                  }
                >
                  <div>{dealerStatusCounts?.docPending}</div>
                  <div>Docs Pending</div>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) =>
                    handleStatusClick("list_type", "only_registerd", e)
                  }
                >
                  <div>{dealerStatusCounts?.onlyRegisterd}</div>
                  <div>Only Registered</div>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) => handleStatusClick("list_type", "rejected", e)}
                >
                  <div>{dealerStatusCounts?.rejected}</div>
                  <div>Rejected</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportPartnerInfo;
