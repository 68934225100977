/* eslint-disable */
import React, { Component } from "react";
import RadioBox from './Radiobox'
class ConfirmPopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showmodal: true
        };
        //this.setWrapperRef = this.setWrapperRef.bind(this);
        //this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    render() {
        return (
            <div className="mark-lost-popup">
                <div className="modal display-block" tabIndex="-1" role="dialog">
                    <section className="modal-main" role="document">
                        <div className="modal-header">
                            <h2 className="modal-title">{this.props.title || 'Confirm'}</h2>
                            <button type="button" className="close_icn" data-dismiss="modal" aria-label="Close" onClick={() => {
                                this.props.confirmNo();
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <h4>{this.props.message || "Are you sure to want to delete?"}</h4><br></br> */}
                            <h4 dangerouslySetInnerHTML={{ __html: this.props.message || "Are you sure to want to delete?" }} />
                            {this.props.is_disable_notification ? 
                            <p> Do you want to send notification to partner? 
                                <input
                                    type="checkbox"
                                    name="is_notification"
                                    label="Is notifications send to partner ? "
                                    id={'is_notification'}
                                    key={'is_notification'}
                                    checked={this.props.is_notification =='yes' ?true:false}
                                    onChange={(e) => this.props.setNotifications(e)}
                                />
                              </p> : null }
                        </div>
                        
                        <div className="modal-footer">
                            <div className="showflexbtn">
                            <button type="button" className="yesbtn" onClick={() => {
                                this.props.confirmYes();
                            }}>Yes</button>
                            
                            <button type="button" className="nobtn" onClick={() => {
                                this.props.confirmNo();
                            }}>No</button>
                        </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
export default ConfirmPopUp;
