import React from "react";

const WhatsappHistoryTab = ({ data }) => {

  const checkStatusUtility = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Sent";
      case "2":
        return "Delivered";
      case "3":
        return "Read";
      case "4":
        return "Clicked";
      case "9":
        return "Failed";
      default:
        return "N/A";
    }
  };

  const getStatusColorUtility = (status) => {
    switch (status) {
      case "0":
        return "gray";
      case "1":
        return "black";
      case "2":
        return "green";
      case "3":
        return "blue";
      case "4":
        return "green";
      case "9":
        return "red";
      default:
        return "gray";
    }
  };

  const cleanedDataTime = (date) => {
    return (
      <>
        {date ? new Date(date).toLocaleDateString() : "N/A"}{" "}
        {date ? new Date(date).toLocaleTimeString() : ""}
      </>
    );
  };

  return (
    <div className="container mt-5">
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Notification</th>
              <th>Request Date</th>
              <th>Sent Date</th>
              <th>Delivered Date</th>
              <th>Read Date</th>
              <th>Clicked Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <>
                {data?.map((log, index) => (
                  <tr key={index}>
                    <td style={{ cursor: "pointer" }} title={log.whatsapp_text}>
                      {log.whatsapp_text?.slice(0, 15)}...
                    </td>
                    <td>{cleanedDataTime(log.created_date)}</td>
                    <td>{cleanedDataTime(log.sent_datetime)}</td>
                    <td>{cleanedDataTime(log.delivered_datetime)}</td>
                    <td>{cleanedDataTime(log.read_datetime)}</td>
                    <td>{cleanedDataTime(log.clicked_datetime)}</td>
                    <td style={{ color: getStatusColorUtility(log.status) }}>
                      {checkStatusUtility(log.status)}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
                <div style={{ margin: "20px" }}>
                    <h5>No Record found!</h5>
                </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WhatsappHistoryTab;
