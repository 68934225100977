import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coApplicantDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_CUST_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE,DISBURSE_REJECTION_ID, AMOUNT_TYPE_OPTIONS } from '../../../config/constants';
import AdditionalIncome from '../../elements/AdditionalIncome';
import Obligation from '../../elements/Obligation';
import BreObligations from '../BreOffer/BreObligations';

const FORM_FIELDS = FormFields["LOAN_DETAILS"];

// addValidationRule("matchAccNo", (values, value) => {
//   if (values.account_number) {
//     return !values.re_account_number ? 'Please Re-Enter Account Number' : values.account_number !== values.re_account_number ? "Account No. does not match" : true
//   }
//   return true;
// })

const LeadDetailsForm = (props) => {
  const [loanDetails, setLoanDetails] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [activeTab, setActiveTab] = useState("coApplicant_0");
  const [obligationFormFields, setObligationFormFields] = useState({});
  // const [moreDetailFormFields, setMoreDetailFormFields] = useState({});
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState({});
  const [obligationsCount, setObligationsCount] = useState({});
  // const [moreDetailsCount, setMoreDetailsCount] = useState({});
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState({});
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const client = useApolloClient();
  const dispatch = useDispatch();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  let accessCondition = !IS_USER_ACCESS_PAGE("leadDetails", "customer-bank-details")?.is_edit_access;
  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["company_type"] = options["company_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id
    })); 
    options["ca_amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] = options["additional_income_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id
    })); 
    options["ca_filtered_additional_income_type_0"] = options["additional_income_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id
    })); 
    // options["obligation_type"] = options["obligation_type"]?.map((ob) => ({
    //   ...ob,
    //   value: +ob.id
    // }));
    // options["ca_filtered_obligation_type"] = options["obligation_type"]?.map((ob) => ({
    //   ...ob,
    //   value: +ob.id
    // }));
    // options["more_detail_type"] = options["more_detail_type"]?.map((ob) => ({
    //   ...ob,
    //   value: +ob.id
    // })); 
    // options["filtered_more_detail_type"] = options["more_detail_type"]?.map((ob) => ({
    //   ...ob,
    //   value: +ob.id
    // }));
    return options;
  }, [masterdata]);

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let co_applicatndata = lead?.leadDetail?.co_applicant || [] 
      const activeIndex = activeTab.split("_")[1];
      const newCoApplicants = co_applicatndata.filter(ob=>ob.ca_type =='financing').map((data, index) => {
        let newAppObject = {}
        newAppObject.id = data.id || 0; 
        newAppObject.ca_first_name = data.ca_first_name || '';
        newAppObject.ca_last_name = data.ca_last_name || ''; 
        newAppObject.ca_profession = data.ca_profession || 0; 
        newAppObject.ca_salary_credit_mode = data.ca_salary_credit_mode || 0; 
        newAppObject.ca_company_name = data.ca_company_name || ''; 
        newAppObject.ca_company_type = data.ca_company_type || 0; 
        newAppObject.ca_monthly_salary = data.ca_monthly_salary || ''; 
        newAppObject.ca_annual_income = data.ca_annual_income || ''; 
        newAppObject.ca_business_proof = data.ca_business_proof;
        newAppObject.ca_gross_monthly_salary = data.ca_gross_monthly_salary || ''; 
        newAppObject.ca_is_form_16_filled = data.ca_is_form_16_filled || '0';
        newAppObject.ca_is_itr_filled = data.ca_is_itr_filled || '0';
        newAppObject.ca_existing_emi_amount = data.ca_existing_emi_amount;
        newAppObject.ca_emi_ending_six_month = data.ca_emi_ending_six_month;
        newAppObject.ca_is_additional_income = data.ca_is_additional_income || 'no';
          newAppObject.ca_is_obligation = data.ca_is_obligation;
          let ca_additionalIncome = [], ca_obli = [];
          if (data.ca_is_additional_income === "yes") {
            ca_additionalIncome = data.ca_additional_income?.map((obj) => {
              return {
                id: obj.id,
                lead_id: obj.lead_id,
                customer_id: obj.customer_id,
                customer_type: obj.customer_type,
                ca_income_type_id: obj.income_type_id,
                ca_amount_type: obj.amount_type,
                ca_amount: obj.amount,
              };
            });
          }
          newAppObject.ca_additional_income = ca_additionalIncome;

          // if (data.ca_is_obligation === "yes") {
          //   ca_obli = data.ca_obligation?.map((obj) => {
          //     return {
          //       id: obj.id,
          //       lead_id: obj.lead_id,
          //       customer_id: obj.customer_id,
          //       customer_type: obj.customer_type,
          //       ca_obligation_type_id: obj.obligation_type_id,
          //       ca_emi_amount: obj.emi_amount,
          //       ca_pending_emi_months: obj.pending_emi_months,
          //     };
          //   });
          // }
          // newAppObject.ca_obligation = ca_obli;

        setAdditionalIncomeCount((current) => ({
          ...current,
          [`coApplicant_${index}`]: 0
        }));

          // setObligationsCount((current) => ({
          //   ...current,
          //   [`coApplicant_${index}`]: 0
          // }));

          // if (data.ca_profession == 1) {
          //   ALL_OPTIONS["filtered_obligation_type"] = ALL_OPTIONS["obligation_type"].filter(obj => obj.value !== 3);
          // }

        // if (data.ca_profession == 2) {
        //   ALL_OPTIONS["filtered_more_detail_type"] = ALL_OPTIONS["more_detail_type"].filter(obj => obj.value === 3);
        // }

        return newAppObject;
      })

      if (newCoApplicants[activeIndex]?.["ca_is_additional_income"] === "yes") {
        const co_app = newCoApplicants[activeIndex].ca_additional_income;
        for (let i = 0; i < co_app.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`ca_filtered_additional_income_type_${i + 1}`] =
            ALL_OPTIONS[`ca_filtered_additional_income_type_${i}`]?.filter(
              (obj) => obj.value !== co_app[i].ca_income_type_id
            );
        }
      }

      // if (newCoApplicants[activeIndex]?.["ca_is_obligation"] === "yes") {
      //   const co_app = newCoApplicants[activeIndex].ca_obligation;
      //   for (let i = 0; i < co_app.length; i++) {
      //     handleAddObligations(i);
      //   }
      // }
      setLoanDetails(newCoApplicants);
      if (co_applicatndata.length === 0) newCoapplicant();
    }
  }, [lead]);

  const newCoapplicant = () => {
    const newCoApplicants = [{
      ca_existing_emi_amount: "",
      ca_annual_income: "",
      ca_profession: "",
      ca_salary_credit_mode: "",
      ca_company_name: "",
      ca_company_type: "",
      ca_loan_amount: "",
      ca_tenure: ""
    }];
    setLoanDetails(newCoApplicants);
  };

  /* const addMoreFields = () => {
    const newCoApplicants = [...loanDetails, {
      ca_existing_emi_amount: "",
      ca_annual_income: "",
      ca_profession: "",
      ca_salary_credit_mode: "",
      ca_company_name: "",
      ca_company_type: "",
      ca_loan_amount: "",
      ca_tenure: ""
    }];

    setLoanDetails(newCoApplicants);
    setActiveTab(`coApplicant_${newCoApplicants.length - 1}`);
  }; */

  const handleInputChange = event => {
    if (event.target.id) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedLoanDetails = [...loanDetails];
      updatedLoanDetails[activetabIndex][event.target.id] = event.target.value;
      setLoanDetails(updatedLoanDetails);
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedLoanDetails = [...loanDetails];
      updatedLoanDetails[activetabIndex][field_name] = data.value;
      setLoanDetails(updatedLoanDetails);
    }
  }

  const handleSubmit = () => { 
    const tabIndex = activeTab.split("_")[1];
    let lead_id = lead?.leadDetail?.id || "";
    let newRecord = loanDetails.map((ob)=>{
      let additionalIncome = [];
      if (ob.ca_additional_income?.length > 0) {
        additionalIncome = ob.ca_additional_income?.map((income) => {
          return {
            ...(income.id ? { id: income?.id } : {}),
            lead_id,
            customer_id: ob.id,
            customer_type: "co_applicant",
            income_type_id: income.ca_income_type_id || null,
            amount_type: income.ca_amount_type,
            amount: income.ca_amount,
            status: income.ca_status,
          };
        });
      }
      
      let obli = [];
      // if (ob.ca_obligation?.length > 0) {
      //   obli = ob.ca_obligation?.map((income) => {
      //     return {
      //       ...(income.id ? { id: income?.id } : {}),
      //       lead_id,
      //       customer_id: ob.id,
      //       customer_type: "co_applicant",
      //       obligation_type_id: income.ca_obligation_type_id || null,
      //       emi_amount: income.ca_emi_amount,
      //       pending_emi_months: +income.ca_pending_emi_months,
      //       status: income.ca_status,
      //     };
      //   });
      // }

      return {
        id: ob.id,
        ca_profession: +ob.ca_profession,
        ca_salary_credit_mode: +ob.ca_salary_credit_mode,
        ca_company_name: ob.ca_company_name,
        ca_company_type : ob.ca_company_type,
        ca_is_itr_filled : ob.ca_is_itr_filled,
        ca_is_form_16_filled : ob.ca_is_form_16_filled,
        ca_gross_monthly_salary : ob.ca_gross_monthly_salary,
        ca_monthly_salary : ob.ca_monthly_salary,
        ca_annual_income : ob.ca_annual_income,
        ca_business_proof : ob.ca_business_proof,
        ca_is_additional_income : additionalIncome.length > 0 ? "yes" : "no",
        ca_additional_income : additionalIncome,
        // ca_is_obligation : obli.length > 0 ? "yes" : "no",
        // ca_obligation : obli,
        ca_existing_emi_amount: ob?.ca_existing_emi_amount || null,
        ca_emi_ending_six_month: ob?.ca_emi_ending_six_month || null,
        ca_is_obligation: ob?.ca_is_obligation || "",
      };
    });

    newRecord = (newRecord[tabIndex])? [newRecord[tabIndex]]: newRecord 
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          co_applicant: newRecord
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client));
    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  let LoanDetailsForm = useMemo(() => { 
    if (loanDetails) {
      let index = activeTab.split('_');
      index = index[1];  
      if(!loanDetails[index]?.ca_existing_emi || !loanDetails[index]?.ca_existing_emi || loanDetails[index].ca_existing_emi =='yes'){
        return FORM_FIELDS;
      }else{
        return FORM_FIELDS.filter(v => (!['ca_no_of_emi','ca_existing_emi_amount'].includes(v.id)) );  
      } 
    }
    return FORM_FIELDS;

  }, [loanDetails]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedLoanDetails = [...loanDetails];
      updatedLoanDetails[activetabIndex][field_name] = (field_name === 'ca_tenure') ? +data.value : data.value;
      if (field_name === "ca_profession") {
        let filteredAddInc = [], filteredObli = [];
        // let filteredMoreDet = ALL_OPTIONS["more_detail_type"];
        if (data.value === 1) {
          filteredAddInc = ALL_OPTIONS["additional_income_type"];
          // filteredObli = ALL_OPTIONS["obligation_type"].filter(obj => obj.value !== 3);
        } else {
          // if (data.value === 2) {
          //   filteredMoreDet = ALL_OPTIONS["more_detail_type"].filter(obj => obj.value === 3);
          // }
          filteredAddInc = ALL_OPTIONS["additional_income_type"].filter(obj => obj.value === 1 || obj.value === 5);
          // filteredObli = ALL_OPTIONS["obligation_type"];
        }
        ALL_OPTIONS["filtered_additional_income_type"] = filteredAddInc;
        // ALL_OPTIONS["filtered_obligation_type"] = filteredObli;
        // ALL_OPTIONS["filtered_more_detail_type"] = filteredMoreDet;
      }
      setLoanDetails(updatedLoanDetails);
    }
  }

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields[activeTab]?.filter(
        (obj) => obj.id === `ca_additional_income_type_${index}`
      ) || [];
      if (isExisting.length === 0) {
        const dropDownObj = {
          id: `ca_additional_income_type_${index}`,
          name: `ca_additional_income_type_${index}`,
          readOnly: false,
          type: `dropdown`,
          label: `Additional income type`,
          required: false,
          validations: null,
          validationError: "",
          ApplyNumberformat: false,
          optionsKey: `ca_filtered_additional_income_type_${index}`,
        };
        setAdditionalIncomeCount((current) => ({
          ...current,
          [activeTab]: index + 1
        }));
        setAdditionalIncomeFormFields((current) => ({
          ...current,
          [activeTab]: [...(additionalIncomeFormFields[activeTab] ? additionalIncomeFormFields[activeTab] : []), dropDownObj]
        }));
      }
    }
  };

  // const handleAddObligations = (index) => {
  //   if (index < 5) {
  //     const isExisting = obligationFormFields[activeTab]?.filter(
  //       (obj) => obj.id === `ca_obligation_type_${index}`
  //     ) || [];
  //     if (isExisting.length === 0 || obligationFormFields[activeTab][obligationFormFields.length - 1].canAddMore) {
  //       const dropDownObj = {
  //         id: `ca_obligation_type_${index}`,
  //         name: `ca_obligation_type_${index}`,
  //         readOnly: false,
  //         type: `dropdown`,
  //         label: `Type`,
  //         required: false,
  //         validations: null,
  //         validationError: "",
  //         ApplyNumberformat: false,
  //         optionsKey: `ca_filtered_obligation_type`,
  //       };

  //       setObligationsCount((current) => ({
  //         ...current,
  //         [activeTab]: index + 1
  //       }));
  //       setObligationFormFields((current) => ({
  //         ...current,
  //         [activeTab]: [...(obligationFormFields[activeTab] ? obligationFormFields[activeTab] : []), dropDownObj]
  //       }));
  //     }
  //   }
  // }

  // const handleAddMoreDetails = (index) => {
  //   if (index < 4) {
  //     const isExists = document.getElementById(`ca_more_detail_amount_${index}`) || null;
  //     if (!isExists) {
  //       const dropDownObj = {
  //         id: `ca_more_detail_amount_type_${index}`,
  //         name: `ca_more_detail_amount_type_${index}`,
  //         readOnly: false,
  //         type: `dropdown`,
  //         label: `Amount type`,
  //         required: false,
  //         validations: null,
  //         validationError: "",
  //         ApplyNumberformat: false,
  //         optionsKey: `filtered_more_detail_type`,
  //       };
    
  //       const inputObj = {
  //         id:`ca_more_detail_amount_${index}`,
  //         name: `ca_more_detail_amount_${index}`,
  //         readOnly: false,
  //         type: "number-format",
  //         label: "Amount",
  //         required: false,
  //         validations: "isNumeric",
  //         validationError: "",
  //         ApplyNumberformat: false
  //       }
  //       setMoreDetailsCount((current) => ({
  //         ...current,
  //         [activeTab]: index + 1
  //       }));
  //       setMoreDetailFormFields((current) => ({
  //         ...current,
  //         [activeTab]: [...(moreDetailFormFields[activeTab] ? moreDetailFormFields[activeTab] : []), dropDownObj, inputObj]
  //       }));
  //     }
  //   }
  // }

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    const loanDetailClone = [...loanDetails];
    const activeIndex = activeTab.split("_")[1];
    loanDetailClone[activeIndex][name] = checked ? '1' : '0';
    setLoanDetails(loanDetailClone);
  }

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`ca_filtered_additional_income_type_${index + 1}`] =
        ALL_OPTIONS[`ca_filtered_additional_income_type_${index}`].filter(
          (obj) => obj.value !== data.value
        );
    }
  };

  const handleRemoveAdditionalIncome = (index) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...loanDetails];
    const existingAddIncomeField = [...additionalIncomeFormFields[activeTab]];
    const existingAddIncome = [
      ...loanDetails[activeTab.split("_")[1]]["ca_additional_income"],
    ];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: "",
      amount_type: "",
      amount: "",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[existingAddIncomeField.length - 1].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    existingCo[activeIndex]["ca_additional_income"] = existingAddIncome;
    setAdditionalIncomeCount((current) => ({
      ...current,
      [activeTab]: additionalIncomeCount[activeTab] - 1
    }));
    setAdditionalIncomeFormFields((current) => ({
      ...current,
      [activeTab]: existingAddIncomeField
    }));
    setLoanDetails(existingCo);
  };

  const handleSetAdditionalIncomeData = (data) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...loanDetails];
    existingCo[activeIndex]["ca_additional_income"] = data;
    setLoanDetails(existingCo);
  };

  // const handleRemoveObligation = (index) => {
  //   const activeIndex = activeTab.split("_")[1];
  //   const existingCo = [...loanDetails];
  //   const existingObField = [...obligationFormFields[activeTab]];
  //   const existingOb = [
  //     ...loanDetails[activeTab.split("_")[1]]["ca_obligation"],
  //   ];
  //   const removedEle = {
  //     ...existingOb[index],
  //     status: "0",
  //     obligation_type_id: null,
  //     emi_amount: "",
  //     pending_emi_months: null,
  //   };
  //   existingObField.splice(index, 1);
  //   if (existingObField.length > 0) {
  //     existingObField[existingObField.length - 1].canAddMore = true;
  //   }
  //   existingOb.splice(index, 1);
  //   existingOb.push(removedEle);
  //   existingCo[activeIndex]["ca_obligation"] = existingOb;
  //   setObligationsCount((current) => ({
  //     ...current,
  //     [activeTab]: obligationsCount[activeTab] - 1
  //   }));
  //   setObligationFormFields((current) => ({
  //     ...current,
  //     [activeTab]: existingObField
  //   }));
  //   setLoanDetails(existingCo);
  // }

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...loanDetails];
      if (obj) {
        existingCo[activeIndex] = { ...existingCo[activeIndex], ...obj };
      } else {
        existingCo[activeIndex][key] = value;
      }
      setLoanDetails(existingCo);
    }
  };

  const handleRadioChange = (event) => {
    if (event.target) {
      const { id, name } = event.target;
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...loanDetails];
      existingCo[activeIndex][name] = id;

      // add slight delay to state updates and component re-renders
      setTimeout(() => {
        setLoanDetails(existingCo);
      }, 0);
    }
  };

  const sectionInfo = { section: "Customer details", sub_section: "Bank details" }
  const rejectDisable = (lead.leadDetail.reject_reason_id > 0 && !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) && [2, 3].includes(lead.leadDetail.status_id)) ? true : false 
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false;
  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="4" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Co applicant details</span>
            {/* <h2>Loan Details</h2> */}
          </div>

          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {loanDetails.length ? loanDetails.map((coApplicant, index) =>
                (loanDetails[index].ca_first_name) ?
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                      eventkey={`coApplicant_${index}`}
                      active={activeTab === `coApplicant_${index}`}
                    >
                      {loanDetails[index].ca_first_name}
                    </Nav.Link>
                  </Nav.Item> :
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                      eventkey={`coApplicant_${index}`}
                      active={activeTab === `coApplicant_${index}`}
                    >
                      Add New
                    </Nav.Link>
                  </Nav.Item>
              ): <div>No Co-applicant found </div> }
            </div>
          </Nav>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">

              {LoanDetailsForm.map((field) => (
                loanDetails.map((loanDetailData, index) => (`coApplicant_${index}` === activeTab) && (

                  (loanDetailData["ca_profession"] === 0 && field.name === "ca_annual_income") ||
                  (loanDetailData["ca_profession"] === 0 && field.name === "ca_company_type") ||
                  (loanDetailData["ca_profession"] === 0 && field.name === "ca_company_name") ||
                  (loanDetailData["ca_profession"] === 0 && field.name === "ca_salary_credit_mode") ||
                  (loanDetailData["ca_profession"] !== 1 && field.name === "ca_monthly_salary") ||
                  (loanDetailData["ca_profession"] !== 1 && field.name === "ca_gross_monthly_salary") || 
                  (loanDetailData["ca_profession"] === 1 && field.name === "ca_annual_income") ||
                  (loanDetailData["ca_profession"] === 2 && field.name === "ca_salary_credit_mode") ||
                  (loanDetailData["ca_profession"] === 3 && field.name === "ca_salary_credit_mode") || 
                  (loanDetailData["ca_profession"] === 3 && field.name === "ca_company_type") || 
                  (loanDetailData["ca_profession"] === 3 && field.name === "ca_company_name") ? null :

                  ["text", "password", "pattern-format", "number-format"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={loanDetailData && loanDetailData[field.name]}
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        onChange={
                          ["pattern-format", "number-format"].includes(field.type)
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          loanDetailData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) :

                    field.type === "dropdown" ? (
                      <fieldset class="single-select col-md-6">
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == loanDetailData[field.name])[0],
                            isDisabled: field.readOnly
                          }}
                          required={field.required}
                          value={loanDetailData[field.name]}
                          onChange={(data) => handleSelectChange(data, field.name)}
                        />
                      </fieldset>
                    ) : null
                ))))}

            </div>
            
            {/* ADDITIONAL INCOME */}
            {loanDetails[activeTab.split("_")[1]]?.ca_profession != 0 && (
              <>
                {additionalIncomeFormFields[activeTab]?.map((data, index) => (
                  <>
                    <div className="additionalincome" key={index}>
                        Additional Income - {index + 1}
                    </div>
                    <AdditionalIncome
                      index={index}
                      ALL_OPTIONS={ALL_OPTIONS}
                      setAdditionalIncomeType={handleAdditionalIncomeType}
                      removeAdditionalIncome={handleRemoveAdditionalIncome}
                      setAdditionalIncomeData={handleSetAdditionalIncomeData}
                      additionalIncomeData={
                        loanDetails[activeTab.split("_")[1]]?.["ca_additional_income"]
                      }
                      key={index}
                      isCoApp={true}
                    />
                  </>
                ))}

                {additionalIncomeCount[activeTab] < 5 && (
                  <div
                    className="additionalincome"
                    onClick={() => handleAddAdditionalIncome(additionalIncomeCount[activeTab])}
                  >
                    <span>+</span>
                    <div>Additional Income</div>
                  </div>
                )}
              </>
            )}

            {loanDetails[activeTab.split("_")[1]]?.ca_profession != 0 && (
              <hr></hr>
            )}

            {/* OBLIGATION */}
            {loanDetails[activeTab.split("_")[1]]?.ca_profession != 0 && (
              <>
                <h4 className="additionalincome">
                  Does the co-applicant have any obligation?
                </h4>
                <div className="propertyflex">
                  <input
                    type="radio"
                    id="yes"
                    name="ca_is_obligation"
                    onChange={handleRadioChange}
                    checked={
                      loanDetails[activeTab.split("_")[1]]?.[
                        "ca_is_obligation"
                      ] === "yes"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="yes">Yes</label>

                  <input
                    type="radio"
                    id="no"
                    name="ca_is_obligation"
                    onChange={handleRadioChange}
                    checked={
                      loanDetails[activeTab.split("_")[1]]?.[
                        "ca_is_obligation"
                      ] === "no"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="no">No</label>
                </div>

                {loanDetails[activeTab.split("_")[1]]?.["ca_is_obligation"] ===
                  "yes" && (
                  <BreObligations
                    setObligationDetails={handleSetObligationData}
                    customerType={"co_applicant"}
                    activeCoApplicant={loanDetails[activeTab.split("_")[1]]}
                  />
                )}

                {/* {obligationFormFields[activeTab]?.map((data, index) => (
                  <>
                    <div className="additionalincome" key={index}>
                      Obligation - {index + 1}
                    </div>
                    <Obligation
                      index={index}
                      ALL_OPTIONS={ALL_OPTIONS}
                      removeObligation={handleRemoveObligation}
                      setObligationData={handleSetObligationData}
                      obligationData={loanDetails[activeTab.split("_")[1]]?.["ca_obligation"]}
                      employmentType={loanDetails[activeTab.split("_")[1]]?.ca_profession}
                      key={index}
                      isCoApp={true}
                    />
                  </>
                ))}
  
                {obligationsCount[activeTab] < 5 && (
                  <div
                    className="additionalincome"
                    onClick={() => handleAddObligations(obligationsCount[activeTab])}
                  >
                    <span>+</span>
                    <div>{loanDetails[activeTab.split("_")[1]]?.ca_profession === 1 ? "EMI based Obligations" : "Monthly Obligations"}</div>
                  </div>
                )} */}
              </>
            )}

            {loanDetails[activeTab.split("_")[1]]?.ca_profession == 1 ? (
              <>
                <div className="property-identified">
                  <label>Have you filled Form 16B ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="ca_is_form_16_filled"
                      id="ca_is_form_16_filled"
                      value="Yes"
                      checked={loanDetails[activeTab.split("_")[1]]?.ca_is_form_16_filled === "1" ? true : false}
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : loanDetails[activeTab.split("_")[1]]?.ca_profession == 2 || loanDetails[activeTab.split("_")[1]]?.ca_profession == 3 ? (
              <>
                <div className="property-identified">
                  <label>Have you filled ITR ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="ca_is_itr_filled"
                      id="ca_is_itr_filled"
                      value="Yes"
                      checked={loanDetails[activeTab.split("_")[1]].ca_is_itr_filled === "1" ? true : false}
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : null}

            {loanDetails[activeTab.split("_")[1]]?.ca_profession == 3 ? (
              <>
                <div className="property-identified">
                  <label>Do you have business proof ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="ca_business_proof"
                      id="ca_business_proof"
                      value="1"
                      checked={loanDetails[activeTab.split("_")[1]]?.["ca_business_proof"] == "1" ? true : false}
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : null}

            {
              loanDetails.length ? 
              <div className="btn-save-remarks">
                <button type="submit" className="btn-primary" disabled={IS_LOST}>
                  Save
                </button>
                {/* <button className="btn-primary-outline" type="button" onClick={addMoreFields}>
                  Add Co-Applicant
                </button> */}
                {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}disabled={rejectDisable}>Remarks
                  <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
                </button> */}
              </div> : null
            }

           
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed}
              accessCondition={accessCondition} sectionInfo={sectionInfo} />
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default LeadDetailsForm;