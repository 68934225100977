import React, { useEffect, useState } from "react";
import { executeGraphQLQuery } from "../../../../common/executeGraphQLQuery";
import { LEADS_QUERY } from "../../../../services/leads.gql";
import { useApolloClient } from "@apollo/client";
import Pagination from "../../../elements/pagination";
import Loader from "../../../elements/Loader";
const LeadListing = ({ basic_details, id }) => {
  const client = useApolloClient();
  const [leadList, setLeadList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loding , setLoading] = useState(false)
  useEffect(() => {
    getLeadList();
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });    
  }, [pageNumber]); 

  const getLeadList = async () => {
    let filterData = {};
    filterData.user_id = basic_details.id;
    filterData.page_no = pageNumber;
    filterData.search_text = basic_details.id;
    setLoading(true)
    const result = await executeGraphQLQuery(LEADS_QUERY(filterData), client);
    setTotalPage(result.data?.leads[0]?.pagination.totalpage);
    setLeadList(result.data?.leads[0]?.lead_list);
    if (result.data?.leads[0]?.lead_list){
      setLoading(false)
    }
  };

  const onPaginate = (pagenumber) => {
    setPageNumber(pagenumber);
 
  };

  return (
    <>
      
      <div className="table-responsive">
        {loding ===  true && <Loader/>}
        <table className="table table-bordered tablefontsize">
          <thead style={{position:"sticky",top:"0"}}>
            <tr>
              <th>Lead ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Created On</th>
              <th>Updated On</th>
              <th>Fulfillment Type</th>
            </tr>
          </thead>
          <tbody>
            {leadList.length > 0 ? (
              leadList.map((data, index) => (
                <tr key={index}>
                  <td>{data?.id ? data?.id : "N/A"}</td>
                  <td>{data?.customer?.first_name? data?.customer.first_name +" " + data?.customer?.last_name: "Unknown Name"}</td>
                  <td>{data?.status_info?.statuslang?.status_name? data?.status_info?.statuslang?.status_name: "N/A"}</td>
                  <td>{data?.created_date ? (<><div>{new Date(data.created_date).toLocaleDateString()}</div><div>{new Date(data.created_date).toLocaleTimeString()}</div></>) : ("N/A")}</td>
                  <td>{data?.updated_date ? (<><div>{new Date(data.updated_date).toLocaleDateString()}</div><div>{new Date(data.updated_date).toLocaleTimeString()}</div></>) : ("N/A")}</td>
                  <td>{data?.fulfillment_type ? data?.fulfillment_type : "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr><td colSpan="6">No Leads Found</td></tr>
            )}
            <tr>
              <td colSpan={6}><Pagination pageCount={totalPage} activePage={pageNumber} onPaginate={onPaginate}/> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LeadListing;
