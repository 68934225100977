const crypto = require("crypto");
const CONSTANT = require("./constants");
const CIPHER_ALGO = "aes-128-cbc"; //"bf-ecb"
//const iv = "";

module.exports.encode = text => {
  if(['',' ',null,undefined,'null','undefined'].includes(text)){ return '';}
  var cipher = crypto.createCipher(CIPHER_ALGO, CONSTANT.SETTING.CRYPTO_KEY);
  var crypted = cipher.update(("" + text), "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
};

module.exports.decode = (text,uper=true) => {
  try {
    if(['',' ',null,undefined,'null','undefined'].includes(text)){ return '';}
    var decipher = crypto.createDecipher(
      CIPHER_ALGO,
      CONSTANT.SETTING.CRYPTO_KEY
    );
    var dec = decipher.update("" + text, "hex", "utf8");
    dec += decipher.final("utf8");
    return uper ? dec.toUpperCase() : dec;//// dec.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
  } catch (error) {
    return null;
  }
};