import React, { useEffect, useState } from "react";
import { MasterService } from "../../services";
import { getDateAndTimeFormat } from "../partner/pre-register-lead-manager/utility/dateFormateUtility";
import Loader from "../elements/Loader";

const PartnerHistory = (props) => {
  const partnerId = props?.partnerId;
  const [partnerHistory, setViewHistoryData] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const [showCallHistory, setShowCallHistory] = useState(false);
  const [tab, setTab] = useState("Partner History");
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setWhatsappLog] = useState([]);
  const [showWhatsappTable, setWhatsappTable] = useState(false);

  useEffect(() => {
    fetchPartnerHistory(partnerId);
    fetchCallHistory(partnerId);
    getWhatsappLogs(partnerId);
  }, [partnerId]);

  const fetchPartnerHistory = (id) => {
    setIsLoading(true);
    MasterService.get(`partner/partner/get-activity-hstory/?partner_id=${id}`)
      .then((response) => {
        setViewHistoryData(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const fetchCallHistory = async (leadId) => {
    const params = {
      partner_id: leadId,
    };
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        "partner/partner-call/partner-call-details-activity",
        params
      );
      setCallHistory(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getWhatsappLogs = async (partnerId) => {
    try {
      MasterService.get(
        `/partner/partner/view-whatsapp-status?partnerId=${partnerId}&notificationId=${39}`
      )
        .then((response) => {
          if (response.data.length) {
            setWhatsappLog(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error inside getwhatsappLog", error);
    }
  };

  const handleButtonClick = (event, tabName) => {
    event.preventDefault();
    setShowCallHistory(false);
    setWhatsappTable(false);
    setTab(tabName);
    if (tabName === "Call History") {
      setShowCallHistory(true);
    }
    if (tabName === "Whatsapp History") {
      setWhatsappTable(true);
    }
  };

  const cleanedDataTime = (date) => {
    return (
      <>
        {date ? new Date(date).toLocaleDateString() : "N/A"}{" "}
        {date ? new Date(date).toLocaleTimeString() : ""}
      </>
    );
  };

  const renderToggleButton = () => {
    return (
      <div style={styles.toggleButtonConatainer}>
        <button
          style={{
            ...styles.wiredButton,
            borderBottomWidth: tab === "Partner History" ? "2px" : "0px",
          }}
          onClick={(e) => handleButtonClick(e, "Partner History")}
        >
          {"Partner History"}
        </button>
        <button
          style={{
            ...styles.wiredButton,
            borderBottomWidth: tab === "Call History" ? "2px" : "0px",
          }}
          onClick={(e) => handleButtonClick(e, "Call History")}
        >
          {"Call History"}
        </button>
        <button
          style={{
            ...styles.wiredButton,
            borderBottomWidth: tab === "Whatsapp History" ? "2px" : "0px",
          }}
          onClick={(e) => handleButtonClick(e, "Whatsapp History")}
        >
          {"Whatsapp History"}
        </button>
      </div>
    );
  };

  const renderHistory = () => {
    return(
      <div className="container mt-4" >
        <div>
        <h1 style={{paddingTop:40,display:"flex",justifyContent:"center"}}>{tab}</h1>
         {renderToggleButton()}
        </div>
        <div className="table-responsive">
          {/* {showCallHistory ? renderCallHistory() : renderActivityHistory()} */}
          {showCallHistory ? renderCallHistory() : showWhatsappTable ? renderWhatsappTable() : renderActivityHistory()}
        </div>
      </div>
    )
  }

  const checkStatusUtility = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Sent";
      case "2":
        return "Delivered";
      case "3":
        return "Read";
      case "4":
        return "Clicked";
      case "9":
        return "Failed";
      default:
        return "N/A";
    }
  };

  const getStatusColorUtility = (status) => {
    switch (status) {
      case "0":
        return "gray";
      case "1":
        return "black";
      case "2":
        return "green";
      case "3":
        return "blue";
      case "4":
        return "green";
      case "9":
        return "red";
      default:
        return "gray";
    }
  };

  const renderCallHistory = () => {
    return (
      <table className="activity-log-table">
        <thead>
          <tr style={{ position: "sticky", top: 0 }}>
            <th>Call Status</th>
            <th>Title</th>
            <th>Remarks</th>
            <th>Comment</th>
            <th>Activity Date</th>
            <th>Next Follow-up Date</th>
            <th>Activity Done By</th>
            <th>Type</th>

          </tr>
        </thead>
        <tbody>
          {callHistory.length ? (
            callHistory.map((log, index) => (
              <tr key={index}>
                <td>
                  <span className="activity-details">
                    <strong>Status: </strong>
                    {log.status_name}
                    <br />
                    <strong>Sub Status: </strong>
                    {log.sub_status_name}
                    <br />
                  </span>
                </td>
                <td>{log.title || "N/A"}</td>
                <td>{log.remarks || "N/A"}</td>
                <td>{log.comment || "N/A"}</td>
                <td>{getDateAndTimeFormat(log.created_date)}</td>
                <td>
                  {log.followup_date
                    ? getDateAndTimeFormat(log.followup_date)
                    : "N/A"}
                </td>
                <td>
                  {`AMB${log.created_by}`}
                  <span className="activity-details">
                    <strong>Name: </strong>
                    {log.created_by_name}
                    <br />
                    <strong>Email: </strong>
                    {log.created_by_email}
                  </span>
                </td>
                <td>{log.source === "sales-registered" ? "Registered Partner" : log.source === "support" ? "Support" : "Partner Lead"} </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No call activity available.</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderActivityDetails = (rowData) => {
    const logData = JSON.parse(rowData?.log_data || "{}");
    const previousValues = logData.previousValues || {};
    const currentValues = logData.currentValues || {};

    if (Object.entries(previousValues).length > 0) {
        return (
            <>
                <strong>Previous:</strong>
                <ul>
                    {Object.entries(previousValues).map(([key, value]) => (
                        <li key={key}>
                            {key}: {value || "N/A"}
                        </li>
                    ))}
                </ul>
                <strong>Current:</strong>
                <ul>
                    {Object.entries(currentValues).map(([key, value]) => (
                        <li key={key}>
                            {key}: {value || "N/A"}
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    return null; // Optionally return null if there's nothing to display
};

  const renderActivityHistory = () => {
    return (
      <table className="activity-log-table">
        <thead>
          <tr style={{ position: "sticky", top: 0 }}>
            <th className="text-left">Created Date</th>
            <th className="text-left">Activity Name</th>
            <th className="text-left">Source</th>
            <th className="text-left">Created By</th>
          </tr>
        </thead>
        <tbody>
          {partnerHistory && partnerHistory.length > 0 ? (
            partnerHistory.map((rowData, index) => {
              let createdName = "", isOtherDetailsShow = true;

              if (rowData.log_data && rowData.log_data !== "") {
                const logData = JSON.parse(rowData.log_data);
                createdName = logData?.currentValues?.created_by_name || rowData?.created_by_name;
                isOtherDetailsShow = logData?.currentValues?.created_by_name ? false : true;
              } else {
                createdName = rowData?.created_by_name;
                isOtherDetailsShow = true;
              }
              return (
                <tr key={index}>
                  <td>
                    {rowData?.created_date
                      ? new Date(rowData?.created_date).toLocaleDateString()
                      : "N/A"}{" "}
                    {rowData?.created_date
                      ? new Date(rowData?.created_date).toLocaleTimeString()
                      : ""}
                  </td>

                  <td>
                    {rowData?.activity_name}
                    <span className="activity-details">
                      {renderActivityDetails(rowData)}
                    </span>
                  </td>

                  <td>{rowData?.source}</td>
                  <td>
                    {isOtherDetailsShow && `AMB${rowData?.created_by}`}
                    <span className="activity-details">
                      <strong>Name: </strong>
                      {createdName}
                      <br />
                      {isOtherDetailsShow && (
                        <>
                          <strong>Email: </strong>
                          {rowData?.created_by_email}
                        </>
                      )}
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4">No activity history available.</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderWhatsappTable = () => {
    return (
      <table className="activity-log-table">
        <thead>
          <tr>
            {/* <th>PartnerId</th>
            <th>Partner Name</th> */}
            <th>Message</th>
            <th>Status</th>
            <th>Created Date</th>
            <th>Delivered Date</th>
            <th>Read Date</th>
            <th>Click Date</th>
          </tr>
        </thead>
        <tbody>
          {logs && logs.length ? (
            logs.map((log, index) => (
              <tr key={index}>
                {/* <td>{log?.partner_id || "N/A"}</td>
                <td>{log?.partner_name || "N/A"}</td> */}
\                <td title={log?.whatsapp_text || 'No message available'}>{'🎉 Greetings..'}</td>
                <td
                  style={{
                    color: getStatusColorUtility(log.status),
                    fontWeight: "bold",
                  }}
                >
                  {checkStatusUtility(log?.status)}
                </td>
                <td>{cleanedDataTime(log?.created_date)}</td>
                <td>{cleanedDataTime(log?.delivered_datetime)}</td>
                <td>{cleanedDataTime(log?.read_datetime)}</td>
                <td>{cleanedDataTime(log?.clicked_datetime)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No WhatsApp activity available.</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div>{renderHistory()}</div>
  );
};

const styles = {
  wiredButton: {
    border: "none",
    fontSize: "16px",
    fontWeight: "500",
    padding: "5px 15px",
    borderBottom: "2px solid #d3d3d3",
    cursor: "pointer",
    backgroundColor: "white",
    outline: "none",
    color:"blue",
    fontSize : "16px",
  },
  // toggleButtonConatainer: {
  //   margin: "auto",
  //   textAlign: "center",
  // },
};

export default PartnerHistory;
