import React, { useEffect, useState } from "react";
import DateFormate from "dateformat";
import Pagination from "../elements/pagination.js";
import { toast } from "react-toastify";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery.js";

import { FORCE_APPROVE, GET_PRE_ELIGIBLE_INNER_LIST, UPDATE_MATCHED_STATUS } from "../../services/payout.gql.js";
import moment from "moment";
import CheckBox from "../elements/Radiobox.js";
import { PAYOUT_STATUS_LIST } from "../../config/constants.js";
import ConfirmPopUp from "../elements/ConfirmPopUp.js"
import Loader from "../elements/Loader.js";
import InputField from "../elements/PartnerInput.js";

const PreEligibleTableContent = ({
  partnerListing,
  eligiblepaginationdata,
  onPaginate,
  page_no,
  handleChange,
  productType,
  financers
}) => {
  const [checkedBox, setCheckedBox] = useState([])
  const [innerCheckBox, setInnerCheckBox] = useState([])
  const [innerListShow,setInnerListShow] = useState(null)
  const [innerList,setInnerList] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);



  useEffect(()=>{
    handleChange(checkedBox)
  },[checkedBox])

  const handleCheck = (event) => {
    const { value, checked } = event.target;
    setCheckedBox((prevItems) => {
      if (checked) {
        return [...prevItems, parseInt(value)];
      } else {
        return prevItems.filter((item) => item !== parseInt(value));
      }
    });    
  };

  const handleInnerCheck = (event) => {
    const { value, checked } = event.target;
    setInnerCheckBox((prevItems) => {
      if (checked) {
        return [...prevItems, parseInt(value)];
      } else {
        return prevItems.filter((item) => item !== parseInt(value));
      }
    });    
  };

  const handlePreEligibleListing = async(id) =>{
    if(innerListShow==id){
      setInnerListShow(null)
      return
    }else{
      setInnerListShow(id)
    }
    try {
      setPageLoading(true)
      const result = await executeGraphQLMutation(
        GET_PRE_ELIGIBLE_INNER_LIST(id)
      );
      if (result.data.pre_eligible_partners_full_detail.length == 0) {
        setInnerList([])
        setInnerListShow(null)
        setPageLoading(false)
        toast.error("Case already approved")
      } else {
        setInnerList(result.data.pre_eligible_partners_full_detail);
        setPageLoading(false)
      }
    } catch (error) {
      toast.error("Failed");
      console.error("error", error);
      setPageLoading(false)
    }

  }

  const handleForceApprove = async(comment,setComment) =>{
    try {
      if(!innerCheckBox.length){
        return toast.error("Please check payout")
      }
      setPageLoading(true)
      const result = await executeGraphQLMutation(
        FORCE_APPROVE(JSON.stringify(innerCheckBox),comment)
      );
      if (result.data.force_approve.status) {
        toast.success(result.data.force_approve.msg);
        const filtered = innerList.filter(obj => !innerCheckBox.includes(obj.id))
        setInnerList(filtered);
        if(innerList.length==1){
          setInnerListShow(null)
        }
        setCheckedBox([])
        setComment("")
        setPageLoading(false);
        setShowConfirmation(false)
      } else {
        toast.error(result.data.force_approve.msg);
        setPageLoading(false);
        setShowConfirmation(false)
      }
    } catch (error) {
      toast.error("Failed");
      console.error("error", error);
      setPageLoading(false)
    }
    // setShowConfirmation(false)
  }


  return (
    <>
    {pageLoading ? <Loader /> : null}
      <div className="clearfix">
        <div className="table-responsive">
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>
                <th>Partner Details</th>
                <th>Disb.Details</th>
                <th>Disb.Amount</th>
                <th>Disb.Date</th>
                <th>Reason</th>
                <th>Payout Amount</th>
                {/* <th>Payout Points</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {partnerListing &&
                partnerListing.map((partner, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Lead ID : <span> {partner.lead_id}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Partner Code : <span> AMK{partner.partner_id}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Customer Name : <span> {partner.customer_name}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Partner Name : <span>{partner.partner_name}</span>
                        </div>
                        {partner.parent_partner_id>0 && <div style={{ opacity: 0.6 }}>
                          Parent Partner : <span>{partner.parent_partner_name} ({partner.parent_partner_id})</span>
                        </div>}
                        
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Fulfillment Type :{" "}
                          <span> {partner.fulfillment_type}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          {/* Product : <span> {productType.filter(product => product.id==partner.product)[0]?.name}</span> */}
                          Product : <span> {partner.product}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          {/* Bank : <span> {financers.filter(bank => bank.id==partner.financer_id)[0]?.name}</span> */}
                          Bank : <span> {partner.bank}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>
                            {new Intl.NumberFormat("en-IN").format(
                              partner.total_disbursal_amount
                            )}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>
                            {partner.disbursal_date
                              ? DateFormate(
                                  new Date(partner.disbursal_date),
                                  "dS mmm yyyy"
                                )
                              : null}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>{PAYOUT_STATUS_LIST[partner.payout_status]}</span><br></br>
                         {partner.points ?  <span>({partner.points})</span> : null }
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>
                            {new Intl.NumberFormat("en-IN").format(
                              partner.total_payout_amount
                            )}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span>{partner.points}</span>
                        </div>
                      </td> */}
                      <td>
                        <div style={{display:"flex",alignItems:"baseline",gap:"5px"}}>
                     {partner?.total_payout_amount>0 && <div className="btn-group btn-group-small mrg-r10">
                                      <div
                                        className="btn btn-default"
                                        title="Mark as View"
                                        onClick={() =>
                                          handlePreEligibleListing(partner.payout_partner_id)
                                        }
                                      >
                                        <i className="ic-remove_red_eyevisibility icons-normal"></i>
                                      </div>
                                    </div>}
                        <div className="">
                          <CheckBox
                            type="checkbox"
                            name="checkall[]"
                            id={`recalc_${partner.payout_partner_id}`}
                            key={`recalc_${partner.payout_partner_id}`}
                            value={partner.payout_partner_id}
                            checked={(partner?.payout_partner_id ? checkedBox.includes(partner.payout_partner_id) : false)}
                            onChange={(e)=> handleCheck(e)}
                          />
                        </div>

                        </div>

                      </td>
                    </tr>
                    {innerListShow!=null && 
                          innerListShow == partner.payout_partner_id ? (
                            <tr
                              style={{
                                backgroundColor: "rgb(245,245,245)",
                                paddingRight: "40px",
                                paddingLeft: "40px",
                              }}
                            >
                              <td colSpan={7}>
                                <div className="table-responsive">
                                  <table className="table table-bordered tablefontsize">
                                    <thead>
                                      <tr>
                                        <th className="payouttable">
                                          {"ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Point"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout Type"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout %"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout Amount"}
                                        </th>
                                        <th className="payouttable">
                                          {"TDS"}
                                        </th>
                                        <th className="payouttable">
                                          {"PAT"}
                                        </th>
                                        <th className="payouttable">
                                          {"Action"}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {innerList.map(
                                          (detail, index) => (
                                            <React.Fragment key={index}>
                                              <tr
                                                // onClick={() =>
                                                //   handleRowClick(index)
                                                // }
                                              >
                                                <td>
                                                    {detail.id}
                                                </td>
                                                <td>
                                                    {detail.booking_point}
                                                </td>
                                                <td>
                                                    {detail.payout_type}
                                                </td>
                                                <td>
                                                    {detail.payout_per}
                                                </td>

                                                <td>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {detail.payout_amount}
                                                  </div>
                                                </td>
                                                <td>
                                                  {detail.tds}
                                                </td>
                                                <td>
                                                  {detail.pat}
                                                </td>
                                                <td>
                                                <div className="col-sm-3">
                                                  <CheckBox
                                                    type="checkbox"
                                                    name="checkall[]"
                                                    id={`inner_${detail.id}`}
                                                    key={`inner_${detail.id}`}
                                                    value={detail.id}
                                                    checked={(detail.id ? innerCheckBox.includes(detail.id) : false)}
                                                    onChange={(e)=> handleInnerCheck(e)}
                                                  />
                                                </div>
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        )}
                                    </tbody>
                                    <CommentRow innerCheckBox={innerCheckBox} setShowConfirmation={setShowConfirmation} showConfirmation={showConfirmation} handleForceApprove={handleForceApprove}/>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                  </React.Fragment>
                ))}
              {!partnerListing ||
                (partnerListing.length === 0 && (
                  <tr>
                    <td colSpan={3}>No payout found!</td>
                  </tr>
                ))}
            </tbody>
            <tr>
              <td colSpan={3}>
                {" "}
                <Pagination
                  pageCount={
                    eligiblepaginationdata && eligiblepaginationdata.totalpage
                      ? eligiblepaginationdata.totalpage
                      : 0
                  }
                  activePage={page_no ? page_no : 1}
                  onPaginate={onPaginate}
                />
              </td>
              <td>
                Total:{" "}
                {eligiblepaginationdata && eligiblepaginationdata.totalrecords
                  ? eligiblepaginationdata.totalrecords
                  : 0}{" "}
              </td>
            </tr>
          </table>

        </div>
      </div>
    </>
  );
};


const CommentRow = ({
  innerCheckBox,
  setShowConfirmation,
  handleForceApprove,
  showConfirmation
}) => {
  const [comment,setComment] = useState("")
  const [error,setError] = useState("")
  if (!innerCheckBox || innerCheckBox.length === 0) {
    return null;
  }

  return (
    <>
    
    <tr>
      <td colSpan={4}></td>
      <td colSpan={2}>
        <div className="comment-form">
          <InputField
            error={error}
            placeholder={'Enter Comment'}
            value={comment}
            onChange={(e) => {
              setError("")
              setComment(e.target.value)
            }}
            required={true}
          />
        </div>
      </td>
      <td colSpan={1}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if(comment){
              setError("")
              setShowConfirmation(true)
            }else{
              setError("Please Write Comment")
            }
          }}
          title="View History"
        >
          Force Approve
        </button>
      </td>
    </tr>
    {showConfirmation && <ConfirmPopUp
                  confirmNo={()=>setShowConfirmation(false)}
                  confirmYes={()=>handleForceApprove(comment,setComment)}
                  message="Are you sure you want to Forcefully Approve ?"
              />}
      </>
  );
};

export default PreEligibleTableContent;
