import React, { useState, useMemo, useEffect, useRef } from "react";
import BreITRSection from "./BreITRSection";
import BreBankIncome from "./BreBankIncome";
import BreCoApplicant from "./BreCoApplicant";
import { useSelector } from "react-redux";
import { AMOUNT_TYPE_OPTIONS } from "../../../config/constants";
import AdditionalIncome from "../../elements/AdditionalIncome";
import {
  SAVE_OFFER_BANK_DETAILS,
  SAVE_OFFER_ITR_DETAILS,
} from "../../../services/bre.gql";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import Formsy from "formsy-react";
import FormsyInputField from "../../elements/FormsyInputField";
import BreObligations from "./BreObligations";

const BreIncomeDetailsSE = ({ setBreIncomeDetails, setNextTabActive }) => {
  const client = useApolloClient();
  const irtYesRadioRef = useRef(null);
  const irtNoRadioRef = useRef(null);
  const addIncYesRadioRef = useRef(null);
  const addIncNoRadioRef = useRef(null);
  const isCoAppYesRadioRef = useRef(null);
  const isCoAppNoRadioRef = useRef(null);
  const itrCalRadioRef = useRef(null);
  const bankCalRadioRef = useRef(null);
  const [incomeDetails, setIncomeDetails] = useState({});
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(1);
  const [hasAdditionalIncome, setHasAdditionalIncome] = useState(false);
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    const RENT_INCOME_ID = 1,
      OTHER_INCOME_ID = 5,
      LIQUID_INCOME_ID = 6;
    options["amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] =
      options["additional_income_type"]
        ?.map((obj) => ({
          ...obj,
          value: obj.id,
        }))
        ?.filter(
          (obj) =>
            obj.value == RENT_INCOME_ID ||
            obj.value == OTHER_INCOME_ID ||
            obj.value == LIQUID_INCOME_ID
        ) || [];
    options["filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    return options;
  }, [masterdata]);

  useEffect(() => {
    if (lead && lead.leadDetail) {
      const obj = {};
      const itrDetails = lead.leadDetail?.customer.offer_itr_details || [];
      obj["income_calculation_mode"] = lead.leadDetail?.customer.offer_type;
      obj["bureau_score"] = lead.leadDetail?.lead_details.cibil_score;
      obj["is_additional_income"] =
        lead.leadDetail?.customer.is_additional_income;
      obj["additional_income"] = lead.leadDetail?.customer.additional_income;
      obj["is_obligation"] = lead.leadDetail?.customer.is_obligation;
      obj["existing_emi_amount"] =
        lead.leadDetail?.customer.existing_emi_amount;
      obj["emi_ending_six_month"] =
        lead.leadDetail?.customer.emi_ending_six_month;
      obj["is_co_applicant"] =
        lead.leadDetail.co_applicant.length > 0 ? "yes" : "no";
      obj["co_applicant"] = lead.leadDetail.co_applicant || [];
      if (itrDetails?.length > 0) {
        obj["is_customer_file_itr"] = itrDetails[0].is_itr_filled;
      }

      if (obj.is_additional_income === "yes") {
        setHasAdditionalIncome(true);
        for (let i = 0; i < obj.additional_income.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`filtered_additional_income_type_${i + 1}`] = ALL_OPTIONS[
            `filtered_additional_income_type_${i}`
          ]?.filter(
            (ob) => ob.value !== obj.additional_income[i]?.income_type_id
          );
        }
      }

      setIncomeDetails((current) => ({
        ...current,
        ...obj,
      }));
      delete obj.co_applicant;
      setBreIncomeDetails(null, null, obj);
    }
  }, [lead]);

  const handleRadioChange = (event) => {
    if (event.target) {
      const { name, id } = event.target;

      if (name === "is_additional_income" && id === "yes") {
        handleAddAdditionalIncome(0);
        setHasAdditionalIncome(true);
      }
      if (name === "is_additional_income" && id === "no") {
        setHasAdditionalIncome(false);
        setAdditionalIncomeCount(0);
        setAdditionalIncomeFormFields([]);
      }

      // add slight delay to state updates and component re-renders
      setTimeout(() => {
        setIncomeDetails((current) => ({
          ...current,
          [name]: id,
        }));
        setBreIncomeDetails(name, id);
      }, 0);
    }
  };

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `additional_income_type_${index}`
      );

      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  const handleCoApplicantDetails = (coApplicants) => {
    setIncomeDetails((current) => ({
      ...current,
      co_applicant: coApplicants,
    }));
    setBreIncomeDetails("co_applicant", coApplicants);
  };

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`filtered_additional_income_type_${index + 1}`] = ALL_OPTIONS[
        `filtered_additional_income_type_${index}`
      ].filter((obj) => obj.value !== data.value);
    }
  };

  const handleSetIncomeData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setIncomeDetails((current) => ({
        ...current,
        ...(obj ? obj : { [key]: value }),
      }));
      if (obj) {
        setBreIncomeDetails(null, null, incomeDetails);
      } else {
        setBreIncomeDetails(key, value);
      }
    }
  };

  const handleSetAdditionalIncomeData = (data) => {
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: data,
    }));
    setBreIncomeDetails("additional_income", data);
  };

  const handleRemoveAdditionalIncome = (index) => {
    const existingAddIncomeField = [...additionalIncomeFormFields];
    const existingAddIncome = [...incomeDetails["additional_income"]];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: null,
      amount_type: "",
      amount: "",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: existingAddIncome,
    }));
  };

  const handleSaveIncomeDetailsSE = async () => {
    try {
      const {
        income_calculation_mode,
        is_customer_file_itr,
        year_1,
        year_2,
        no_of_accounts,
        is_additional_income,
        is_co_applicant,
        bureau_score,
        is_obligation,
        existing_emi_amount,
        emi_ending_six_month,
      } = incomeDetails;

      const lead_id = lead?.leadDetail?.id || "";
      const customer_id = lead?.leadDetail?.customer.customer_id;
      const itrDetails = [],
        bankDetails = [],
        variables = {};
      let co_applicant = [],
        ca_income_calculation_mode = "";

      const customer = {
        is_additional_income: is_additional_income,
        first_name: lead?.leadDetail?.customer.first_name,
        last_name: lead?.leadDetail?.customer.last_name,
        mobile: lead?.leadDetail?.customer.mobile,
        offer_type: income_calculation_mode,
        is_obligation,
        existing_emi_amount,
        emi_ending_six_month,
      };

      if (is_additional_income === "yes") {
        const additionalIncome = incomeDetails.additional_income?.map(
          (income) => {
            return {
              ...(income.id ? { id: income?.id } : {}),
              lead_id,
              customer_id,
              customer_type: "customer",
              income_type_id: income.income_type_id,
              amount_type: income.amount_type,
              amount: income.amount,
              status: income.status,
            };
          }
        );
        customer["additional_income"] = additionalIncome;
      }

      if (is_additional_income === "no") {
        const obj = {
          lead_id,
          customer_id,
          customer_type: "customer",
        };
        customer["additional_income"] = [obj];
      }

      if (is_co_applicant === "yes") {
        co_applicant = incomeDetails["co_applicant"]?.map((coApp) => {
          let ca_additionalIncome = [];
          if (coApp["ca_is_additional_income"] === "yes") {
            ca_additionalIncome = coApp.ca_additional_income?.map((income) => {
              return {
                ...(income.id ? { id: income?.id } : {}),
                lead_id,
                customer_id: coApp.id,
                customer_type: "co_applicant",
                income_type_id: income.ca_income_type_id,
                amount_type: income.ca_amount_type,
                amount: income.ca_amount,
              };
            });
          }

          if (+coApp.ca_profession === 3) {
            if (coApp.ca_income_calculation_mode === "itr") {
              ca_income_calculation_mode = "itr";
              if (coApp?.year_1) {
                itrDetails.push({
                  ...(coApp?.year_1?.id ? { id: coApp.year_1?.id } : {}),
                  lead_id,
                  is_itr_filled: coApp?.is_coapplicant_file_itr,
                  npat: coApp?.year_1.npat || "",
                  depreciation: coApp?.year_1.depreciation || "",
                  interest: coApp?.year_1.interest || "",
                  tax_paid: coApp?.year_1.tax_paid || "",
                  itr_year: "1",
                  customer_id: coApp?.id,
                  customer_type: "co_applicant",
                });
              }
              if (coApp?.year_2) {
                itrDetails.push({
                  ...(coApp?.year_2?.id ? { id: coApp.year_2?.id } : {}),
                  lead_id,
                  is_itr_filled: coApp?.is_coapplicant_file_itr,
                  npat: coApp?.year_2.npat || "",
                  depreciation: coApp?.year_2.depreciation || "",
                  interest: coApp?.year_2.interest || "",
                  tax_paid: coApp?.year_2.tax_paid || "",
                  itr_year: "1",
                  customer_id: coApp?.id,
                  customer_type: "co_applicant",
                });
              }
            }
            if (coApp.ca_income_calculation_mode === "bank") {
              for (let i = 0; i < coApp.no_of_accounts; i++) {
                bankDetails.push({
                  ...(coApp[`bank_details_id_${i}`]
                    ? { id: coApp[`bank_details_id_${i}`] }
                    : {}),
                  lead_id,
                  account_type: coApp[`bank_account_type_${i}`] || "",
                  amount: coApp[`bank_account_avg_amount_${i}`] || "",
                  customer_id: coApp?.id,
                  customer_type: "co_applicant",
                });
              }
            }
          }
          return {
            id: coApp.id,
            ca_profession: +coApp.ca_profession,
            ca_gross_monthly_salary: coApp.ca_gross_monthly_salary,
            ca_monthly_salary: coApp.ca_monthly_salary,
            ca_first_name: coApp.ca_first_name,
            ca_is_additional_income: coApp?.ca_is_additional_income || "no",
            ca_additional_income: ca_additionalIncome,
            ca_no_of_accounts: coApp?.no_of_accounts || null,
            ca_offer_type: coApp?.ca_income_calculation_mode || "",
            ca_existing_emi_amount: coApp?.ca_existing_emi_amount || null,
            ca_emi_ending_six_month: coApp?.ca_emi_ending_six_month || null,
            ca_is_obligation: coApp?.ca_is_obligation || "",
          };
        });
      }

      const variables1 = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            cibil_score: bureau_score,
          },
          customer,
          co_applicant,
        },
      };

      if (income_calculation_mode === "itr") {
        if (year_1) {
          itrDetails.push({
            ...(year_1.id ? { id: year_1?.id } : {}),
            lead_id,
            is_itr_filled: is_customer_file_itr,
            npat: year_1.npat || "",
            depreciation: year_1.depreciation || "",
            interest: year_1.interest || "",
            tax_paid: year_1.tax_paid || "",
            itr_year: "1",
            customer_id,
            customer_type: "customer",
          });
        }

        if (year_2) {
          itrDetails.push({
            ...(year_2.id ? { id: year_2?.id } : {}),
            lead_id,
            is_itr_filled: is_customer_file_itr,
            npat: year_2.npat || "",
            depreciation: year_2.depreciation || "",
            interest: year_2.interest || "",
            tax_paid: year_2.tax_paid || "",
            itr_year: "2",
            customer_id,
            customer_type: "customer",
          });
        }
      } else if (income_calculation_mode === "bank") {
        for (let i = 0; i < no_of_accounts; i++) {
          bankDetails.push({
            ...(incomeDetails[`bank_details_id_${i}`]
              ? { id: incomeDetails[`bank_details_id_${i}`] }
              : {}),
            lead_id,
            account_type: incomeDetails[`bank_account_type_${i}`] || "",
            amount: incomeDetails[`bank_account_avg_amount_${i}`] || "",
            customer_id,
            customer_type: "customer",
          });
        }
        variables1.LeadAdditionalInput.customer["no_of_accounts"] =
          no_of_accounts;
      }

      const resp = await executeGraphQLMutation(
        SAVE_CUST_DETAILS(user_id),
        variables1,
        client
      );

      let response = resp?.data?.save_additional_info || [];
      let finalItr = [],
        finalBankInc = [];

      for (let i = 0; i < response[0]?.co_applicant?.length; i++) {
        const coApp = response[0].co_applicant[i];
        finalItr = itrDetails?.map((obj) => {
          if (obj.customer_type === "co_applicant") {
            return {
              ...obj,
              ...(obj.customer_id ? {} : { customer_id: coApp?.id }),
            };
          } else {
            return obj;
          }
        });
        finalBankInc = bankDetails?.map((obj) => {
          if (obj.customer_type === "co_applicant") {
            return {
              ...obj,
              ...(obj.customer_id ? {} : { customer_id: coApp?.id }),
            };
          } else {
            return obj;
          }
        });
      }

      if (itrDetails.length > 0) {
        const itrVariable = {
          offerItrDetails: {
            offer_itr_details: finalItr.length ? finalItr : itrDetails,
          },
        };
        await executeGraphQLMutation(
          SAVE_OFFER_ITR_DETAILS,
          itrVariable,
          client
        );
      } else if (bankDetails.length > 0) {
        const bankVariable = {
          offerBankDetails: {
            offer_bank_details: finalBankInc.length
              ? finalBankInc
              : bankDetails,
          },
        };
        await executeGraphQLMutation(
          SAVE_OFFER_BANK_DETAILS,
          bankVariable,
          client
        );
      }

      toast.success("Success");
      setBreIncomeDetails(null, null, incomeDetails);
      setNextTabActive();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        [id]: value,
      }));
      setBreIncomeDetails(id, value);
    }
  };

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setIncomeDetails((current) => ({
        ...current,
        ...(obj ? { ...obj } : { [key]: value }),
      }));
      if (obj) {
        setBreIncomeDetails(null, null, obj);
      } else {
        setBreIncomeDetails(key, value);
      }
    }
  };

  return (
    <>
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        onValid={() => null}
        onInvalid={() => null}
      >
        <div className="row">
          <fieldset className={"form-filed col-md-6"}>
            <FormsyInputField
              id={"bureau_score"}
              name={"bureau_score"}
              type={"number"}
              value={incomeDetails["bureau_score"] || ""}
              placeholder=" "
              thousandsGroupStyle="lakh"
              thousandSeparator={","}
              decimalSeparator={"."}
              maxLength={"3"}
              label={"Bureau Score"}
              format={""}
              validations={"isNumeric"}
              validationError={""}
              readOnly={false}
              required={true}
              onChange={handleInputChange}
            />
          </fieldset>
        </div>
      </Formsy>

      <h4 className="additionalincome">Generate Offer by which mode ?</h4>
      <div className="propertyflex">
        <input
          type="radio"
          id="itr"
          name="income_calculation_mode"
          onChange={handleRadioChange}
          ref={itrCalRadioRef}
          checked={
            incomeDetails["income_calculation_mode"] === "itr" ? true : false
          }
        />
        <label htmlFor="itr">ITR Details</label>

        <input
          type="radio"
          id="bank"
          name="income_calculation_mode"
          onChange={handleRadioChange}
          ref={bankCalRadioRef}
          checked={
            incomeDetails["income_calculation_mode"] === "bank" ? true : false
          }
        />
        <label htmlFor="bank">Bank Income Details</label>
      </div>

      {incomeDetails["income_calculation_mode"] === "itr" && (
        <>
          <h4 className="additionalincome">Does the customer file ITR ?</h4>
          <div className="propertyflex">
            <input
              type="radio"
              id="yes"
              name="is_customer_file_itr"
              onChange={handleRadioChange}
              ref={irtYesRadioRef}
              checked={
                incomeDetails["is_customer_file_itr"] === "yes" ? true : false
              }
            />
            <label htmlFor="yes">Yes</label>

            <input
              type="radio"
              id="no"
              name="is_customer_file_itr"
              onChange={handleRadioChange}
              ref={irtNoRadioRef}
              checked={
                incomeDetails["is_customer_file_itr"] === "no" ? true : false
              }
            />
            <label htmlFor="no">No</label>
          </div>

          {incomeDetails["is_customer_file_itr"] === "yes" && (
            <BreITRSection setITRDetails={handleSetIncomeData} />
          )}
        </>
      )}

      {incomeDetails["income_calculation_mode"] === "bank" && (
        <BreBankIncome setBankIncomeDetails={handleSetIncomeData} />
      )}

      {incomeDetails["income_calculation_mode"] === "itr" && (
        <>
          <h4 className="additionalincome">
            Does the customer have any additional Income?
          </h4>
          <div className="propertyflex">
            <input
              type="radio"
              id="yes"
              name="is_additional_income"
              onChange={handleRadioChange}
              ref={addIncYesRadioRef}
              checked={
                incomeDetails["is_additional_income"] === "yes" ? true : false
              }
            />
            <label htmlFor="yes">Yes</label>
            <input
              type="radio"
              id="no"
              label="No"
              name="is_additional_income"
              onChange={handleRadioChange}
              ref={addIncNoRadioRef}
              checked={
                incomeDetails["is_additional_income"] === "no" ? true : false
              }
            />
            <label htmlFor="no">No</label>
          </div>

          {/* ADDITIONAL INCOME FIELDS */}
          {hasAdditionalIncome && (
            <>
              {additionalIncomeFormFields.map((data, index) => (
                <AdditionalIncome
                  index={index}
                  ALL_OPTIONS={ALL_OPTIONS}
                  setAdditionalIncomeType={handleAdditionalIncomeType}
                  removeAdditionalIncome={handleRemoveAdditionalIncome}
                  setAdditionalIncomeData={handleSetAdditionalIncomeData}
                  additionalIncomeData={incomeDetails["additional_income"]}
                  key={index}
                />
              ))}

              {additionalIncomeCount < 3 && (
                <div
                  className="additionalincome"
                  onClick={() =>
                    handleAddAdditionalIncome(additionalIncomeCount)
                  }
                >
                  <span>+</span>
                  <div>Additional Income</div>
                </div>
              )}
            </>
          )}
        </>
      )}

      <h4 className="additionalincome">
        Does the customer have any obligation?
      </h4>
      <div className="propertyflex">
        <input
          type="radio"
          id="yes"
          name="is_obligation"
          onChange={handleRadioChange}
          checked={incomeDetails["is_obligation"] === "yes" ? true : false}
        />
        <label htmlFor="yes">Yes</label>

        <input
          type="radio"
          id="no"
          name="is_obligation"
          onChange={handleRadioChange}
          checked={incomeDetails["is_obligation"] === "no" ? true : false}
        />
        <label htmlFor="no">No</label>
      </div>

      {incomeDetails["is_obligation"] === "yes" && (
        <BreObligations
          setObligationDetails={handleSetObligationData}
          customerType={"customer"}
        />
      )}

      <h4 className="additionalincome">
        Does the customer have any financial co-applicants ?
      </h4>
      <div className="propertyflex">
        <input
          type="radio"
          id="yes"
          name="is_co_applicant"
          onChange={handleRadioChange}
          ref={isCoAppYesRadioRef}
          checked={incomeDetails["is_co_applicant"] === "yes" ? true : false}
        />
        <label htmlFor="yes">Yes</label>
        <input
          type="radio"
          id="no"
          label="No"
          name="is_co_applicant"
          onChange={handleRadioChange}
          ref={isCoAppNoRadioRef}
          checked={incomeDetails["is_co_applicant"] === "no" ? true : false}
        />
        <label htmlFor="no">No</label>
      </div>

      {incomeDetails["is_co_applicant"] === "yes" && (
        <BreCoApplicant
          setCoApplicantIncomeDetails={handleCoApplicantDetails}
        />
      )}

      <div className="btn-save-remarks">
        <button
          type="submit"
          className="btn-primary"
          onClick={handleSaveIncomeDetailsSE}
        >
          Save & next
        </button>
      </div>
    </>
  );
};

export default BreIncomeDetailsSE;
