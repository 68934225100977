import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_BANK_POC_MAPPING } from '../../services/bank.gql';
import EmailThread from "../leads/EmailComms";


const LeadCommuniationEmailModal = () => {
    const [activeTab, setActiveTab] = useState("customer");
    const [bankerEmail, setBankerEmail] = useState("");
    const client = useApolloClient();

    const handleSelectTab = (eventKey) => setActiveTab(eventKey);

    const { leadDetail } = useSelector(({ lead }) => ({
        leadDetail: lead.leadDetail,
    }));

    const getBankPOCMappingData = async (lead_id) => {
        try {
            const result = await executeGraphQLQuery(GET_BANK_POC_MAPPING(lead_id), client);
            const bankRecord = result?.data?.get_bank_poc_mapping?.bank_record;

            if (bankRecord?.length > 0) {
                const bankerEmailList = bankRecord.map(item => { return item.email })
                if (bankerEmailList.length) {
                    setBankerEmail(bankerEmailList.join(","));
                }
            }
        } catch (error) {
            console.error("Error fetching bank POC mapping data:", error);
        }
    };

    useEffect(() => {
        if (leadDetail?.id) {
            getBankPOCMappingData(leadDetail.id);
        }
    }, [leadDetail]);

    return (
        <>
            <Tab.Container
                id="left-tabs-example"
                activeKey={activeTab}
                onSelect={handleSelectTab}
            >
                <Nav variant="pills" className="flex-column">
                    <div className="tab-list">
                        <Nav.Item className="nav-item">
                            <Nav.Link eventKey="customer">
                                <strong>Customer</strong>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                            <Nav.Link eventKey="bank">
                                <strong>Bank POC</strong>
                            </Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="customer">
                        <div className="file-upload-bx">
                            <EmailThread emailForComs={leadDetail?.customer?.email} formType="customer" />
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="bank">
                        <div className="file-upload-bx">
                            <EmailThread emailForComs={bankerEmail} formType="bank_poc" />
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
};

export default LeadCommuniationEmailModal;
