import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from "react";

import RadioBox from '../elements/Radiobox'
import InputField from "../elements/InputField";
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from "../../services/MasterService";
import { Navigate, NavLink, BrowserRouter as Router } from 'react-router-dom';
import { Add_BANK, SAVE_PRODUCT_DSA_CODE, FINEX_BANK_LIST, FINEX_MASTER_LIST,PAYOUT_POINT_DETAILS } from "../../services/scheme.gql";
import { executeGraphQLQuery, executeGraphQLMutation,executeGraphQLMutationFinex } from "../../common/partnerExecuteGraphQLQuery";
import {    
    UPDATE_BANK_ONBOARDING_PAYIN,
    BANK_ONBOARDING_PAYIN_LIST
  } from "../../services/mis.gql";
import DateFormate from 'dateformat';
import { useParams } from "react-router-dom";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';

const AddNewPartner = (props) => {    
    const param = useParams()
    const { handleTabClick } = props;
    const [formData, setFormData] = useState({});
    const [selectstatus, setselectstatus] = useState("");
    const [cityAllList, setcityAllList] = useState([]);
    const [errors, setErrors] = useState("");
    const [financers, setFinancers] = useState([]);
    const [product_type, setProductType] = useState([]);   
    const [payout_points, setPayoutpoints] = useState([]);
    const [bookingpoints, setBookingPoints] = useState([]);
    let payInList = []
    let scheme_view =  IS_USER_ACCESS_PAGE("payout", "scheme_view")?.is_edit_access;

    const handleInput = (e, key = "") => {
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.value : e.value;

        setFormData({ ...formData, [name]: val })
        let newErrors = { ...errors };
        newErrors[name] = ('');
        setErrors(newErrors);

    }


    useEffect(() => { 
      //  getPayoutDataList();
        getFinexMasterData()
    }, [props.schemedetailsData]);

    const handleUpdateOnboardPoint = async () => {
        try {
          if(!formData.financer_id){
            return false;
          }
          const variables =   {
            addpayinconfigargs: {
                financer_id: formData.financer_id,
                bank_usp: formData.bank_usp ? formData.bank_usp : ""
                // is_on_payin: formData.is_on_payin ? formData.is_on_payin : '0',
                // is_partnership: formData.is_partnership ? formData.is_partnership : "",
                // lap_dsa_code: formData.lap_dsa_code ? formData.lap_dsa_code : "",
                // payout_calc_date: formData.payout_calc_date ? formData.payout_calc_date : "",
                // payout_calc_amount: formData.payout_calc_amount ? formData.payout_calc_amount : "",
                // payout_disbursal: parseInt(formData.payout_disbursal) ? parseInt(formData.payout_disbursal) : "",
               // product_type: formData.dsa_type ? formData.dsa_type : 0,
            }
         }
         if (validateForm()) {
            try {    
          const response = await executeGraphQLMutation(
            UPDATE_BANK_ONBOARDING_PAYIN,
            variables
          );
    
          if (response.data?.save_on_payin) {
            addBank(formData.financer_id)
           // toast.success(response.data?.save_on_payin);
            //setTimeout(redirectpage("/bank/bank-onboarding"), 1000);
          }
        } catch (error) {
            toast.error("Failed");
        }
      }
        } catch (error) {
          console.log("Error  ", error.message);
          toast.error(error.message);
        }
      };
    
    const base64EncodeId = (id) => {
        return btoa(id.toString());
    }

    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };
        if (!newFormData['financer_id'] || newFormData['financer_id'] === '') {
            newErrors['financer_id'] = ('Please Select Bank');
            validForm = false;
        }
        if (!newFormData['bank_usp'] || newFormData['bank_usp'] === '') {
            newErrors['bank_usp'] = ('Please Enter Bank USP');
            validForm = false;
        }

        // if (!newFormData['lap_dsa_code'] || newFormData['lap_dsa_code'] === '') {
        //     newErrors['lap_dsa_code'] = ('Please Enter LAP dsa code');
        //     validForm = false;
        // }

        // if (!newFormData['hl_dsa_code'] || newFormData['hl_dsa_code'] === '') {
        //     newErrors['hl_dsa_code'] = ('Please Enter HL dsa code');
        //     validForm = false;
        // }

        // if (!newFormData['payout_calc_date'] || newFormData['payout_calc_date'] === '') {
        //     newErrors['payout_calc_date'] = ('Please Select Payout Calc date');
        //     validForm = false;
        // }

        // if (!newFormData['payout_calc_amount'] || newFormData['payout_calc_amount'] === '') {
        //     newErrors['payout_calc_amount'] = ('Please Select Payout Calc Amount');
        //     validForm = false;
        // }

        // if (newFormData['payout_disbursal'] && (parseFloat(newFormData['payout_disbursal'])<=0 || parseFloat(newFormData['payout_disbursal'])>100)) {
        //     newErrors['payout_disbursal'] = ('Please Enter % Payout Disbursal in Between 0-100');
        //     validForm = false;
        // } else {
        //     newErrors['payout_disbursal'] = "";
        // }
        console.log({newErrors})
        setErrors(newErrors);
        return validForm;
    };

       
    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.bank_pa) {
            const bankData = [];
            const producttypedata = [];
            const bookingPointsData = [];
            const data = masterData.data.bank_pa;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData);
                payout_points.map(point => {
                    bookingPointsData.push({ id: point.lead_status, name: point.point_name });
                });
                setBookingPoints(bookingPointsData);
                if (products && products.length > 0) {
                    products.map(ptype => {
                        producttypedata.push({ id: ptype.id, name: ptype.label })
                    })
                    setProductType(producttypedata)
                }
            }
        }
    }

   

    const redirectpage = (url) => {
        window.location.href = url
    }

    const handleFinancier = (n,e) => {
            getBankOnboardingPayinList(e.id)
           // setFormData({ ...formData, [n]: e.id})
    }

    const handleDsaType = (e,n) => {
        setFormData({ ...formData, [n]: e.id})
    }

    const payoutCalcOn = [
        { value: 'login_data', label: "Login Date"},
        { value: 'senction_date', label: "Sanction Date"},
        { value: 'disbursal_date', label: "Disbursal Date"},
        { value: 'pdd_date', label: "PDD Date"},
        { value: 'transaction_date', label: "Transaction Date"},
        { value: 'mis_date', label: "MIS Date"},
        { value: 'payin_date', label: "Payin Date"},        
    ]

    const payoutCalcAmount = [
        { value: 'login_amount', label: "Login Amount"},
        { value: 'senction_amount', label: "Sanction Amount"},
        { value: 'disbursal_amount', label: "Disbursal Amount"},
            
    ]

    const handlePayoutCalc = (e,n) => {
        setFormData({ ...formData, [n]: e.id})
    }



    const handleCheckBoxDefault = (n, e) => {
        const checked = e.target.checked;
        switch (n) {
            case 'is_payin':
                setFormData({ ...formData, [n]: checked ? '1' : '0' });
                break;
            
        }
    };

    const getBankOnboardingPayinList = async (financier_id) => {
        try {        
          const variables = { filter_params:{financer_id:financier_id} };
          variables.page_no = 1;
          const response = await executeGraphQLMutation(
            BANK_ONBOARDING_PAYIN_LIST(1),
            variables
          );
          if (response.data?.payin_config_list && response.data?.payin_config_list.length>0 && response.data?.payin_config_list[0]?.payinlist.length>0 ) {
            const payInList = response.data?.payin_config_list[0]?.payinlist[0];   
             const newFormdata = {...formData}
             newFormdata.financer_id = financier_id;
            //  newFormdata.is_on_payin = parseInt(payInList.is_on_payin);
              newFormdata.is_partnership = payInList.is_partnership;
            //  newFormdata.hl_dsa_code = payInList.hl_dsa_code;
            //  newFormdata.lap_dsa_code = payInList.lap_dsa_code;
            //  newFormdata.payout_calc_date = payInList.payout_calc_date;
            //  newFormdata.payout_calc_amount = payInList.payout_calc_amount;
            setFormData(newFormdata);
           // setTimeout(redirectpage("/bank/bank-onboarding"), 1000);
           
          }else{
            const newFormdata = {...formData}
            newFormdata.financer_id = financier_id;
            newFormdata.is_on_payin = 0;
            newFormdata.is_partnership = payInList.is_partnership;
            // newFormdata.hl_dsa_code = "";
            // newFormdata.lap_dsa_code = "";
            // newFormdata.payout_calc_date = "";
            // newFormdata.payout_calc_amount = "";
      //      newFormdata.financer_id = financier_id
            setFormData({financer_id:financier_id})
          }
       
        } catch (error) {
          console.log("Error 🔻 ", error.message);
        }
      };
    
      const addBank = async (financer_id) => {
        // const formDataNew = { ...formData };
        // formDataNew.status = selectstatus?.value;
          const data = {};
         const variables = {
             addeditbankargs: {
                 bank_id: financer_id,
                 is_partnership: '1',                
             },
         };
         data.addeditbankargs = variables;
         if (validateForm()) {
             try {
                 const result = await executeGraphQLMutationFinex(Add_BANK, variables);
                 if (result && result.data && result.data.save_bank.msg) {
                    if (result?.data?.save_bank?.error) {
                         toast.error(result?.data?.save_bank || "Success");
                     } else {                       
                              toast.success(result?.data?.save_bank.msg || "Success");
                              if(formData.is_partnership==1){
                                 props.handleTabClick("new_partner_ship")
                              }else{
                                 setTimeout(redirectpage("/bank/bank-onboarding"), 10000);
                              }
                     }                 
                 }
             } catch (error) {
                 toast.error("Failed");
             }
         }
     }


    return (
        <>
            <div className="card">

                <div className="card-body">                  
                 
                    <div className="row">
                        <div className="col-sm-3">

                            <label>Bank<span className="required" style={{color: 'red'}}>*</span></label>
                            <Select
                                    id="financer_id"
                                    onChange={(e) => handleFinancier('financer_id', e)}
                                    options={financers}
                                    label={"Financier"}
                                    name="financer_id"
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                    dataerror={errors.financier_id}
                                    value={financers.filter(({ id }) => id === formData.financer_id)}
                                    // isDisabled = {is_disabled}
                                   
                                />
                            {errors.financier_id &&
                                <div className="error show" >{errors.financier_id}</div>
                            }
                        </div>
                        {/* <div className="col-sm-3">
                        <label>Payout calc on Amount </label>                       
                           <Select
                                id="payout_calc_amount"                                
                                onChange={(e) => handleInput(e, 'payout_calc_amount')}
                                options={payoutCalcAmount}
                                name="payout_calc_amount"                               
                                value={payoutCalcAmount.filter(({ value }) => value === formData.payout_calc_amount)}                       
                                dataerror={errors.payout_calc_amount}
                                placeholder = "Please Payout Calc Amount"
                                validationreq = "true"
                            />
                             {errors.payout_calc_amount &&
                                    <div className="error show" >{errors.payout_calc_amount}</div>
                                }     
                         </div> */}

                         {/* <div className="col-sm-3">
                        <label>Payout calc on date </label>                       
                           <Select
                                id="payout_calc_date"                                
                                onChange={(e) => handleInput(e, 'payout_calc_date')}
                                options={payoutCalcOn}
                                name="payout_calc_date"                               
                                value={payoutCalcOn.filter(({ value }) => value === formData.payout_calc_date)}                       
                                dataerror={errors.payout_calc_date}
                                placeholder = "Please Payout Calc Date"
                                validationreq = "true"
                            />
                             {errors.payout_calc_date &&
                                    <div className="error show" >{errors.payout_calc_date}</div>
                                }     
                         </div> */}
                        
                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "bank_usp",
                                    type: "text",
                                    name: "bank_usp",
                                    label: ('Bank USP'),
                                    value: formData.bank_usp || '',
                                    placeholder: "Please Enter Bank USP ",
                                    dataerror: errors.bank_usp || '',
                                    validationreq: "true",
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                             {errors.hl_dsa_code &&
                                    <div className="error show" >{errors.bank_usp}</div>
                                } 
                        </div>

                        {/* <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "lap_dsa_code",
                                    type: "text",
                                    name: "lap_dsa_code",
                                    label: ('LAP DSA Code'),
                                    value: formData.lap_dsa_code || '',
                                    placeholder: "Please Enter DSA Code ",
                                    dataerror: errors.lap_dsa_code || '',
                                    validationreq: "true",
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>                       */}

                        

                        {/* <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "payout_disbursal",
                                    type: "number",
                                    name: "payout_disbursal",
                                    label: ('% Payout Disbursal'),
                                    value: formData.payout_disbursal || '',
                                    placeholder: "Please Enter % Payout Disbursal.",
                                    dataerror: errors.payout_disbursal || '',
                                    // validationreq: "true",                                    
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                            {errors.payout_disbursal &&
                                <div className="error show" >{errors.payout_disbursal}</div>
                            }
                        </div> 
                         */}

                        {/* <div className="col-sm-3">
                            <label>% Payin type bank</label><br />
                            <input
                                type="checkbox"
                                name="is_payin"
                                label="Is Payin type Bank"
                                id={'is_payin'}
                                key={'is_payin'}
                                value={formData.is_payin}
                                checked={formData.is_payin == '1' ? true : false}
                                onClick={(e) => handleCheckBoxDefault('is_payin', e)}
                            />
                        </div> */}

                    </div>

                    <div>
                        {formData.id && formData.id > 0 && formData.financer_id ?
                            <div className="text-center">
                                {scheme_view === 1 && (
                                    <button style={{ marginBottom: 10, marginTop: 20 }}
                                    //onClick={(e) => updateScheme(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Update</b>}</button>
                                )}
                               
                            </div>
                            :
                            <div className="text-center">
                                <button style={{ marginBottom: 10, marginTop: 20 }}
                                    onClick={(e) => handleUpdateOnboardPoint(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Enable Partnership</b>}</button>
                            </div>
                        }

                    </div>


                </div>
            </div>
           

        </>
    )
};


export default AddNewPartner;

