import _ from "lodash";

export const SETTING = {
    CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
    RESEND_OTP_TIME_IN_SECOND: 30,
};

export const ALPHA_NUMERIC_VALIDATION = /^[a-zA-Z0-9 ]+$/;
export const invalidNumberValues = ["-", "+", "e", "."];
export const NAME_VALIDATION = /^[A-Za-z\s]+$/;
export const API_URL = process.env.REACT_APP_GETWAY_API;
export const WS_URL = process.env.REACT_APP_WS_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;  
export const getFileExtensionFromURL = (url) => {
	try {
		return url && url.split(/\#|\?/)[0].split('.').pop().trim();
	} catch (e) {
		return '';
	}
}

export const ADMIN_ROLE_ID = 2;
export const MAKER_ROLE_ID = 5;
export const VERIFIER_ROLE_ID = 1;


export const  DOC_STATUS = [
	{label: "Pending", value: "0" },
	{label: "Uploaded", value: "1" },
	{label: "Verified", value: "2" },
]

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	// let loginData = localStorage.getItem("loginUserInfo");
	// let loginData = JSON.parse(localStorage.getItem('user_information'));
	//  let isSuperAdmin = loginData && loginData.role_id && loginData.role_id.includes(1);
	
	//  if(isSuperAdmin){
	// 	isUserHasRights=true;
	// 	isEditAccess=true;
	//  }else{
		const userAccessFeatures = JSON.parse(localStorage.getItem("userAccess"));
		if (userAccessFeatures && userAccessFeatures.length) {
			let isKeyExist = _.findKey(userAccessFeatures, function (v) {
				if (controller && v.controller && action && v.action) {
					return (
						v.controller.toLowerCase() === controller.toLowerCase() && 
						v.action.toLowerCase() === action.toLowerCase()
					);
				}
			});
			
			isUserHasRights = isKeyExist ? true : false;
			isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? 1 : 0) : 2
		}
	//}


	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};


export const CHECK_ASSIGN_USER = (assign_to) => {
	let loginData = JSON.parse(localStorage.getItem('user_information'));
	let isSuperAdmin = loginData && loginData?.role_id?.includes(1);
	if(isSuperAdmin) return false;
	return !(assign_to===loginData?.id);
};
export const TENURE_IN_MONTH = {
    "0": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
    "1": [{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "2": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "3": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "4": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "5": [{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "6": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
    "7": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
	"8": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
}

//login with gmail
export const GOOGLE_AUTH_URL = process.env.GOOGLE_AUTH_URL || "https://sos.ambak.com/google?redirect=";

export const USERS_FOR_VIEW_AMBAK_LEADS = ["ravi.singh@ambak.com"];

export const DISBURSE_REJECTION_ID = [38,39,40,41,42,43,44,45]    
export const DEALER_WALLET_ID = 39;

export const LOGGED_QUERY_ID = [15,19,22,25,29,33,37,41]    
export const APROVED_QUERY_ID = [73,75]    

export const RoleId = {
	"1": "Agent"
}

export const freshdeskApisUrl = 'https://ambak-help.freshdesk.com/api/v2/tickets';

export const headersConfig = {
	'Content-Type': 'application/json',
	'Authorization': 'Basic OWl2dXQ4cFpoSlR1VzR5SVpZOlg=',
};  

export const AMOUNT_TYPE_OPTIONS = [
	{ id: "account", label: "Account", value: "account" },
	{ id: "cash", label: "Cash", value: "cash" }
]

export const  SALUTATION = [
	{ label: "Mr", value: "Mr" },
	{ label: "Mrs", value: "Mrs" },
	{ label: "Miss", value: "Miss" },
	{ label: "Ms", value: "Ms" },
	{ label: "Other", value: "Other"}
]

export const signatureMobile={
	mobile: 7425840902
}
export const PAYOUT_STATUS_LIST = {
    0 : "Pending for payout calc",
    1 : "Partner Not Verified",
    2 : "Awaiting for payin",
    3 : "Payout Points not configured",
    4 : "100% Calculated",
    5 : "Scheme Not found",
	6 : "Child Partner Scheme not found",
	7 : "Child Partner is not applicable for payout"
}

export const PRE_LOGIN = {
	'upcoming'        :1,
	'overdue'         :2,
	'qualified'       :3,
	'docs_collected'  :4,
	'fresh'           :5,
	'not_connected'   :6,
	'interested'      :7,
	'not_interested'  :8
}
export const FRESH_DESK_API_KEY = "Basic OWl2dXQ4cFpoSlR1VzR5SVpZOkFtYmFrQDEx"

export const FRESH_DESK_DOMAIN_URL = "https://ambak-help.freshdesk.com/"


export const DIALER_API_HEADER = {
	api_key: 'Vs8ZHww7aS3CJ7XjBUlkq5LKZWXxZOVu9mAQU8CL',
	Authorization: '9ebad98f-2b93-41f6-b123-5b8971e327b7',
	content_type: 'application/json',
	url: 'https://kpi.knowlarity.com/Basic/v1/account/call/makecall',
	method: 'POST',
}