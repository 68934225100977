import React, { useEffect, useState } from "react";
import Select from "react-select";
import secureStorage from "../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MasterService from "../../services/MasterService";
import SubPartnerPayout from "./SubPartnerPayout";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { SCHEME_PARTNER_LISTING } from "../../services/scheme.gql";
import { toast } from "react-toastify";

const SubPartner = (props) => {
  const [currentSubPartnerId, setCurrentSubPartnerId] = useState("");
  const [partnerListing, setPartnerListing] = useState([]);
  const [page_no, setPageNo] = useState(1);
  const [parent_id, setParent_id] = useState("");
  const [is_default, setIs_default] = useState("");
  const [partnerData, setPartnerData] = useState({
    partner_status: 0,
    page_no: 1,
    search_by: "",
    search_text: "",
    is_subscribed: 1,
    scheme_id: "",
  });
  const schemedetailsData = props.schemedetailsData;
  let role;

  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  useEffect(() => {
    const fullUrl = window.location.href;
    const correctedUrl = fullUrl.replace(/\?(?=[^?]*\?)/, "&");
    const urlObject = new URL(correctedUrl);
    const searchParams = new URLSearchParams(urlObject.search);
    const type = searchParams.get("type");
    const defaultValue = searchParams.get("default");
    setIs_default(defaultValue);

    if (type) {
      setPartnerData((prev) => ({
        ...prev,
        scheme_id: type,
      }));
      if (defaultValue == 1) {
        PartnerList();
      } else if (defaultValue == 0) {
        getPartnerListWithFilters(type);
      }
    }
  }, []);

  const getPartnerListWithFilters = async (scheme_id) => {
    try {
      const result = await executeGraphQLMutation(
        SCHEME_PARTNER_LISTING(
          scheme_id,
          partnerData.search_by,
          partnerData.search_text,
          partnerData.partner_status,
          page_no,
          partnerData.is_subscribed
        )
      );
      if (result.data.partners && result.data.partners.length > 0) {
        const partners = result.data.partners[0].dealers;
        setPartnerListing(partners);
      } else {
        setPartnerListing([]);
      }
    } catch (error) {
      toast.error("Failed to fetch partner list.");
    }
  };

  const [subPartner, setSubPartner] = useState([]);

  const handlePatner = async (sname, ovalue) => {
    if (sname === "partner") {
      setParent_id(ovalue.id);
    }else if(sname === "partner_default"){
      setParent_id(ovalue.partnerId);
    }
    else if (sname === "sub_partner") {
      setCurrentSubPartnerId(ovalue?.sub_partner_id);
      return;
    } 
      const id = ovalue.partnerId || ovalue.id
      try {
        const response = await MasterService.get(
          "/partner/partner/sub-partner-list",
          { parent_partner_id: id, source: "payout" }
        );
        if (is_default == 1) {
          setSubPartner(response.data.data.sub_partner_list || []);
        } else {
          setSubPartner(response.data.data.sub_partner_list || []);
        }
      } catch (error) {
        toast.error("Failed to fetch sub-partner list.");
      }
    
  };

  const PartnerList = async () => {
    try {
      const response = await MasterService.post("/partner/partner/get-partner-list");
      setPartnerListing(response.data.data || []);
    } catch (error) {
      toast.error("Failed to fetch partner list.");
    }
  };

  return (
    <>
      <div className="search-wrap">
        <div className="row">
          {is_default == 0 ? (
            <div className="col-sm-1 col-md-3">
              <label>Partner</label>
              <Select
                id="partner"
                options={Array.isArray(partnerListing) ? partnerListing : []}
                onChange={(e) => handlePatner("partner", e)}
                name="partner"
                getOptionLabel={({ first_name, agent_code }) => ` ${first_name} : ${agent_code}`}
                getOptionValue={({ id }) => id}
              />
            </div>
          ) : (
            <div className="col-sm-1 col-md-3">
              <label>Partner</label>
              <Select
                id="partner"
                options={Array.isArray(partnerListing) ? partnerListing : []}
                onChange={(e) => handlePatner("partner_default", e)}
                name="partner"
                getOptionLabel={({ PartnerName, PartnerCode }) => ` ${PartnerName} : ${PartnerCode}`}
                getOptionValue={({ partnerId }) => partnerId}
              />
            </div>
          )}
        
          {is_default == 1 ? (

            <div className="col-sm-1 col-md-3">
              <label>Sub Partner</label>
              <Select
                id="sub_partner"
                options={subPartner}
                name="sub partner"
                getOptionLabel={({ sub_partner_name, sub_partner_id }) => ` ${sub_partner_name} : AMB${sub_partner_id}`}
                getOptionValue={({ sub_partner_id }) => sub_partner_id}
                onChange={(e) => handlePatner("sub_partner", e)}
              />
            </div>
          ) : (
            <div className="col-sm-1 col-md-3">
              <label>Sub Partner</label>
              <Select
                id="sub_partner"
                options={Array.isArray(subPartner) ? subPartner : []}
                name="sub partner"
                getOptionLabel={({ sub_partner_name, sub_partner_id }) => ` ${sub_partner_name} : AMB${sub_partner_id}`}
                getOptionValue={({ sub_partner_id }) => sub_partner_id}
                onChange={(e) => handlePatner("sub_partner", e)}
              />
            </div>
          )}
        </div>
      </div>
    
      {currentSubPartnerId && parent_id && (
        <SubPartnerPayout
          schemedetailsData={schemedetailsData}
          subPartnerId={currentSubPartnerId}
          parent_id={parent_id}
        />
        
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("ac_manager")(
  connect(mapStateToProps, mapDispatchToProps)(SubPartner)
);
