import React, { useEffect, useState, useRef } from "react";
import DateFormate from 'dateformat';
import { FINEX_MASTER_LIST,PAYOUT_POINT_DETAILS } from "../../services/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import { DELETE_PAYOUT_POINT, GET_PAYOUT_POINTS_WITH_FILTERS} from "../../services/payout.gql"
import { toast } from 'react-toastify';

const BookingPoint = ({payout_points_list, setRefreshList}) => {   

    // console.log("payout_points_list12333",payout_points_list);

    const [financers, setFinancers] = useState([]);
    const [bookingpoints, setBookingPoints] = useState([]);
    const [product_type, setProductType] = useState([]);
    const [payout_points, setPayoutpoints] = useState([])

    let bank_create =  IS_USER_ACCESS_PAGE("payout", "bank_create")?.is_edit_access;
    let bank_update =  IS_USER_ACCESS_PAGE("payout", "bank_update")?.is_edit_access;

    useEffect(() => {
        //updateReduxData()
        getFinexMasterData()
        getPayoutDataList()
       // getPayoutPoints()

    }, []);

    const getPayoutDataList =  async() => {
        const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
        const PayoutDataList = []
        result.data.points.map(point => {
           PayoutDataList.push({ id: point.id, name: point.booking_point });
       });
       setPayoutpoints(PayoutDataList)
   }
   
    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const bookingPointsData = []
            const producttypedata = [];
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];

            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
                payout_points.map(point => {
                    bookingPointsData.push({ id: point.lead_status, name: point.point_name });
                });
                setBookingPoints(bookingPointsData);

                if (products && products.length > 0) {
                    products.map(ptype => {
                        producttypedata.push({ id: ptype.id, name: ptype.label })
                    })
                    setProductType(producttypedata)
                }

            }

        }

    }

    const deletePayoutPoint = async(id)=>{
        try {
            if(window.confirm("Do you want to delete this payout poing?")){
                const responseData = await executeGraphQLQuery(DELETE_PAYOUT_POINT(id))
                if (responseData?.data.delete_payoutpoint && responseData?.data.delete_payoutpoint!="") {
                    toast.success(responseData?.data?.delete_payoutpoint || "Success");
                    // getPayoutPoints(1, bank_id)
                    setRefreshList(true)
                }else{
                    toast.success("Something went wrong!");
                }
               // getPayoutPoints()
            }
        } catch (error) {
            toast.success("Something went wrong!");
        }
    }

    return (
        <>
            <div className='modal-body' style={{overflowY: "scroll"}}>
              
                <h2 className="top-heading">Payout Point</h2>
                <div className='clearfix'>
                    <div className="card">
                        <div className='table-responsive '>
                            <table className='table table-bordered tablefontsize' >
                                <thead>
                                    <tr>
                                        <th>{('ID')}</th>
                                        <th>{('Bank')}</th>
                                        <th>{('% Payout')}</th>
                                        <th>{('Booking Point')}</th>
                                        <th>{('Product Type')}</th>
                                        <th>{('Start Date')}</th>
                                        <th>{('End Date')}</th>
                                        <th>{('Status')}</th>
                                        <th>{('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {JSON.stringify(payout_points_list)} */}
                                    {payout_points_list && payout_points_list.length > 0 && payout_points_list.map((point) => (


                                        <tr key={point.id}>
                                            <td>{point.id}</td>
                                            <td>{financers && financers.length > 0 && point.financier_id && financers.filter(bank => bank.id == point.financier_id)[0]?.name}</td>
                                            <td>{point.payout_per}</td>
                                            {/* <td>{payout_points.filter((points) => points.id == point.point_id)[0]?.name}</td> */}
                                            <td>{point.payoutpointgroup.map((point) => point.points.booking_point).join(" + ")}</td>
                                            <td>
                                                {product_type.find(pt => pt.id == point.product_type)?.name || ""}
                                            </td>
                                            <td>{DateFormate(new Date(point.start_date), "dS mmm yyyy")}</td>
                                            <td>{DateFormate(new Date(point.end_date), "dS mmm yyyy")}</td>
                                            <td>{point.status==1? "Active":"In-active"}</td>
                                            <td>

                                            <div style={{display:"flex"}}>
                                            {/* <div className="btn-group btn-group-small mrg-r10">
                                            <Link to={`/payout/edit/${point.id}`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal" style={{color:"gray"}}></i></Link>
                                            </div> */}
                                            {bank_update === 1&& (
                                                <div className="btn-group btn-group-small mrg-r10">
                                                <div onClick={()=>deletePayoutPoint(point.id)}>Remove</div>
                                                </div>
                                            )}
                                            
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BookingPoint;





