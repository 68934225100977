import { gql } from '@apollo/client';

export const LEAD_CREATION_FORM = gql`
    mutation create_loan($newLeadInput: CreateLoanInput!, $api_called_by: API_CALL_BY!, $user_id: Float,$role_id:Float, $partner_id:Float, $is_demo:String) {
    create_loan(newLeadInput: $newLeadInput, api_called_by: $api_called_by,user_id: $user_id,role_id:$role_id, partner_id:$partner_id, is_demo:$is_demo) {
        id
        message
        is_duplicate
    }
}
`;

export const LEAD_CREATION_CSV = gql`
    mutation create_leads_csv($newLeadsJsonArray: CreateCsvuploadInput!) {
        create_leads_csv(newLeadsJsonArray: $newLeadsJsonArray)
    }
`;

export const ASSIGN_LEAD_CSV = gql`
    mutation update_assign_lead($BulkAssignLeadInput: BulkAssignLeadInput!){
        update_assign_lead(bulkAssignLeadInput: $BulkAssignLeadInput) 
    }
`;
export const PARTNER_DROPDOWN_LIST = gql`
    query{
        partner_list_dropdown
    }
`;


export const CHAT_SAVE = gql`
mutation chat_save($chatInput: SaveChatInput!, $api_called_by: API_CALL_BY!, $call_by: String! ,   $tempelate_name: String! ) {
    chat_save(chatInput: $chatInput, api_called_by: $api_called_by, call_by: $call_by , tempelate_name : $tempelate_name)  {
        message
    }
}
`;
