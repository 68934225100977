import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import FormFields from '../insurance/formFields/insuranceFormFields.json';
import { SAVE_INSURANCE_LEAD } from "../../services/insurance.gql";
import { getLoanDetail } from './../../store/action/allAction';
import FormsyDatePicker from "../elements/FormsyDatePicker";
import React, { useEffect, useMemo, useState } from "react";
import FormsyInputField from "../elements/FormsyInputField";
import GeneralService from "../../services/generalService";
import { setCityList } from "../../store/action/allAction";
import { useDispatch, useSelector } from "react-redux";
import FormsySelect from "../elements/FormsySelect";
import { SALUTATION } from "../../config/constants";
import { useApolloClient } from "@apollo/client";
import CoApplicantDetails from "./CA_Insurance";
import { Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import Formsy from "formsy-react";

const INSURANCE_FIELDS = FormFields["INSURANCE_FORM"];

const InsuranceForm = (props) => {
  const [additionalCaSalutationCount, setAdditionalCaSalutationCount] = useState(0);
  const [defaultActiveTab, setDefaultActiveTab] = useState("insuranse_profile");
  const [addCoApplicant, setAddCoApplicant] = useState([]);
  const [insuranceData, setInsuranceData] = useState({});
  const [incomeDetails, setIncomeDetails] = useState({});
  const [showDetails, setShowDetails] = useState(true);
  const [showIframe, setShowIframe] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);
  const [isFormTouch, setTouch] = useState({});
  const client = useApolloClient();
  const dispatch = useDispatch();
    
  const { masterdata, lead } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead
    })
  );

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setInsuranceData((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  useEffect(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.city && options?.city?.length) {
      let record = options.city
        .filter((ob) => ob.state_id == insuranceData.property_state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });
      dispatch(setCityList(record));
    }
  }, [masterdata?.data?.state?.length, insuranceData?.property_city]);

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let record = lead.leadDetail.co_applicant;
      const newCaDetails = record?.map(applicant => ({
        ca_salutation: applicant.ca_salutation,
        ca_first_name: applicant.ca_first_name,
        ca_last_name: applicant.ca_last_name,
        co_app_id: applicant.id,
      }));

      let insuranLeadData = {
        salutation: lead.leadDetail.customer.salutation,
        first_name: lead.leadDetail.customer.first_name,
        last_name: lead.leadDetail.customer.last_name,
        phone_number: lead.leadDetail.customer.mobile,
        dob: lead?.leadDetail?.customer?.dob ? dateFormat(lead?.leadDetail?.customer?.dob, "dd mmm, yyyy") : null,
        email: lead.leadDetail.customer.email,
        property_pincode: lead.leadDetail.lead_details?.property_pincode,
        property_city: lead.leadDetail.lead_details?.property_city,
        property_state:lead.leadDetail.lead_details?.property_state,
        property_address1: lead.leadDetail.lead_details?.property_address1,
        pancard_no:lead.leadDetail.customer.pancard_no,
        property_value:lead.leadDetail.lead_details?.property_value,
        house_item_value:lead.leadDetail.lead_details?.house_item_value
      };
      setIncomeDetails((current) => ({
        ...current,
        additional_income: newCaDetails
      }));
      setInsuranceData(insuranLeadData);

      for (let i = 0; i < record.length; i++) {
        handleAddAdditionalCoApplicant(i);    
      }
    }
  }, [lead]);

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    options["company_type"] = options["company_type"]?.map(obj => ({ ...obj, value: obj.id}));
    return options;
  }, [masterdata]);

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name.includes("state")) {
        let options = masterdata ? { ...masterdata.data } : {};
        if (options?.city && options?.city?.length) {
          let record = options.city
            .filter((ob) => ob.state_id == data.value)
            .map((data) => {
              data.value = data.id;
              data.label = data.label;
              return data;
            });
          dispatch(setCityList(record));
        }
      }
      setInsuranceData((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (id == "property_pincode" && value.length == 6) {
      setCityStateByPin(value);
    }
    setInsuranceData((currentValue) => ({
      ...currentValue,
      [id]: value,
    }));
  };

  const setCityStateByPin = async (pincode) => {
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0];
        setInsuranceData({
          ...insuranceData,
          property_pincode: pincode,
          property_state: item?.state_id,
          property_city: item?.city_id,
        });
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const saveHandleSubmit = () => {
    // Validate if all required fields are filled
    const validateFields = () => {
      if (!insuranceData.salutation) return "Salutation is required.";
      if (!insuranceData.first_name) return "First name is required.";
      if (!insuranceData.last_name) return "Last name is required.";
      if (!insuranceData.phone_number) return "Phone number is required.";
      if (!insuranceData.dob) return "Date of birth is required.";
      if (!insuranceData.email) return "Email is required.";
      if (!insuranceData.property_pincode) return "Property pincode is required.";
      if (!insuranceData.property_state) return "Property state is required.";
      if (!insuranceData.property_city) return "Property city is required.";
      if (!insuranceData.property_address1) return "Property address is required.";
      if (!insuranceData.property_value) return "Property value is required.";
      if (!insuranceData.house_item_value) return "House item value is required.";
      return null; // No validation errors
    };
  
    const validationError = validateFields();
    
    // If validation fails, show an error message and prevent API call
    if (validationError) {
      toast.error(validationError);
      return;
    }
  
    // Confirm dialog before proceeding
    let confirm = window.confirm(
      "Are you sure you want to initiate the home insurance sale?"
    );
    
    if (confirm) {
      const lead_id = lead?.leadDetail?.id || "";
      const customer = {
        salutation: insuranceData.salutation || "",
        first_name: insuranceData.first_name || "",
        last_name: insuranceData.last_name || "",
        mobile: insuranceData.phone_number || "",
        // dob: insuranceData.dob || "",
        dob: dateFormat(insuranceData?.dob, "dd mmm, yyyy"),
        email: insuranceData.email || "",
        pancard_no: insuranceData.pancard_no || ""
      };
      const lead_details = {
        property_address1: insuranceData.property_address1 || "",
        property_pincode: insuranceData.property_pincode || "",
        property_state: insuranceData.property_state || 0,
        property_city: insuranceData.property_city || 0,
        property_value: insuranceData.property_value || "",
        house_item_value: insuranceData.house_item_value || ""
      };
      
      const modifyCoApplicant = incomeDetails["additional_income"].map(obj => {
        let newObj = { ...obj, id: obj.co_app_id };
        delete newObj.co_app_id; 
        return newObj;
      });
  
      const co_applicant = modifyCoApplicant;
  
      let mutation = SAVE_INSURANCE_LEAD,
      variables = {
        api_called_by: "web",
        SaveInsuranceLeadInput: {
          lead_id,
          customer,
          lead_details,
          co_applicant,
        },
      };
  
      // Call the GraphQL mutation
      executeGraphQLMutation(mutation, variables, client)
        .then((resp) => {
          let response = resp?.data?.save_insurance_lead || null;
          if (response && !response.insurance_url) {
            throw new Error(response.error || "Something went wrong.");
          }
          toast.success("Success");
          dispatch(getLoanDetail(lead_id, client));
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };
  
  
  const handleCoApplicantType = (data, index, field_name) => {
    const exAdd = incomeDetails["additional_income"];
    if(field_name === "ca_salutation") {
      if (exAdd[index]) {
        exAdd[index][field_name] = data?.value;
      } else {
        exAdd.push({
          [field_name]: data?.value
        });
      }
    } else {
      if (exAdd[index]) {
        exAdd[index][field_name] = data?.target.value;
      } else {
        exAdd.push({
          [field_name]: data?.target.value,
        });
      }
    }
    setIncomeDetails((current) => ({
      ...current,
      additional_income: exAdd
    }));
  };

  const handleRemoveCoApplicant = (index) => {
    const existingCoApplicantField = [...addCoApplicant];
    existingCoApplicantField.splice(index, 1);
    if (existingCoApplicantField.length > 0) {
      existingCoApplicantField[
        existingCoApplicantField.length - 1
      ].canAddMore = true;
    }
    setAdditionalCaSalutationCount(additionalCaSalutationCount - 1);
    setAddCoApplicant(existingCoApplicantField);
  };

  const handleAddAdditionalCoApplicant = (index) => {
    if (index < 5) {
      const isExisting = addCoApplicant.filter(
        (obj) => obj.id === `co_applicant_${index}`
      );
      if (
        isExisting.length === 0 ||
        addCoApplicant[addCoApplicant.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `co_applicant_${index}` };
        setAdditionalCaSalutationCount(index + 1);
        setAddCoApplicant((current) => [...current, dropDownObj]);
      }
    }
  };

  const handleSetAdditionalIncomeData = (data) => {
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: data,
    }));
  };

  const link = `${lead?.leadDetail?.insurance_lead_details?.insurance_url}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        setLinkCopy(true);
        setTimeout(() => setLinkCopy(false), 2000);
      },
      () => {
        console.error('Failed to copy the link');
      }
    );
  };

  const openIframe = (e) => {
    e.preventDefault();
    setShowIframe(true);
    setShowDetails(false)
  };
  
  return (
    <div
      className="document-upload-tabs"
      key={lead.leadDetail && lead.leadDetail?.status_id}
    >
     
      {/* ---- LEFT PANEL ---- */}
      <div className="doument-upload-left-panel">
        { link=="undefined" || link==""? (
          <div className="breoffercard">
            <div className="breofferheading">
              <span>Fill up the details to unlock home insurance opportunities</span>
            </div>
          </div>
        ) : (
          <div>
            {showDetails && (
              <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3 style={{ marginBottom: 50, fontSize: 22, fontWeight: 700 }}>Insurance Lead Details</h3>
                  <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "left", background: "#F6EAB6",fontSize:"16px",fontWeight:"500",padding:"0 8px",borderRadius:"5px", textAlign: "end" }}>mis Status: <span>{lead?.leadDetail?.insurance_lead_details?.insurance_lead_status?.label}</span></div>
                    <div style={{padding:"0 8px"}}>Last Uploaded On: <span>{lead?.leadDetail?.insurance_lead_details?.last_updated_date ? dateFormat(lead?.leadDetail?.insurance_lead_details?.last_updated_date, 'd mmm,yyyy hh:MM') : "NA"}</span></div>
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: 50 }}>
                  <div style={{ width: "40%" }}>Insurance Lead ID: <span>{lead?.leadDetail?.insurance_lead_details?.id || "NA"}</span></div>
                  <div style={{ width: "40%" }}>Enquiry ID: <span>{lead?.leadDetail?.insurance_lead_details?.ref_id || "NA"}</span></div>
                </div>
                <div style={{ display: "flex", marginBottom: 50 }}>
                  <div style={{ width: "40%" }}>Coverage Amount: <span>{lead?.leadDetail?.insurance_lead_details?.content_sum_insured || "NA"}</span></div>
                  <div style={{ width: "40%" }}>Premium Amount: <span>{lead?.leadDetail?.insurance_lead_details?.selected_premium || "NA"}</span></div>
                </div>
                <div style={{ display: "flex", marginBottom: 50 }}>
                  <div style={{ width: "40%" }}>Insurance Type: <span>{lead?.leadDetail?.insurance_lead_details?.insurance_type || "NA"}</span></div>
                  <div style={{ width: "40%" }}>Initiated On: <span>{dateFormat(lead?.leadDetail?.insurance_lead_details?.created_date, 'd mmm,yyyy hh:MM') || "NA"}</span></div>
                </div>
              </div>
            )}

            <div>
              {showDetails && (
                <div> 
                  <a style={{ color: "black" }} href={link} onClick={openIframe}>{link}</a>
                  <span
                    style={{ marginLeft: 10, color: "#6147FF", cursor: 'pointer' }}
                    onClick={copyToClipboard}
                  >
                    <i className="ic-content_copy">{linkCopy ? "Copied" : "Copy"}</i>
                  </span>
                </div>
              )}

              {showIframe && (
                <div style={{ marginTop: 20 }}>
                  <iframe src={link} width="100%" height="600px"/>
                </div>
              )}
            </div>
          </div>
        )}

      </div>
     
      {/* RIGHT PANEL */}
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
            onSelect={(tab) => setDefaultActiveTab(tab)}
            >
            <Tab.Content>
              <Tab.Pane key="insuranse_profile" eventKey="insuranse_profile">
                <Formsy
                  className="lead-form-filed"
                  autoComplete="off"
                  onValid={() => null}
                  onInvalid={() => null}
                >
                  <div className="row">
                    {INSURANCE_FIELDS.map((field, index) =>
                      ["text", "pattern-format", "number"].includes(
                        field.type
                      ) ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "form-filed col-md-12"
                              : "form-filed col-md-6"
                          }
                          key={index}
                          >
                          <FormsyInputField
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            value={(insuranceData && insuranceData[field.name]) || ""}
                            placeholder=" "
                            maxLength={field.maxLength}
                            label={field.label}
                            format={field.ApplyNumberformat ? field.format : ""}
                            validations={
                              isFormTouch[field.name] ? field.validations : null
                            }
                            validationError={
                              insuranceData[field.name] ||
                              insuranceData[field.name] == ""
                                ? isFormTouch[field.name]
                                  ? field.validationError
                                  : ""
                                : ""
                            }
                            readOnly={field.readOnly}
                            required={field.required}
                            onChange={handleInputChange}
                          />
                        </fieldset>
                      ) : field.type === "dropdown" ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "single-select col-md-12"
                              : "single-select col-md-6"
                          }
                          key={index}
                          >
                          {
                          field.name=='salutation'?
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: SALUTATION,
                              options: SALUTATION,
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value:
                                SALUTATION &&
                                SALUTATION.filter(
                                  ({ value }) => value == insuranceData[field.name]
                                ),
                              isDisabled: field.readOnly,
                            }}
                            required={field.required}
                            value={insuranceData && insuranceData[field.name]}
                            onChange={(data) =>
                              handleSelectChange(data, field.name)
                            }
                          />: <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options: [field.optionsKey],
                            options:
                              ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value:
                              ALL_OPTIONS &&
                              ALL_OPTIONS[field.optionsKey] &&
                              ALL_OPTIONS[field.optionsKey].filter(
                                ({ value }) => value == insuranceData[field.name]
                              ),
                            isDisabled: field.readOnly,
                          }}
                          required={field.required}
                          value={insuranceData && insuranceData[field.name]}
                          onChange={(data) =>
                            handleSelectChange(data, field.name)
                          }
                        />
                          }
                        </fieldset>
                      ) : field.type === "date-picker" ? (
                        <fieldset className="form-filed col-md-6 " key={index}>
                          <FormsyDatePicker
                            name="dob"
                            selectedDate={
                              insuranceData &&
                              insuranceData[field.name] &&
                              new Date(insuranceData[field.name])
                            }
                            placeholder={field.label}
                            maxDate={field.maxDate}
                            minDate={field.minDate}
                            value={insuranceData && insuranceData[field.name]}
                            onDateSelect={(date) =>
                              handleDateChange(date, field.name)
                            }
                            dateFormat="dd MMM, yyyy"
                            validations={field.validations}
                            validationError={
                              insuranceData[field.name] ? field.validationError : ""
                            }
                            required={field.required}
                            hasError={
                              insuranceData.hasOwnProperty(field.name) &&
                              !insuranceData[field.name]
                                ? true
                                : false
                            }
                          />
                        </fieldset>
                      ) : null
                    )}
                  </div>

                  <div>

                    { (
                      <>
                        <h2 className="additionalincome">Co-Applicant Details</h2>
                        {addCoApplicant.map((data, index) => (
                          <>
                            <div className="additionalincome" key={index}>
                              Co-Applicant - {index + 1}
                            </div>
                            <CoApplicantDetails
                              index={index}
                              ALL_OPTIONS={ALL_OPTIONS}
                              setCASalutation={handleCoApplicantType}
                              removeCoApplicant={handleRemoveCoApplicant}
                              setCoApplicantData={handleSetAdditionalIncomeData}
                              additionalCaSalutationData={incomeDetails["additional_income"]}
                              key={index}
                            />
                          </>
                        ))}

                        {additionalCaSalutationCount < 5 && (
                          <div
                            className="additionalincome"
                            onClick={() =>
                              handleAddAdditionalCoApplicant(additionalCaSalutationCount)
                            }
                            >
                            <span>+</span>
                            <div>Add Co-Applicant</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="btn-save-remarks">
                    <button onClick={saveHandleSubmit} type="submit" className="btn-primary">Save</button>
                  </div>
                </Formsy>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};
export default InsuranceForm;