import { useApolloClient } from '@apollo/client';
import dateFormat from "dateformat";
import Q from 'q';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { WS_URL } from "../../config/constants";
import GeneralService from '../../services/generalService';
import { CHAT_SAVE } from "../../services/leadCreate.gql";
import { CHAT_LIST, CHAT_TEMPLATE_LIST, UPDATE_CHAT_READ_STATUS } from '../../services/leads.gql';
import docImage from "../../webroot/images/saveicon.svg";
import sendicon from "../../webroot/images/sendicon.png";
import Modal from "../elements/Modal";
import TemplateList from './TemplateList';

const WhatsAppTemplate = () => {
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); // Reference for chat container
  const [showtemplate, setShowTemplate] = useState(false);
  const [initiate, setInitiate] = useState(false);

  let refresh = 0
  const [inputField, setField] = useState({ chat_type: 'TEXT', mode_type: 1, message: '', files: '' });
  const [list, setList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const client = useApolloClient();

  const intervalRef = useRef(null);

  const { leadDetail, userInfo } = useSelector(({ lead, user }) => {
    return {
      leadDetail: lead.leadDetail,
      userInfo: (user.user_information) ? JSON.parse(user.user_information) : {},
    };
  });

  useEffect(() => {
    getTemplateList(leadDetail?.id, userInfo?.name, "");
    getMessageList(leadDetail?.customer?.mobile);
  }, [leadDetail?.id]);

  useEffect(() => {
    scrollToBottom();
  }, [chatList]);

  const getTemplateList = (lead_id, username, tempelate_type) => {
    executeGraphQLQuery(CHAT_TEMPLATE_LIST(lead_id, username, tempelate_type), client).then((result) => {
      setList(result?.data?.chat_template_list);
    }).catch((error) => { });
  };

  const getMessageList = (mobile) => {
    executeGraphQLQuery(CHAT_LIST(mobile, leadDetail?.id, userInfo?.id, 'Sangam'), client).then((result) => {
      let replyResult = result?.data?.chat_list.filter(ob => ob.mode_type == 2);
      if (!replyResult.length) {
        setInitiate(true)
      } else {
        setInitiate(false)
      }
      let curCount = result?.data?.chat_list?.length;
      if (!(refresh == curCount)) {
        setTimeout(() => scrollToBottom(), 200)
      }
      refresh = curCount;
      setChatList(result?.data?.chat_list);
    }).catch((error) => { });
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setField({
      ...inputField,
      message: value
    });
  };

  const sendMessage = (reqParam = null, initiate = false) => {
    if (inputField.message.trim() !== '' || reqParam || initiate) {
      let chatField = {
        ...inputField,
        lead_id: leadDetail?.id,
        user_id: userInfo?.id,
        customer_id: leadDetail?.customer?.customer_id,
        mobile: leadDetail?.customer?.mobile
      };
      if (initiate) {
        chatField.initiate = 1;
      }
      if (reqParam && reqParam?.chat_type) {
        chatField = { ...chatField, ...reqParam };
      }
      let variables = {
        api_called_by: "partner",
        chatInput: chatField,
        call_by: "whatspp_page",
        tempelate_name: 'sangam'
      };
      let mutation = CHAT_SAVE;
      executeGraphQLMutation(mutation, variables, client)
        .then(result => {
          getMessageList(leadDetail?.customer?.mobile);
        })
        .catch((error) => { });
      setField({
        ...inputField,
        message: ''
      });
    }
  };

  const showTemplate = () => {
    setShowTemplate(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideTemplate = () => {
    setShowTemplate(false);
    document.body.classList.remove("overflow-hidden");
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase();
  };

  const handleChangeImage = (event) => {
    let files = Array.from(event.target.files), awsRes = [], leadDocArr = [];
    if (files && files.length) {
      var formData = new FormData();
      formData.append("dealer_id", leadDetail.id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      awsRes.push(GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }));
      Q.allSettled(awsRes).then((uploadOutput) => {
        uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === 'fulfilled') {
            let docPath = (response?.data?.data?.length) ? response?.data?.data?.[0]?.file_url : '';
            let chat_type = 'DOCUMENT'
            if (docPath.endsWith('.jpg') || docPath.endsWith('.jpeg') || docPath.endsWith('.png') || docPath.endsWith('.gif')) {
              chat_type = 'IMAGE'
            }
            let params = {
              files: docPath,
              chat_type: chat_type
            };
            sendMessage(params);
          }
        });
      });
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // WebSocket Code Initialize
  useEffect(() => {
    if (leadDetail?.id) {
      const identifierType = "activeMessage";
      const connectionId = Math.floor(Date.now() / 1000);
      const socketData = {
        transports: ['websocket'],
        query: { type: identifierType, identifier: leadDetail?.id, connectionId },
        reconnectionAttempts: 10,
        reconnectionDelay: 60000,
        randomizationFactor:5
      }
      const newSocket = io(`${WS_URL}/socket`, socketData);

      newSocket.on(`${identifierType}-${leadDetail?.id}_${connectionId}`, (msg) => {
        if (msg) {
          const msgFrmt = {
            created_date: msg.message.created_date,
            message: msg.message.message,
            chat_type: msg.message.chat_type,
            files: msg?.message?.files,
            mode_type: msg.message.mode_type
          }
          setChatList((prevMessages) => [...prevMessages, msgFrmt]);
          scrollToBottom();
          setInitiate(false);
          updateReadStatus(msg.identifier.msgId, msg.identifier.userId);
        }
      });

      return () => {
        newSocket.disconnect();
      };
    }
  }, [leadDetail?.id]);
  // WebSocket Code Ends

  const updateReadStatus = (msgId, userId) => {
    executeGraphQLMutation(UPDATE_CHAT_READ_STATUS, { chatId: msgId, userId }, client)
      // .then(result => {
      //   console.log("\n\n updateReadStatus >> executeGraphQLQuery => result -> ", result)
      // })
      .catch((error) => {
        console.error("\n\n updateReadStatus >> executeGraphQLQuery => error -> ", error)
      });
  }

  return (
    <>
      <div className="whatsappheader">
        <div className="whatsappdetails">
          <div className="whatsappdetails">
            <div className="whatsappname">{capitalizeFirstLetter(leadDetail?.customer?.first_name)}</div>
            <div>
              <div className="detailsname">{leadDetail?.customer?.first_name + ' ' + leadDetail?.customer?.last_name}</div>
              <div>
                Chat ID: <span>{leadDetail?.customer?.customer_id}</span>
              </div>
            </div>
          </div>
          <button className="templatesbtn" onClick={showTemplate}>Templates</button>
        </div>
      </div>
      <div ref={chatContainerRef} className="chat-container" style={{ maxHeight: '95%', overflowY: 'scroll' }}> {/* Ensure the container is scrollable */}
        <div className="chatsdate">
          {chatList && chatList.length ? dateFormat(chatList[0].created_date, "dd mmm yyyy") : null}
        </div>
        {chatList && chatList.length ? chatList.map((item, key) => {
          let messageElement = <div>{item.message}</div>;
          if (item.chat_type == 'IMAGE') {
            messageElement = <div>
              {item.files && (
                <>
                  <a href={item.files} target="_blank" rel="noopener noreferrer">
                    <img src={item.files} width="100px" height="100px" alt="chat-img" />
                  </a>
                  <br />
                </>
              )}
              <span>{item.message}</span>
            </div>;
          } else if (item.chat_type == 'DOCUMENT' && item.files) {
            messageElement = <div>
              {item.files && (
                <>
                  <a href={item.files} target="_blank" rel="noopener noreferrer">
                    <img src={docImage} width="100px" height="50px" alt="chat-doc" />
                  </a>
                </>
              )}
            </div>;
          }
          return (
            <div className={`${item.mode_type == '2' ? "leftchats" : "rightchats"}`} key={key}>
              <div className="chatmsgdetails">
                {item.mode_type == '2' ? <div className="otherchat">{capitalizeFirstLetter(leadDetail?.customer?.first_name)}</div> : null}
                <div className={`${item.mode_type == '2' ? "chatsviewleft" : "chatsviewright"}`}>
                  {messageElement}
                  <div className="chattime">{dateFormat(item.created_date, "dd mmm yyyy, h:MM TT")}</div>
                </div>
                {item.mode_type == '1' ? <div className="mychat">{capitalizeFirstLetter(userInfo?.user_data?.name)}</div> : null}
              </div>
            </div>
          );
        }) : null}
        {
          initiate ? <div className="btn-save-remarks">
            <center>
              <button className="btn-primary" type="button" onClick={() => sendMessage(null, 1)}>Initiate Chat</button>
            </center>
          </div>
            :
            <div className="sendchatsdetails">
              <div className="inputbox">
                <div className="sendinput">
                  <textarea
                    type="text"
                    placeholder="Type Something..."
                    value={inputField.message}
                    onChange={handleChange}
                    rows={3}
                  />
                  {inputField.message && (
                    <span onClick={() => sendMessage()}>
                      <img src={sendicon} alt="icon" width={16} height={16} />
                    </span>
                  )}
                </div>
                <div className="attachiconwidth">
                  <i
                    className="ic-attach_file"
                    alt="icon"
                    onClick={handleIconClick}
                  ></i>
                  <input
                    type="file"
                    placeholder="Type Something..."
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleChangeImage}
                  />
                </div>
              </div>
            </div>
        }

      </div>
      <div className="view-timeline-popup">
        <Modal show={showtemplate} handleClose={hideTemplate}>
          <TemplateList list={list} setInputValue={(message) => setField({ ...inputField, message })} hideTemplate={hideTemplate} />
        </Modal>
      </div>
    </>
  );
};

export default WhatsAppTemplate;
