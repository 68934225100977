import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from "react";

import RadioBox from '../elements/Radiobox'
import InputField from "../elements/InputField";
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from "../../services/MasterService";
import { Navigate, NavLink, BrowserRouter as Router } from 'react-router-dom';
import { Add_SCHEME, Add_Scheme_Range, GET_SCHEMA_LIST_BYID, Update_SCHEME, GET_SCHEMA_LIST_BY_CODE, Update_SCHEME_CITIES, SCHEME_CITIES_VARIABLE, FINEX_MASTER_LIST } from "../../services/scheme.gql";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import DateFormate from 'dateformat';
import { useParams } from "react-router-dom";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import {executeGraphQLQueryFinex} from "../../common/executeGraphQLQueryFinex.js";
import {    
    GET_USER_LIST_PAYOUT,    
  } from "../../services/payout.gql.js";

const Schemedetails = (props) => {
    const param = useParams()
    const { handleTabClick } = props;
    const [formData, setFormData] = useState({ "scheme_type": "standard", "is_all_cities": 1 });
    const [selectstatus, setselectstatus] = useState("");
    const [cityAllList, setcityAllList] = useState([]);
    const [errors, setErrors] = useState("");
    const [fulfillmenttypes, setFulfillmentTypes] = useState([])
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);
    const [user_list, setUserList] = useState([]);

    const [schemeCities, setSchemeCities] = useState({
        status: 1,
        cities: [],
        scheme_id: props.schemedetailsData && props.schemedetailsData ? props.schemedetailsData.id : 0,
        citiesids: []
    })

    let scheme_view =  IS_USER_ACCESS_PAGE("payout", "scheme_view")?.is_edit_access;
    const handleInput = (e, key = "") => {
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.value : e.value;
        setFormData({ ...formData, [name]: val })
        let newErrors = { ...errors };
        newErrors[name] = ('');
        setErrors(newErrors);

    }

    // const handleTabs = (tabId) => {
    //     console.log(props.handleTabClick)
    //     props.handleTabClick(tabId)
    // };

    useEffect(() => {
        getCityList()
        if (props.schemedetailsData && props.schemedetailsData.scheme_name != "") {
            setFormData(props.schemedetailsData)
            getSchemeCitities(props.schemedetailsData.id)
        }
        getFinexMasterData()
        getDealerList()
    }, [props.schemedetailsData]);

    const getCityList = () => {
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                if (response.data.status == 200) {
                    setcityAllList(response.data.data.city)
                }
            })
            .catch(function (response) {
            });
    }

    const AddSchemeSave = async (e) => {
        e.preventDefault();
        if (param.id) {
            updateScheme();
        } else {
            addScheme();
        }
    }

    const handelCheckbox = (value) => {
        let newFormData = { ...formData };
        newFormData['scheme_type'] = value;
        setFormData(newFormData);
    }

    const handleCheckboxall = (e, keyname) => {
        const formvalues = { ...formData }
        const checked = e.target.checked
        if (checked) {
            formvalues[keyname] = 1;
        } else {
            formvalues[keyname] = 0;
        }
        setFormData(formvalues)

    }

    const handleCityOptionChange = (name, selectedOptions) => {
        const schemeCitiesdata = { ...schemeCities }
        const cities = []
        const citiesids = []
        selectedOptions && selectedOptions.length > 0 && selectedOptions.map((selectedData) => {
            cities.push({ city_id: selectedData.id })
            citiesids.push(selectedData.id)
        })
        setSchemeCities({ ...schemeCitiesdata, cities: cities, citiesids: citiesids });
    };

    const addScheme = async () => {
        const formDataNew = { ...formData };
        formDataNew.status = selectstatus?.value;
        const data = {};
        const variables = {
            addSchemeArgs: {
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: formData.status,
                fulfillment_type: formData.fulfillment_type,
                is_all_cities: formData.is_all_cities ? formData.is_all_cities : 0,
                is_all_financers: formData.is_all_financers ? formData.is_all_financers : 0,
                is_all_builders: formData.is_all_builders ? formData.is_all_builders : 0,
                is_default: formData.is_default ? formData.is_default : '0'
            },
        };
        data.addSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Add_SCHEME, variables);
                if (result && result.data && result.data.addScheme && result.data.addScheme.code) {
                    if (result?.data?.addScheme?.error) {
                        toast.error(result?.data?.addScheme?.msg || "Success");
                    } else {
                        toast.success(result?.data?.addScheme?.msg || "Success");
                        setTimeout(redirectpage("/scheme/" + result?.data?.addScheme?.code + "/details?" + "type="+ result.data.addScheme.id + "&default="+ variables.addSchemeArgs.is_default ), 5000);
                    }

                    setFormData({ ...formData });
                }
            } catch (error) {
                toast.error("Failed");
            }
        }
    }

    const updateScheme = async () => {
        const data = {}
        if (schemeCities.cities.length > 0) {
            updateSchemeCities()
        }
        const variables = {
            updateSchemeArgs: {
                id: formData.id,
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: formData.status,
                fulfillment_type: formData.fulfillment_type,
                is_all_cities: formData.is_all_cities,
                is_all_financers: formData.is_all_financers,
                is_all_builders: formData.is_all_builders,
                is_default: formData.is_default ? formData.is_default : '0',
                approved_status: formData.approval_status ? formData.approval_status.toString() : '0'
            },
        };
        data.updateSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Update_SCHEME, variables);


                if (result) {
                    if (result?.data?.updateScheme?.error) {
                        toast.error(result?.data?.updateScheme?.msg);
                    } else {
                        toast.success(result?.data?.updateScheme?.msg || "Success");
                    }

                    handleTabClick("details")
                }
            } catch (error) {
                toast.error("Failed");
            }
        }
    }

    const updateSchemeCities = async () => {
        const data = {}
        const selectedcitiesdata = { ...schemeCities, scheme_id: formData.id }
        delete selectedcitiesdata.citiesids;
        const variables = {
            addSchemeCityArgs: selectedcitiesdata,
        };
        data.updateSchemeArgs = variables;
        if (validateForm()) {
            try {
                await executeGraphQLMutation(Update_SCHEME_CITIES, variables);
            } catch (error) {
                //  toast.error("Failed");
                //  console.error(error);
            }
        }
    }

    const getSchemeCitities = async (scheme_id) => {
        const schemeDataList = await executeGraphQLQuery(SCHEME_CITIES_VARIABLE(scheme_id));
        const schemeCitiesNew = { ...schemeCities }
        const citiesids = []
        const citiesData = []
        const schemadata = schemeDataList.data && schemeDataList.data.schemecitybyschemeid && schemeDataList.data.schemecitybyschemeid.length > 0 ? schemeDataList.data.schemecitybyschemeid : schemeCitiesNew;
        schemadata && schemadata.length > 0 && schemadata.map(data => {
            citiesids.push(data.city_id)
            citiesData.push({ city_id: data.city_id });
        })
        setSchemeCities({ scheme_id: scheme_id, cities: citiesData, status: 1, citiesids: citiesids })
    }




    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };

        if (!newFormData['scheme_name'] || newFormData['scheme_name'] === '') {
            newErrors['scheme_name'] = ('Please enter scheme name');
            validForm = false;
        }
        if (!newFormData['start_date'] || newFormData['start_date'] === '') {
            newErrors['start_date'] = ('Please enter start date');
            validForm = false;
        }
        if (!newFormData['end_date'] || newFormData['end_date'] === '') {
            newErrors['end_date'] = ('Please enter end date');
            validForm = false;
        }
        setErrors(newErrors);
        return validForm;
    };

    const status = [
        { value: 1, label: "Active" },
        { value: 2, label: "Inactive" }
    ]

    const calculation = [
        { value: "count", label: "Count" },
        { value: "amount", label: "Amount" }
    ]

    const builders = [
        { value: '1', label: 'M3M' },
        { value: '2', label: 'DLF' },
        { value: '3', label: 'Signature Gloabal' },
        { value: '4', label: 'Pyramid' },
        { value: '5', label: 'Tulip' },
    ];

    const handleStatusSelect = (selectedOptions) => {
        setselectstatus(selectedOptions);
    };

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const fulfillmenttypeData = []
            const data = masterData.data.masterdata;
            const fulfillmenttype = data.fulfillment_type && data.fulfillment_type.length > 0 ? data.fulfillment_type : []
            if (fulfillmenttype && fulfillmenttype.length > 0) {
                fulfillmenttype.map(ptype => {
                    fulfillmenttypeData.push({ id: ptype.id, name: ptype.label })
                })
                setFulfillmentTypes(fulfillmenttypeData)
            }
        }
    }

    const handleFullfilmentType = (name, selectedOptions) => {
        const formDataNew = { ...formData }
        const fulfillment_type_Array = []
        fulfillment_type_Array.push({ id: selectedOptions.id })
        formDataNew['fulfillment_type'] = selectedOptions.id
        formDataNew['fulfillment_type_Array'] = fulfillment_type_Array
        setFormData(formDataNew);
    };

    const redirectpage = (url) => {
        window.location.href = url
    }

    const handleCheckBoxDefault = (n, e) => {
        const checked = e.target.checked;
        switch (n) {
            case 'is_default':
                setFormData({ ...formData, [n]: checked ? '1' : '0' });
                break;
            case 'approved_status':
                setFormData({ ...formData, [n]: checked ? 0 : 2 });
                break;
        }
    };

    const getDealerList = async () => {
        let user_list = [], userlist = [];
        setUserList(userlist)
        const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
          (err) => console.log(err)
        );
            if (response && response.data && response.data.get_user_list) {
                user_list = response.data.get_user_list;
                user_list && user_list.map((v) => {
                    userlist.push({ value: v.user_id, label: v.name })
                    return v;
                })
                console.log("userlist",userlist)
                setUserList(userlist);
            }
      
    };


    console.log("userlist------------------",user_list?.length)

  let ttt =   formData.created_by &&
                                                                    user_list?.length >
                                                                      0
                                                                      ? user_list?.filter(
                                                                          (
                                                                            user
                                                                          ) =>
                                                                            user.value ==
                                                                            formData.created_by
                                                                        )[0]
                                                                          ?.label
                                                                      : "system";



                                                                      console.log("userlist------------------",ttt)
    return (
        <>
            <div className="card">

                <div className="card-body">
                    <div className="panlinkedflex">
                        <div style={{ marginRight: 100, marginTop: 10, marginBottom: 10 }}>
                            <RadioBox
                                type="radio"
                                name="scheme_type"
                                id="scheme_type"
                                value="standard"
                                label={('Standard')}
                                checked={(formData.scheme_type === "standard" || (!formData.scheme_type)) ? true : false}
                                onChange={() => handelCheckbox("standard")}

                            />
                        </div>

                        <div style={{ marginRight: 20, marginTop: 10, marginBottom: 10 }}>
                            <RadioBox
                                type="radio"
                                name="promotional"
                                id="promotional"
                                value="promotional"
                                label={('Promotional')}
                                checked={(formData.scheme_type === "promotional" || (!formData.scheme_type)) ? true : false}
                                onChange={() => handelCheckbox("promotional")}
                            />
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-sm-3">

                            <label>Fulfillment Type</label>
                            <Select
                                id="city_id"
                                onChange={(e) => handleFullfilmentType('fulfillment_type', e)}
                                options={fulfillmenttypes}
                                name="fulfillment_type"
                                value={fulfillmenttypes && fulfillmenttypes.length > 0 && formData.fulfillment_type && formData.fulfillment_type != "" && fulfillmenttypes.filter((items) => items.id == formData.fulfillment_type)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}

                            />
                        </div>
                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "scheme_name",
                                    type: "text",
                                    name: "scheme_name",
                                    label: ('Scheme Name'),
                                    value: formData.scheme_name || '',
                                    placeholder: "Please Enter Scheme Name",
                                    dataerror: errors.scheme_name || '',
                                    validationreq: "true",
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>

                        <div className="col-sm-3">

                            <InputField
                                inputProps={{
                                    id: "start_date",
                                    type: "date",
                                    name: "start_date",
                                    label: ('Start Date'),
                                    value: formData.start_date ? DateFormate(new Date(formData.start_date), 'yyyy-mm-dd') : '',
                                    dataerror: errors.start_date || '',
                                    validationreq: "true",
                                    label_before_input: "true"
                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "end_date",
                                    type: "date",
                                    name: "end_date",
                                    label: ('End Date'),
                                    value: formData.end_date ? DateFormate(new Date(formData.end_date), 'yyyy-mm-dd') : '',
                                    dataerror: errors.end_date || '',
                                    validationreq: "true",
                                    label_before_input: "true"
                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>



                        <div className="col-sm-3">

                            <label>Scheme For</label>
                            <Select
                                id="scheme_for"
                                name="scheme_for"
                                options={calculation}
                                value={calculation.filter(({ value }) => value === formData.scheme_for)}
                                onChange={(e) => handleInput(e, 'scheme_for')}
                                dataerror={errors.scheme_for}
                            >
                            </Select>
                        </div>

                        <div className="col-sm-3">
                            <label>Status</label>
                            <Select
                                id="status"
                                name="status"
                                options={status}
                                value={status.filter(({ value }) => value === formData.status)}
                                onChange={(e) => handleInput(e, 'status')}
                                dataerror={errors.status}
                            >
                            </Select>
                        </div>
                        {/* <div className="col-sm-3">
                          <label>Select For City</label>
                                <Select
                                                    id="city_id"
                                                    onChange={(e) => handleCityOptionChange('city_id', e)}
                                                    options={cityAllList}
                                                    isMulti
                                                    name="city_id"
                                                    value={cityAllList && cityAllList.length > 0 && schemeCities.citiesids.length>0 && cityAllList.filter(({ id }) => schemeCities.citiesids.includes(id))}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                />
                         </div> */}
                        <div className="col-sm-3">
                            <label>Is Default</label><br />
                            <input
                                type="checkbox"
                                name="is_default"
                                label="Is Default Scheme"
                                id={'is_default'}
                                key={'is_default'}
                                value={formData.is_default}
                                checked={formData.is_default == '1' ? true : false}
                                onClick={(e) => handleCheckBoxDefault('is_default', e)}
                            />
                        </div>
                        {formData.id && formData.id > 0 ?
                        <div className="col-sm-3">
                            
                            <p>Created By:  
                            {formData.created_by && user_list?.length > 0 ? user_list?.filter(
                                                                          (
                                                                            user
                                                                          ) =>
                                                                            user.value ==
                                                                            formData.created_by
                                                                        )[0]
                                                                          ?.label
                                                                      : "system"} </p>
                            <p>Created On: {formData.created_date ? DateFormate(new Date(formData.created_date), 'yyyy-mm-dd hh:mm:ss') : ''}</p>
                            <p>Approved By:  
                            {formData.approved_by && user_list?.length > 0 ? user_list?.filter(
                                                                          (
                                                                            user
                                                                          ) =>
                                                                            user.value ==
                                                                            formData.approved_by
                                                                        )[0]
                                                                          ?.label
                                                                      : "system"} </p>
                            <p>Approved On: {formData.approved_date ? DateFormate(new Date(formData.approved_date), 'yyyy-mm-dd hh:mm:ss') : ''}</p>
                        </div>
                         : null
                        }

                        {formData.approved_status == '2' ?
                            <div className="col-sm-3">
                                <label>Send For Approval</label><br />
                                <input
                                    type="checkbox"
                                    name="approved_status"
                                    label="Send For Approval"
                                    id={'approved_status'}
                                    key={'approved_status'}
                                    value={formData.approval_status}
                                    //  checked={formData.is_default==0?true:false}
                                    onClick={(e) => handleCheckBoxDefault('approved_status', e)}
                                />
                            </div> : null}
                    </div>

                    <div>
                        {formData.id && formData.id > 0 ?
                            <div className="text-center">
                                {scheme_view === 1 && (
                                    <button style={{ marginBottom: 10, marginTop: 20 }}
                                    onClick={(e) => updateScheme(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Update</b>}</button>
                                )}
                               
                            </div>
                            :
                            <div className="text-center">
                                <button style={{ marginBottom: 10, marginTop: 20 }}
                                    onClick={(e) => AddSchemeSave(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Create</b>}</button>
                            </div>
                        }

                    </div>


                </div>
            </div>
            {/* {formData.scheme_code != "" ?
            <div className="card">
              <div className="card-body">
                    <h5 className="">Scheme For</h5>
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckBox type="checkbox" name="scheme" id="scheme" value="scheme" label="All Cities" checked={formData.is_all_cities ? true : false} onChange={(e) => handleCheckboxall(e,"is_all_cities")} />
                        </div>
                        {!formData.is_all_cities ? 
                         <div className="col-sm-6">
                          
                                <Select
                                                    id="city_id"
                                                    onChange={(e) => handleCityOptionChange('city_id', e)}
                                                    options={cityAllList}
                                                    isMulti
                                                    name="city_id"
                                                    value={cityAllList && cityAllList.length > 0 && schemeCities.citiesids.length>0 && cityAllList.filter(({ id }) => schemeCities.citiesids.includes(id))}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                />
                         </div>
                        : ""}
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckBox type="checkbox" name="scheme" id="scheme" value="scheme" checked={formData.is_all_builders ? true : false} label="All Builders" onChange={(e) => handleCheckboxall(e,"is_all_builders")}  />
                        </div>
                        {!formData.is_all_builders ? 
                         <div className="col-sm-12">
                          
                                <Select
                                                    id="builder_id"
                                                    onChange={(e) => handleCityOptionChange('builder_id', e)}
                                                    options={builders}
                                                    isMulti
                                                    name="builder_id"
                                                    value={cityAllList && cityAllList.length > 0 && schemeCities.citiesids.length>0 && cityAllList.filter(({ id }) => schemeCities.citiesids.includes(id))}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                />
                         </div>
                        : ""}
                    </div>
                </div>

            </div> : "" } */}


        </>
    )
};


export default Schemedetails;

