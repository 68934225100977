import React, { useEffect, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { Update_SCHEME } from "../../services/scheme.gql";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { toast } from "react-toastify";

const TermsConditions = (props) => {
  const [editorContent, setEditorContent] = useState(props?.schemedetailsData?.scheme_tnc || "<p></p>");
  const [errors, setErrors] = useState("");
  const [formData, setFormData] = useState(props.schemedetailsData);

  const updateScheme = async () => {
    const data = {};
    const variables = {
      updateSchemeArgs: {
        id: formData.id,
        scheme_type: formData.scheme_type || null,
        scheme_name: formData.scheme_name,
        start_date: formData.start_date,
        end_date: formData.end_date,
        scheme_for: formData.scheme_for ? formData.scheme_for : null,
        status: formData.status,
        fulfillment_type: formData.fulfillment_type,
        is_all_cities: formData.is_all_cities,
        is_all_financers: formData.is_all_financers,
        is_all_builders: formData.is_all_builders,
        is_default: formData.is_default ? formData.is_default : "0",
        approved_status: formData.approval_status
          ? formData.approval_status.toString()
          : "0",
        scheme_tnc: editorContent,
      },
    };
    data.updateSchemeArgs = variables;
    console.log({ data });
    if (validateForm()) {
      try {
        const result = await executeGraphQLMutation(Update_SCHEME, variables);

        if (result) {
          if (result?.data?.updateScheme?.error) {
            toast.error(result?.data?.updateScheme?.msg);
          } else {
            toast.success(result?.data?.updateScheme?.msg || "Success");
          }

          // handleTabClick("details");
        }
      } catch (error) {
        toast.error("Failed");
      }
    }
  };
  const cleanString = (input) => {
    const withoutTags = input.replace(/<\/?[^>]+(>|$)/g, "");    
    const withoutExtraSpaces = withoutTags.replace(/\s\s+/g, ' ').trim();
    return withoutExtraSpaces;
  }
  const validateForm = () => {
    let validForm = true;
    let newErrors = { ...errors };
    // let newFormData = { ...formData };
    // console.log("editorContent:", cleanString(editorContent))
    const editorContentText = cleanString(editorContent);
    if (!editorContentText || editorContentText === "") {
      newErrors["scheme_tnc"] = "Please enter scheme terms and conditions";
      validForm = false;
    } else {
      newErrors["scheme_tnc"] = ""
    }
    console.log(newErrors)
    setErrors(newErrors);
    return validForm;
  };

  // console.log("content: ", props?.schemedetailsData?.scheme_tnc)

  return (
    <div className="card" title="Terms & Conditions">
      <div className="card-body">
        <CKEditor
          initData={editorContent}
          onInstanceReady={() => {
            console.log("Editor is ready!");
          }}
          onChange={(event) => {
            console.log(event.editor.getData());
            setEditorContent(event.editor.getData());
          }}
          config={{ versionCheck: false }}
        />
        {errors.scheme_tnc &&
            <div className="error show" >{errors.scheme_tnc}</div>
        }
        <div className="text-center">
          <button
            style={{ marginBottom: 10, marginTop: 20 }}
            onClick={updateScheme}
            className="btn btn-primary"
          >
            <b>Update</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
