import React, { useEffect, useState } from "react";
import { MasterService } from "../../../../services";
import Modal from "../../../elements/Modal";

const SubPartnerDeatils = ({ basic_details }) => {
  const [subPartnerList, setSubPartnerList] = useState([]);
  const [supportOpen,setsupportOpen] = useState(false)

  useEffect(() => {
    getSubPartnerList();
  }, []);

  const getSubPartnerList = async () => {
    const payload = {};
    payload.parent_partner_id = basic_details.id;
    payload.source = "payout";
    payload.type = "sub_partners";
    const response = await MasterService.get(
      "/partner/partner/sub-partner-list",
      payload
    );
    setSubPartnerList(response.data.data.sub_partner_list);
  };


  const SupportPopup = () => {
    setsupportOpen(prevState => !prevState);
    document.body.classList.toggle("overflow-hidden", !supportOpen);
  };


  return (
    <>
      <div className="search-wrap">
          <div>
            <div className="subpartnerdeatils">
              Sub Partner :
              <span onClick={SupportPopup}>
                 View All 
              </span>
            </div>

            <table className="table table-bordered tablefontsize">
              <thead>
                <tr>
                  <th>Partner ID</th>
                  <th>Partner Name</th>
                  <th>KYC Status</th>
                  <th>Registered at</th>
                </tr>
              </thead>
              <tbody>
                  {subPartnerList.slice(0, 2).map((data) => {
                  return (
                  <tr key={data?.sub_partner_id}>
                  <td>{data?.sub_partner_id ? data?.sub_partner_id : "N/A"}</td>
                  <td>{data?.sub_partner_name ? data?.sub_partner_name : "N/A"}</td>
                  <td>{data?.status ? (data?.status === 1 ? "Verified" : "Pending") : "N/A"}</td>
                  <td>
                  <div>{data?.registered_date ? new Date(data?.registered_date).toLocaleDateString() : "N/A"}</div>
                  <div>{data?.registered_date ? new Date(data?.registered_date).toLocaleTimeString() : "N/A"}</div>
                  </td>
                  </tr>
                  );
                  })}
                 </tbody>

            </table>
          </div>
        
          <Modal show={supportOpen} handleClose={SupportPopup} width={1200} >
          <div style={{padding:40}}>
          <table className="table table-bordered tablefontsize">
              <thead>
                <tr>
                  <th>Partner ID</th>
                  <th>Partner Name</th>
                  <th>KYC Status</th>
                  <th>Registered at</th>
                </tr>
              </thead>
              <tbody>
                {subPartnerList.map((data) => {
                  return (
                    <tr>
                      <td>{data?.sub_partner_id ? data?.sub_partner_id : data?.sub_partner_id}</td>
                      <td>{data?.sub_partner_name ? data?.sub_partner_name : "N/A"}</td>
                      <td>{data?.status ?  data?.status == 1 ? "Verified" : "Pending" :"N/A"}</td>
                      <td><div>{data?.registered_date ? new Date(data?.registered_date).toLocaleDateString() : "N/A"}</div><div>{data?.registered_date ? new Date(data?.registered_date).toLocaleTimeString() : "N/A"}</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
          </Modal>
        </div>

        <div className="search-wrap">
         <div className="subpartnerdeatils">App Activity Info:</div>
         <div className="subpatnerlogin">
          <div>
             First App Login {"  -   "}
             <span>{basic_details.first_login_date ? new Date(basic_details.first_login_date).toLocaleDateString() : "N/A"} |</span>
             <span> {basic_details.first_login_date ? new Date(basic_details.first_login_date).toLocaleTimeString() : "N/A"}</span>
          </div>
          <div>
             Latest App Login  {"  -  "}
             <span>{basic_details.first_login_date ? new Date(basic_details.last_login_date).toLocaleDateString() : "N/A"}|</span>
             <span> {basic_details.first_login_date ? new Date(basic_details.last_login_date).toLocaleTimeString() : "N/A"}</span>
          </div>
        
         </div>
          </div>
    </>
  );
};

export default SubPartnerDeatils;
