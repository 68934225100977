import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../../elements/partnerModal";
import "../../pre-register-lead.css";
import { MasterService } from "../../../../../services";
import Loader from "../../../../elements/Loader";
import CallHistoryTab from "./CallHistoryTab";
import PartnerHistoryTab from "./PartnerHistoryTab";
import WhatsappHistoryTab from "./WhatsappHistoryTab";
import { IS_USER_ACCESS_PAGE } from "../../../../../config/constants";

const TEMPLATE_ID = 39;

export const PartnerLeadHistory = ({ show, closeModal, leadData }) => {
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Call History");

  const styles = {
    addPartnerButton: {
      backgroundColor: "#6147FF",
      color: "#fff",
      borderRadius: "10px 10px 0 0",
      border: "none",
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
    },
    wiredButton: {
      backgroundColor: "transparent",
      color: "#6147FF",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      borderBottom: "2px solid #6147FF",
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      marginRight: "10px",
    },
  };

  const getCallHistory = async () => {
    const params = { partner_id: leadData.id };
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        "partner/partner-call/partner-call-details-activity",
        params
      );
      setHistoryData(response.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const getPartnerHistory = async () => {
    const params = { lead_id: leadData.id };
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        "/partner/partner/get-pre-lead-activity-log",
        params
      );
      setHistoryData(response?.data || []);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  const getWhatsappHistory = async () => {
    try {
      setIsLoading(true);
      const response = await MasterService.get(
        `/partner/partner/view-whatsapp-status?partnerId=${leadData.id}&notificationId=${TEMPLATE_ID}`
      );
      setHistoryData(response?.data || []);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "Partner History") {
      getPartnerHistory();
    } else if (activeTab === "Call History") {
      getCallHistory();
    } else if (activeTab === "Whatsapp History") {
      getWhatsappHistory();
    }
  }, [activeTab]);

  const access = useMemo(
    () => ({
      partner_lead_history: IS_USER_ACCESS_PAGE(
        "partner",
        "partner_lead_history"
      )?.is_edit_access,
    }),
    []
  );

  const handleOnCloseModal = () => {
    setHistoryData([]);
    closeModal();
    setIsLoading(false);
  };

  const handleActiveButton = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Modal
      show={show}
      handleClose={handleOnCloseModal}
      customStyle={{ width: "80%" }}
    >
      {isLoading ? <Loader /> : null}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "15px",
        }}
      >
        <h2>{activeTab}</h2>
      </div>
      <div style={{ display: "flex", justifyContent: "start", marginLeft: 10 }}>
        {access.partner_lead_history && (
          <button
            style={{
              ...styles.wiredButton,
              borderBottomWidth:
                activeTab === "Partner History" ? "2px" : "0px",
            }}
            onClick={() => handleActiveButton("Partner History")}
          >
            Partner History
          </button>
        )}
        <button
          style={{
            ...styles.wiredButton,
            borderBottomWidth: activeTab === "Call History" ? "2px" : "0px",
          }}
          onClick={() => handleActiveButton("Call History")}
        >
          Call History
        </button>
        <button
          style={{
            ...styles.wiredButton,
            borderBottomWidth: activeTab === "Whatsapp History" ? "2px" : "0px",
          }}
          onClick={() => handleActiveButton("Whatsapp History")}
        >
          Whatsapp History
        </button>
      </div>
      {activeTab === "Partner History" && (
        <PartnerHistoryTab data={historyData} />
      )}
      {activeTab === "Call History" && <CallHistoryTab data={historyData} />}
      {activeTab === "Whatsapp History" && (
        <WhatsappHistoryTab data={historyData} />
      )}
    </Modal>
  );
};

export default PartnerLeadHistory;
