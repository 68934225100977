import React, { useEffect, useState } from "react";
import { MasterService } from "../../../../services";
import DateFormate from "dateformat";
import Pagination from "../../../elements/pagination";
import { Link } from "react-router-dom";

const SupportListing = ({ searcFilter, buttonClick }) => {
  const [dealerList, setDealerList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const getDealerList = async () => {
    setIsLoading(true);
    let user_type;
    const user_information = localStorage.getItem("user_information");
    const loginResponse = JSON.parse(localStorage.getItem("user_information"));

    if (loginResponse.role_names.includes("Partner Support Executive")) {
      user_type = "partner_support_executive";
    } else if (loginResponse.role_names.includes("Partner Admin")) {
      user_type = "partner_admin";
    }

    const filters = {
      user_type,
      page_no: pageNumber,
      list_type: "saathi_support",
      call_by_source: "sangam",
      user_id: loginResponse.id,
      ...searcFilter, // Spreading search filters
    };

    const response = await MasterService.post(
      "dealer/dealer/dealer-list",
      filters
    );

    setIsLoading(false);

    if (response.data?.data) {
      setDealerList(response.data.data);
      setTotalPage(Math.ceil(response.data.pagination.total / 10));
    }
  };

  useEffect(() => {
    if (searcFilter?.isForced) {
      getDealerList();
    }
  }, [searcFilter]);

  // Combined useEffect to handle both initial and filter/button updates
  useEffect(() => {
    getDealerList();
  }, [buttonClick, pageNumber]);

  const onPaginate = (pagenumber) => {
    setPageNumber(pagenumber);
  };

  const calculateAppInactivityStatus = (lastLoginDateTime) => {
    if (!lastLoginDateTime) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const lastLoginDate = new Date(lastLoginDateTime);
    const timeDifference = new Date() - lastLoginDate;

    const daysInactive = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursInactive = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesInactive = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondsInactive = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days: daysInactive > 0 ? daysInactive : 0,
      hours: hoursInactive > 0 ? hoursInactive : 0,
      minutes: minutesInactive,
      seconds: secondsInactive,
    };
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <div className="loading result-wrap"></div>
        </div>
      ) : (
        <div className="table-responsive" width={100}>
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>
                <th>Partner details</th>
                {/* <th>Calling Buckets</th> */}
                <th>Call status/Disposition</th>
                <th>Verification Status</th>
                <th>Followup(s)</th>
                <th>Last Connected</th>
                <th>Category</th>
                <th>Registered at</th>
                <th>Relationship Manager</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dealerList?.map((dealer, index) => {
                const inactivityStatus = calculateAppInactivityStatus(
                  dealer.followup_date
                );
                return (
                  <tr key={index}>
                    <td>
                      <div>{dealer.organization}</div>
                      <div>{dealer.agent_code}</div>
                    </td>
                    {/* <td>Inactive, Payouts ready but KYC Pending</td> */}
                    <td>
                      <div>{dealer?.status_name || "N/A"}</div>
                      <div>{dealer?.sub_status_name || "N/A"}</div>
                    </td>

                    <td>{dealer.partnerStatus}</td>
                    <td>
                      <div className="statusflexlist">
                        {dealer.followup_date ? (
                          <>
                            <span className="supportliststatus">
                              {DateFormate(new Date(), "dS mmm yyyy") ===
                              DateFormate(dealer.followup_date, "dS mmm yyyy")
                                ? "Today"
                                : DateFormate(
                                    dealer.followup_date,
                                    "dS mmm yyyy"
                                  )}
                            </span>
                            <span>
                              {DateFormate(dealer.followup_date, "hh: MM TT")}
                            </span>
                          </>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                      {inactivityStatus.days !== 0 &&
                        inactivityStatus.hours !== 0 && (
                          <div className="supporttime">
                            Overdue by:{" "}
                            <span>
                              {" "}
                              {`${inactivityStatus.days} days ${inactivityStatus.hours} hours`}
                            </span>
                          </div>
                        )}
                    </td>
                    <td>
                      <div>
                        {dealer.connected_at
                          ? DateFormate(dealer.connected_at, "dS mmm yyyy")
                          : "N/A"}
                      </div>
                      <div>
                        {dealer.connected_at
                          ? DateFormate(dealer.connected_at, "h: MM TT")
                          : "N/A"}
                      </div>
                    </td>
                    <td>
                      <div
                        className="statusinactive"
                        style={
                          ["active", "super_active"].includes(
                            dealer?.support_category
                          )
                            ? {
                                background: "#32C498",
                                textTransform: "capitalize",
                              }
                            : {
                                background: "#EEA8A8",
                                textTransform: "capitalize",
                              }
                        }
                      >
                        {dealer?.support_category?.replace("_", " ") || "N/A"}
                      </div>
                    </td>
                    <td>
                      <div>
                        {dealer.created_date
                          ? DateFormate(dealer.created_date, "dS mmm yyyy")
                          : "N/A"}
                      </div>
                      <div>
                        {dealer.created_date
                          ? DateFormate(dealer.created_date, "h:MM TT")
                          : "N/A"}
                      </div>
                    </td>
                    <td>
                      <div>{dealer.sfa_refferal_user_name || "N/A"}</div>
                      <div>
                        ID - {dealer.sfa_referral_user_id || "N/A"} |{" "}
                        <span>{dealer.sfa_refferal_user_mobile || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="mrg-r10" style={{ width: "30px" }}>
                        <Link
                          style={{ color: "gray" }}
                          className="commoniconbtn"
                          to={`/dealer/${dealer.dealer_id_hash}/support`}
                        >
                          <i
                            className="ic-createmode_editedit icons-normal"
                            style={{ size: "10%" }}
                          ></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={9}>
                  <Pagination
                    pageCount={totalPage}
                    activePage={pageNumber}
                    onPaginate={onPaginate}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SupportListing;
