import React, { useEffect, useState } from "react";
import { convertJsonToCsv, downloadFile } from "../../../helpers/helpers";
import { RotatingLines } from "react-loader-spinner";
import Modal from "../../elements/Modal";
import Formsy from "formsy-react";
import FormsySelect from "../../elements/FormsySelect";

const MIN_TENURE_YEAR = 5;
const MIN_LOAN_AMOUNT = 500000;

const BreOfferSection = ({
  checkBankOffers,
  recalculateOffer,
  isLoadingOffer,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [allFees, setAllFees] = useState({});
  const [checkedOffers, setCheckedOffers] = useState([]);
  const [loanDetails, setLoanDetails] = useState({});

  useEffect(() => {
    if (checkBankOffers.length) {
      const listOfLoanAmount = checkBankOffers.map(
        (offer) => offer.maxEligibalLoanAmount
      );
      const listOfMaxLoanAmount = checkBankOffers.map((offer) =>
        offer.eligibalLoanAmount.replaceAll(",", "")
      );
      const listOfTenure = checkBankOffers.map((offer) => offer.finalMaxTenure);
      const listOfMaxTenure = checkBankOffers.map((offer) => offer.maxTenure);
      const maxEligibleLoan = Math.max(...listOfLoanAmount);
      const maxLoan = Math.max(...listOfMaxLoanAmount);
      const maxTenure = Math.max(...listOfTenure);
      const tenure = Math.max(...listOfMaxTenure);

      setLoanDetails((prev) => ({
        ...prev,
        maxEligibleLoanAmount: maxEligibleLoan,
        requiredLoanAmount: maxEligibleLoan,
        minLoanAmount: MIN_LOAN_AMOUNT,
        requiredTenure: maxTenure,
        maxLoan,
        tenure,
        maxTenure,
      }));
    }
  }, [checkBankOffers]);

  const getMaxTenureOptions = (minYear, maxYear) => {
    const options = [];
    for (let i = minYear; i <= maxYear; i++) {
      options.push({
        id: i,
        label: `${i} Years`,
        value: i,
      });
    }
    return options;
  };

  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const handleExportCSVFile = () => {
    const offersList =
      checkedOffers.length > 0 ? checkedOffers : checkBankOffers;
    const requiredOfferCol = JSON.parse(JSON.stringify(offersList)).map(
      (offer) => {
        offer.bankId && delete offer.bankId;
        offer.bankLogo && delete offer.bankLogo;
        offer.propValue && delete offer.propValue;
        offer.documentSet && delete offer.documentSet;
        offer.messages = offer.messages?.join("||").replaceAll(",", "");
        offer.processingText = offer.processingText?.replaceAll(",", "");
        offer.login_fee = offer.login_fee?.replaceAll(",", "");
        offer.ac_handling_charge = offer.ac_handling_charge?.replaceAll(
          ",",
          ""
        );
        offer.legal_technical_fee = offer.legal_technical_fee?.replaceAll(
          ",",
          ""
        );
        offer.e_stamp_charge = offer.e_stamp_charge?.replaceAll(",", "");
        offer.due_diligence_charge = offer.due_diligence_charge?.replaceAll(
          ",",
          ""
        );
        offer.CERSAI_charge = offer.CERSAI_charge?.replaceAll(",", "");
        offer.MODT_charge = offer.MODT_charge?.replaceAll(",", "");
        offer.NOI_charge = offer.NOI_charge?.replaceAll(",", "");
        offer.netMonthlyIncome = offer.netMonthlyIncome?.replaceAll(",", "");
        offer.eligibalLoanAmount = offer.eligibalLoanAmount?.replaceAll(
          ",",
          ""
        );
        offer.monthlyEMI = offer.monthlyEMI?.replaceAll(",", "");

        return { ...offer };
      }
    );
    const csvData = convertJsonToCsv(requiredOfferCol);
    downloadFile(csvData, "text/csv", `BRE_OFFERS_${getDateAndTimeStr()}.csv`);
  };

  const handleAmountSlider = (event) => {
    const { value } = event.target;
    setLoanDetails((prev) => ({
      ...prev,
      maxLoan: +value,
    }));
  };

  const handleViewFeeModal = (index) => {
    const feesObj = {};
    const selectedOffer = checkBankOffers[index];
    feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
    feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
    feesObj["Account handling charges/Other Charges"] =
      selectedOffer?.ac_handling_charge || "N/A";
    feesObj["Legal and Technical Fee"] =
      selectedOffer?.legal_technical_fee || "N/A";
    feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
    feesObj["Due Diligence Charges"] =
      selectedOffer?.due_diligence_charge || "N/A";
    feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
    feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
    feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
    setAllFees(feesObj);
    setShowModal(true);
    document.body.classList.add("overflow-hidden");
  };

  const handleCloseFeeModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleOfferCheck = (event, index) => {
    if (event.target) {
      const { checked } = event.target;
      const existingList = [...checkedOffers];
      if (checked) {
        const filtered = checkBankOffers[index];
        existingList.push({ ...filtered, offerIndex: index });
      } else {
        const unCheckedIndex = existingList.findIndex(
          (obj) => obj.offerIndex === index
        );
        if (unCheckedIndex !== -1) {
          existingList.splice(unCheckedIndex, 1);
        }
      }
      setCheckedOffers(existingList);
    }
  };

  const handleTenureChange = (data) => {
    setLoanDetails((prev) => ({
      ...prev,
      tenure: data.value,
    }));
  };

  const handleRecalculate = () => {
    const { maxLoan, tenure } = loanDetails;
    const reCalculate = {
      loan_amount: `${maxLoan}`,
      max_tenure: tenure,
    };
    recalculateOffer(reCalculate);
  };

  useEffect(() => {
    const rangeInputs = document.querySelectorAll(
      'input[type="range"].slider-progress'
    );
    rangeInputs.forEach((e) => {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min === "" ? "0" : e.min);
      e.style.setProperty("--max", e.max === "" ? "100" : e.max);
      e.addEventListener("input", (event) => {
        e.style.setProperty("--value", event.target.value);
      });
    });
    return () => {
      rangeInputs.forEach((e) => {
        e.removeEventListener("input", () => {});
      });
    };
  }, [loanDetails]);

  return (
    <>
      <div className="doument-upload-left-panel">
        {checkBankOffers.length > 0 && loanDetails?.requiredLoanAmount > 0 && (
          <>
            <div className="row">
              <div className="col-md-8">
                {/* <div className="banktipscard">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/4486/1729775917444.png"
                    alt="tips"
                  />
                  <div>
                    Income allows for a higher loan of upto
                    <span>
                      {" "}
                      ₹ {getNumberFormat_IN(loanDetails?.maxLoanAmount)}
                    </span>{" "}
                    customer can increase their property budget!
                  </div>
                </div> */}
              </div>

              <div className="col-md-4">
                {checkBankOffers.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div onClick={handleExportCSVFile}>
                      <img
                        src="https://ambak.storage.googleapis.com/partner/4743/1731116440307.png"
                        alt="download"
                        width={30}
                        height={30}
                      />
                      {/* <i className="ic-upload offeruploadbre"></i> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row" style={{ marginTop: "18px" }}>
              {/* <div className="col-md-8">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label>Desired Amount</label>
                  </div>
                  <div>
                    <label>₹ {getNumberFormat_IN(loanDetails?.maxLoan)}</label>
                  </div>
                </div>
                <div className="box" style={{ marginBottom: "10px" }}>
                  <div className="range" style={{ width: "100%" }}>
                    <input
                      step={1000}
                      type="range"
                      id="loan_amount"
                      name="loan_amount"
                      onChange={handleAmountSlider}
                      className="styled-slider slider-progress"
                      min={loanDetails?.minLoanAmount}
                      max={loanDetails?.maxEligibleLoanAmount}
                      value={loanDetails?.maxLoan}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.minLoanAmount)}
                    </label>
                  </div>
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.maxEligibleLoanAmount)}
                    </label>
                  </div>
                </div>
              </div> */}
              <div className="col-md-3" style={{ marginTop: "10px" }}>
                <Formsy>
                  <fieldset className="single-select">
                    <FormsySelect
                      style={{ width: "200px" }}
                      name={"requiredTenure"}
                      id={"requiredTenure"}
                      inputProps={{
                        options: getMaxTenureOptions(
                          MIN_TENURE_YEAR,
                          loanDetails?.maxTenure
                        ),
                        placeholder: "Tenure",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        isDisabled: false,
                        value: getMaxTenureOptions(
                          MIN_TENURE_YEAR,
                          loanDetails?.maxTenure
                        ).filter((obj) => obj.id == loanDetails.tenure),
                      }}
                      required={false}
                      onChange={(data) => handleTenureChange(data)}
                    />
                  </fieldset>
                </Formsy>
              </div>

              <div className="col-md-1">
                <div className="btn-save-remarks">
                  <div
                    className="bankofferrecalculate"
                    onClick={handleRecalculate}
                  >
                    Recalculate
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="breoffercard">
          <div className="breofferheading">
            {checkBankOffers.length === 0 &&
              !isLoadingOffer &&
              "Please fill all the required fields to get offers"}
          </div>

          {isLoadingOffer && (
            <div style={{ textAlign: "center" }}>
              <RotatingLines
                visible={true}
                height="80"
                width="80"
                strokeColor="grey"
                strokeWidth="4"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <p>Please wait while generating offer</p>
            </div>
          )}

          {checkBankOffers.map((offer, index) => (
            <div key={index} className="offerbox">
              <div className="offercardflex">
                <div className="offerimg">
                  <img src={offer.bankLogo} alt="" />
                </div>

                <div className="offerwidth">
                  <div className="offerlistingcard">
                    <div>
                      <input
                        type="checkbox"
                        onClick={(event) => handleOfferCheck(event, index)}
                      />
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Eligible Loan Amount</div>
                      <div className="breheading">
                        ₹ {offer.eligibalLoanAmount}
                      </div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Monthly EMI</div>
                      <div className="breheading">₹ {offer.monthlyEMI}</div>
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Interest Rate</div>
                      <div className="breheading">{offer.minRoi} %</div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Tenure</div>
                      <div className="breheading">{offer.maxTenure} years</div>
                    </div>
                  </div>
                </div>

                <div
                  className="breviewfees"
                  onClick={() => handleViewFeeModal(index)}
                >
                  View Fees
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={showModal} handleClose={handleCloseFeeModal}>
          <h2>Fees</h2>
          {Object.keys(allFees)?.map((feeKey, index) => (
            <div className="modal-body" key={index}>
              <div className="offerlistingfees">
                <div className="feesheading" style={{ fontSize: 14 }}>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li style={{ listStyle: "initial" }}>
                      {feeKey} :{" "}
                      <span className="feessubheading">{allFees[feeKey]}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      </div>
    </>
  );
};

export default BreOfferSection;
