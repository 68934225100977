import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { SAVE_BRE_OFFER } from "../../../services/customer.gql";
import FormFields from "../formFields/breOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import React, { useState, useMemo, useEffect } from "react";
import FormsySelect from "../../elements/FormsySelect";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import Formsy from "formsy-react";
import {
  GET_BRE_OFFERS,
  GET_BUILDER_LIST,
  GET_PROJECT_LIST,
} from "../../../services/bre.gql";
import { getNumberFormat_IN } from "../../../helpers/helpers";
import {
  getMasterCityState,
  setCityList,
} from "../../../store/action/allAction";
import { useLocation } from "react-router-dom";

const PROPERTY_PROFILE_FIELDS = FormFields["PROPERTY"];

const BreProperty = ({
  breData,
  setBankOffersDetails,
  recalculate,
  isLoadingOffer,
  activeTab,
}) => {
  const [breProperty, setBreProperty] = useState({});
  const [offerBtnDisabled, setOfferBtnDisabled] = useState(false);
  const [isFormTouch, setTouch] = useState({});
  const client = useApolloClient();
  const location = useLocation();
  const dispatch = useDispatch();
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  useEffect(() => {
    if (!masterdata.data || !masterdata.data.state_list) {
      dispatch(getMasterCityState());
    }

    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.city && options?.city?.length) {
      let record = options.city
        .filter((ob) => ob.state_id == breProperty.property_state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });
      dispatch(setCityList(record));
    }
  }, [breProperty?.property_state]);

  useEffect(() => {
    if (Object.keys(recalculate).length) {
      isLoadingOffer(true);
      getBankOffers(null, recalculate);
    }
  }, [recalculate]);

  useEffect(() => {
    if (
      lead &&
      lead.leadDetail &&
      lead.leadDetail.customer &&
      location.pathname.indexOf("offer-check") > -1 &&
      activeTab === "property"
    ) {
      let basic_profile = {
        expected_property_value:
          lead?.leadDetail?.lead_details?.expected_property_value,
        property_type: lead?.leadDetail?.lead_details?.property_type,
        property_sub_type: lead?.leadDetail?.lead_details?.property_sub_type,
        property_city: lead?.leadDetail?.lead_details?.property_city,
        property_state: lead?.leadDetail?.lead_details?.property_state,
        agreement_type: lead?.leadDetail?.lead_details?.agreement_type,
        builder_name_id: lead?.leadDetail?.lead_details?.builder_name_id,
        check_oc_cc:
          lead?.leadDetail?.lead_details?.check_oc_cc === "1" ? "yes" : "no",
        ready_for_registration:
          lead?.leadDetail?.lead_details?.ready_for_registration === "1"
            ? "yes"
            : "no",
        property_agreement_value:
          lead?.leadDetail?.lead_details?.property_agreement_value,
        project_name: lead?.leadDetail?.lead_details?.project_name,
        is_property_identified:
          lead?.leadDetail?.lead_details?.is_property_identified,
      };

      const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[basic_profile?.["property_type"]]?.includes(
          obj.value
        )
      );
      ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;

      if (basic_profile.property_city) {
        setBuilderAndProjectList("builder", basic_profile.property_city);
      }
      if (basic_profile.builder_name_id) {
        setBuilderAndProjectList("project", basic_profile.builder_name_id);
      }

      setBreProperty(basic_profile);
    }
  }, [lead, location, activeTab]);

  /**
   * key: property_type
   * value: list of sub_properties
   */
  const SUB_PROPERTY_MAPPING_OBJ = {
    9: [1, 2, 3],
    11: [1, 4],
    12: [1, 4],
    13: [1, 2, 3],
    14: [1, 2, 3, 4],
  };

  const ADDITIONAL_INCOME_MAPPING_OBJ = {
    1: "rent_amount",
    2: "annual_bonus_amount",
    3: "monthly_incentives_amount",
    4: "monthly_reimbursements_amount",
    5: "others",
    6: "liquid_amount",
  };

  const PROPERTY_TYPE_MAPPING_OBJ = {
    9: "resale",
    11: "builder_endorsement",
    12: "builder_allotment",
    13: "authority_allotment",
    // 14: "allotment_transfer",
  };

  const PROPERTY_SUB_TYPE_MAPPING_OBJ = {
    1: "ready_to_move",
    4: "contruction_linked",
    2: "plot",
    3: "plot_contruction",
  };

  const PROPERTY_USAGE_MAPPING_OBJ = {
    1: "commercial",
    2: "residential",
    3: "industrial",
  };

  const PROPERTY_REGISTRATION_TYPE_MAPPING_OBJ = {
    1: "khasra_khatoni_laldora",
    2: "regularised",
    3: "khasra_khatoni_laldora",
    4: "khasra_khatoni_laldora",
    5: "khasra_khatoni_laldora",
    6: "freehold",
    7: "leasehold",
  };

  const getBuilderList = async (key_name, value) => {
    try {
      let cityId = null,
        builderId = null;
      if (key_name === "builder") {
        cityId = +value;
        builderId = null;
      } else if (key_name === "project") {
        cityId = +breProperty["property_city"] || null;
        builderId = +value;
      }
      const variables = {
        [`${key_name}Params`]: {
          ...(cityId ? { cityId } : {}),
          ...(builderId ? { builderId } : {}),
        },
      };
      const mutation =
        key_name === "builder" ? GET_BUILDER_LIST : GET_PROJECT_LIST;
      const resp = await executeGraphQLMutation(mutation, variables, client);
      let optionList = [];
      if (resp.data?.[`get_${key_name}_name`]?.data) {
        const responseData = resp.data?.[`get_${key_name}_name`];
        optionList = responseData.data?.map((obj) => {
          return {
            id: obj[`${key_name}_id`],
            value: obj[`${key_name}_id`],
            label: obj[`${key_name}_name`],
          };
        });
        return optionList;
      } else {
        toast.error(resp.data?.[`get_${key_name}_name`]?.message);
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error("Internal server error");
      return [];
    }
  };

  const setBuilderAndProjectList = async (key_name, value) => {
    if (
      lead?.leadDetail?.lead_details?.is_property_identified === "yes" &&
      location.pathname.indexOf("offer-check") > -1
    ) {
      const list = await getBuilderList(key_name, value);
      ALL_OPTIONS[`${key_name}_name`] = list;
    }
  };

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    const existingPropertyType = breProperty["property_type"] || null;
    // const sortedStates = sortStateListByTopStates(options.state_list);
    options["property_type"] = options["property_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    options["property_sub_type"] = options["property_sub_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    if (existingPropertyType) {
      const filteredSubType = options["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[existingPropertyType]?.includes(obj.value)
      );
      options["filtered_property_sub_type"] = filteredSubType;
    }
    // options["state_list"] = sortedStates;
    return options;
  }, [masterdata, lead, location]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let isPropIdentified = "no";
    if (checked) {
      isPropIdentified = "yes";
    }
    setBreProperty((currentValue) => ({
      ...currentValue,
      [name]: isPropIdentified,
    }));
  };

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      setBreProperty((currentValue) => ({
        ...currentValue,
        [id]: value,
      }));
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setBreProperty((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSelectChange = async (data, field_name) => {
    if (data && field_name) {
      if (field_name === "property_type") {
        const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter(
          (obj) => SUB_PROPERTY_MAPPING_OBJ[data.value].includes(obj.value)
        );
        ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
      }
      if (field_name === "property_city") {
        const list = await getBuilderList("builder", data.value);
        ALL_OPTIONS["builder_name"] = list;
      }
      if (field_name === "builder_name_id") {
        const list = await getBuilderList("project", data.value);
        ALL_OPTIONS["project_name"] = list;
      }
      setBreProperty((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const convertValuesToNumeric = (obj) => {
    const finalObj = {};
    for (let key in obj) {
      finalObj[key] = +obj[key];
    }
    return finalObj;
  };

  const getBankOffers = (isPropertyConsidered, recalculatedObj = {}) => {
    const {
      loan_type,
      loan_amount,
      profession,
      phone_number,
      dob,
      fulfillment_type,
      gross_monthly_salary,
      monthly_salary,
      existing_emi_amount,
      emi_ending_six_month,
      tenure,
      is_additional_income,
      additional_income,
      is_co_applicant,
      co_applicant,
      bureau_score,
      income_calculation_mode,
      year_1,
      year_2,
      no_of_accounts,
    } = breData;

    const {
      is_property_identified,
      expected_property_value,
      property_type,
      property_sub_type,
      property_agreement_value,
      property_city,
      agreement_type,
      builder_name_id,
      project_name,
      check_oc_cc,
      ready_for_registration,
    } = breProperty;

    let isPropDec = "",
      loanAmount = "",
      maxTenure = "";
    const lead_id = lead?.leadDetail?.id || "";

    if (isPropertyConsidered !== undefined && isPropertyConsidered !== null) {
      isPropDec = isPropertyConsidered ? "Yes" : "No";
    } else {
      isPropDec = is_property_identified
        ? is_property_identified.charAt(0).toUpperCase() +
          is_property_identified.slice(1)
        : "No";
    }

    if (Object.keys(recalculatedObj).length) {
      loanAmount = recalculatedObj?.loan_amount;
      maxTenure = `${recalculatedObj?.max_tenure}`;
    } else {
      loanAmount = loan_amount;
      maxTenure = tenure ? `${tenure}` : null;
    }

    const other_income = {};
    let isCoAppAddIncConsider = "No";

    const isAddInc =
      profession !== 1 && income_calculation_mode === "bank"
        ? "No"
        : is_additional_income
        ? is_additional_income.charAt(0).toUpperCase() +
          is_additional_income.slice(1)
        : "";

    const isCo = is_co_applicant
      ? is_co_applicant.charAt(0).toUpperCase() + is_co_applicant.slice(1)
      : "";

    const isOC_CC = check_oc_cc
      ? check_oc_cc.charAt(0).toUpperCase() + check_oc_cc.slice(1)
      : "";

    const isReadyRegistration = ready_for_registration
      ? ready_for_registration.charAt(0).toUpperCase() +
        ready_for_registration.slice(1)
      : "No";

    if (isAddInc === "Yes") {
      const filteredAdd = additional_income?.filter(
        ({ status }) => status == "1"
      );
      for (let i = 0; i < filteredAdd?.length; i++) {
        other_income[
          ADDITIONAL_INCOME_MAPPING_OBJ[filteredAdd[i].income_type_id]
        ] = {
          amount: +filteredAdd[i].amount,
          payment_mode: filteredAdd[i].amount_type,
        };
      }
    }

    let coApplicants = [];
    if (isCo === "Yes") {
      coApplicants = co_applicant?.map((coApp) => {
        const isCoAddInc = coApp.ca_is_additional_income
          ? coApp.ca_is_additional_income.charAt(0).toUpperCase() +
            coApp.ca_is_additional_income.slice(1)
          : "";

        const ca_applicant_other_income = {};

        if (isCoAddInc === "Yes") {
          isCoAppAddIncConsider = isCoAddInc;
          const filteredAdd = coApp.ca_additional_income?.filter(
            ({ status }) => status == "1"
          );
          for (let i = 0; i < filteredAdd.length; i++) {
            if (
              ADDITIONAL_INCOME_MAPPING_OBJ[filteredAdd[i].ca_income_type_id]
            ) {
              ca_applicant_other_income[
                `ca_${
                  ADDITIONAL_INCOME_MAPPING_OBJ[
                    filteredAdd[i].ca_income_type_id
                  ]
                }`
              ] = {
                amount: +filteredAdd[i].ca_amount,
                payment_mode: filteredAdd[i].ca_amount_type,
              };
            }
          }
        }

        const finalObj = {};
        if (+coApp.ca_profession === 3) {
          finalObj["income_calculation_mode"] =
            coApp?.ca_income_calculation_mode === "bank"
              ? "bank_income"
              : coApp?.ca_income_calculation_mode;
          if (coApp?.ca_income_calculation_mode === "itr") {
            const itrObj = {};
            if (coApp.year_1) {
              const parseYear1 = convertValuesToNumeric(coApp.year_1);
              itrObj["year1"] = {
                npat: parseYear1["npat"],
                depreciation: parseYear1["depreciation"],
                interest: parseYear1["interest"],
                tax_paid: parseYear1["tax_paid"],
              };
            }
            if (coApp.year_2) {
              const parseYear2 = convertValuesToNumeric(coApp.year_2);
              itrObj["year2"] = {
                npat: parseYear2["npat"],
                depreciation: parseYear2["depreciation"],
                interest: parseYear2["interest"],
                tax_paid: parseYear2["tax_paid"],
              };
            }
            if (Object.keys(itrObj).length === 0) {
              const obj = {};
              for (let i = 0; i < coApp?.ca_offer_itr_details?.length; i++) {
                const current = coApp?.ca_offer_itr_details[i];
                if (current.itr_year === "1") {
                  obj["year_1"] = {
                    npat: current.npat,
                    depreciation: current.depreciation,
                    interest: current.interest,
                    tax_paid: current.tax_paid,
                  };
                }
                if (current.itr_year === "2") {
                  obj["year_2"] = {
                    npat: current.npat,
                    depreciation: current.depreciation,
                    interest: current.interest,
                    tax_paid: current.tax_paid,
                  };
                }
              }
              if (obj.year_1) {
                const parseYear1 = convertValuesToNumeric(obj.year_1);
                itrObj["year1"] = {
                  npat: parseYear1["npat"],
                  depreciation: parseYear1["depreciation"],
                  interest: parseYear1["interest"],
                  tax_paid: parseYear1["tax_paid"],
                };
              }
              if (obj.year_2) {
                const parseYear2 = convertValuesToNumeric(obj.year_2);
                itrObj["year2"] = {
                  npat: parseYear2["npat"],
                  depreciation: parseYear2["depreciation"],
                  interest: parseYear2["interest"],
                  tax_paid: parseYear2["tax_paid"],
                };
              }
            }
            finalObj["ITR"] = itrObj;
          } else if (coApp?.ca_income_calculation_mode === "bank") {
            const bankIncomes = [];
            for (let i = 0; i < coApp.no_of_accounts; i++) {
              if (coApp[`bank_account_type_${i}`]) {
                bankIncomes.push({
                  ac_type: coApp[`bank_account_type_${i}`],
                  avg_balance: +coApp[`bank_account_avg_amount_${i}`],
                });
              } else {
                break;
              }
            }
            for (let i = 0; i < coApp?.ca_offer_bank_details?.length; i++) {
              const current = coApp?.ca_offer_bank_details[i];
              bankIncomes.push({
                ac_type: current?.account_type,
                avg_balance: +current?.amount,
              });
            }
            finalObj["bankIncome"] = bankIncomes;
          }
        }

        return {
          ca_profession: +coApp.ca_profession,
          ...(+coApp.ca_profession === 1
            ? { ca_monthly_salary: +coApp.ca_monthly_salary || null }
            : { ca_annual_income: +coApp.ca_annual_income || null }),
          ca_gross_monthly_salary: +coApp.ca_gross_monthly_salary || null,
          ...(coApp.ca_is_obligation === "yes"
            ? {
                ca_emi_ending_six_month: +coApp.ca_emi_ending_six_month,
                ca_existing_emi_amount: +coApp.ca_existing_emi_amount,
              }
            : {}),
          ca_applicant_other_income,
          ...finalObj,
        };
      });
    }

    const breOfferInput = {
      loan_type: `${loan_type}`,
      lead_details: {
        lead_id,
        loan_amount: loanAmount,
        profession: `${profession}`,
        cibil_score: bureau_score ? +bureau_score : -1,
        existing_emi_amount,
        ...(maxTenure ? { max_tenure: maxTenure } : {}),
        emi_ending_six_month: +emi_ending_six_month || 0,
        is_property_decided: isPropDec,
        ...(profession !== 1
          ? {
              income_calculation_mode:
                income_calculation_mode === "bank"
                  ? "bank_income"
                  : income_calculation_mode,
            }
          : {}),
      },
      customer: {
        mobile: phone_number,
        pancard_no: "",
        dob: `${dateFormat(dob, "dd/mm/yyyy")}`,
      },
      is_additional_income: `${isAddInc}`,
      other_income,
      is_co_additional_income: isCoAppAddIncConsider,
      co_applicant: coApplicants,
      fulfillment_type,
    };

    if (profession === 1) {
      breOfferInput.lead_details["monthly_salary"] = +monthly_salary;
      breOfferInput.lead_details["gross_monthly_salary"] = gross_monthly_salary
        ? +gross_monthly_salary
        : null;
    }

    if (profession === 3) {
      if (income_calculation_mode === "itr") {
        const itrObj = {};
        if (year_1) {
          const parseYear1 = convertValuesToNumeric(year_1);
          itrObj["year1"] = {
            npat: parseYear1["npat"],
            depreciation: parseYear1["depreciation"],
            interest: parseYear1["interest"],
            tax_paid: parseYear1["tax_paid"],
          };
        }
        if (year_2) {
          const parseYear2 = convertValuesToNumeric(year_2);
          itrObj["year2"] = {
            npat: parseYear2["npat"],
            depreciation: parseYear2["depreciation"],
            interest: parseYear2["interest"],
            tax_paid: parseYear2["tax_paid"],
          };
        }
        breOfferInput["ITR"] = itrObj;
      } else if (income_calculation_mode === "bank") {
        const bankIncomes = [];
        for (let i = 0; i < no_of_accounts; i++) {
          bankIncomes.push({
            ac_type: breData[`bank_account_type_${i}`],
            avg_balance: +breData[`bank_account_avg_amount_${i}`],
          });
        }
        breOfferInput["bankIncome"] = bankIncomes;
      }
    }

    if (isPropDec === "Yes") {
      const obj = {
        property_city,
        expected_market_value: expected_property_value
          ? +expected_property_value
          : null,
        property_type: PROPERTY_TYPE_MAPPING_OBJ[property_type],
        property_sub_type: PROPERTY_SUB_TYPE_MAPPING_OBJ[property_sub_type],
        ...(property_type === 9 || property_type === 13
          ? {
              agreement_type:
                PROPERTY_REGISTRATION_TYPE_MAPPING_OBJ[agreement_type],
            }
          : {}),
        registration_value: +property_agreement_value,
        ...(property_type === 11 || property_type === 12
          ? { builder_id: +builder_name_id || null }
          : {}),
        ...(property_type === 11 || property_type === 12
          ? { project_id: +project_name || null }
          : {}),
        ...(property_type === 11 ||
        (property_type === 12 && property_sub_type === 1)
          ? {
              check_oc_cc: isOC_CC,
              ready_for_registration: isReadyRegistration,
            }
          : {}),
      };
      breOfferInput["property_details"] = obj;
    }

    console.log({ API_REQUEST_BODY: breOfferInput });

    const variables = { breOfferInput };
    const mutation = GET_BRE_OFFERS;
    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        if (resp.data?.get_bre_offers) {
          const jsonData = JSON.parse(resp.data.get_bre_offers);
          if (jsonData.data) {
            const respData = jsonData.data || [];

            console.log({ API_RESPONSE: respData });

            respData.sort(
              (a, b) => b.eligibalLoanAmount - a.eligibalLoanAmount
            );
            const offerData = respData?.map((obj) => {
              return {
                ...obj,
                netMonthlyIncome: getNumberFormat_IN(
                  obj?.netMonthlyIncome || 0
                ),
                eligibalLoanAmount: getNumberFormat_IN(
                  obj?.eligibalLoanAmount || 0
                ),
                monthlyEMI: getNumberFormat_IN(Math.ceil(obj?.monthlyEMI) || 0),
              };
            });
            isLoadingOffer(false);
            setBankOffersDetails(offerData);
            setOfferBtnDisabled(false);
          } else {
            console.log("🔻 ERROR : ", jsonData);
            toast.error(jsonData.message);
            setOfferBtnDisabled(false);
            isLoadingOffer(false);
          }
        }
      })
      .catch((error) => {
        console.log(`🔻 ERROR : ${error.message}`);
        toast.error("Something went wrong");
      });
  };

  const handlePropertySave = () => {
    setOfferBtnDisabled(true);
    isLoadingOffer(true);
    const AGREEMENT_TYPE_GPA_ID = 4;
    if (
      breProperty["agreement_type"] === AGREEMENT_TYPE_GPA_ID &&
      breProperty.is_property_identified === "yes"
    ) {
      alert("We do not provide home loans for GPA properties");
      breProperty["agreement_type"] = null;
    }
    const lead_id = lead?.leadDetail?.id || "";
    const customer = {
      lead_id,
      first_name: breData.first_name,
      last_name: breData.last_name,
      mobile: breData.phone_number,
      dob: dateFormat(breData?.dob, "yyyy-mm-dd"),
    };
    let lead_details = {};

    if (breProperty.is_property_identified === "no") {
      lead_details = {
        lead_id,
        is_property_identified: breProperty.is_property_identified,
      };
    } else {
      lead_details = {
        lead_id,
        expected_property_value: breProperty.expected_property_value,
        is_property_identified: breProperty.is_property_identified,
        property_type: breProperty.property_type,
        property_sub_type: breProperty.property_sub_type,
        ...(breProperty.property_type === 11 || breProperty.property_type === 12
          ? {
              builder_name_id: breProperty.builder_name_id,
              project_name: breProperty.project_name,
            }
          : {}),
        ...(breProperty.property_type === 9 || breProperty.property_type === 13
          ? { agreement_type: breProperty.agreement_type }
          : {}),
        property_agreement_value: breProperty.property_agreement_value,
        check_oc_cc: breProperty?.check_oc_cc === "yes" ? "1" : "0",
        ready_for_registration:
          breProperty?.ready_for_registration === "yes" ? "1" : "0",
        // usage_type: +breProperty.usage_type,
        property_city: breProperty.property_city,
        property_state: breProperty.property_state,
        loan_amount: breData.loan_amount,
        profession: breData.profession,
        company_type: breData.company_type,
        company_name: breData.company_name,
        ...(breData.work_experience
          ? { work_experience: +breData.work_experience }
          : {}),
      };
    }

    let mutation = SAVE_BRE_OFFER(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          customer,
          lead_details,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          setOfferBtnDisabled(false);
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        getBankOffers();
      })
      .catch((err) => {
        toast.error(err.message);
        setOfferBtnDisabled(false);
        isLoadingOffer(false);
      });
  };

  const handleRemoveBuilder = () => {
    setBreProperty((current) => ({
      ...current,
      builder_name_id: null,
      project_name: null,
    }));
  };

  return (
    <>
      <div className="property-identified">
        <label>Property Identified ?</label>
        <label className="toggle-label">No</label>
        <label className="toggle-switch">
          <input
            type="checkbox"
            name="is_property_identified"
            id="is_property_identified"
            value="Yes"
            checked={
              breProperty["is_property_identified"] === "yes" ? true : false
            }
            onChange={handleCheckboxChange}
          />
          <span className="slider"></span>
        </label>
        <label className="toggle-label">Yes</label>
      </div>

      {breProperty["is_property_identified"] === "yes" ? (
        <div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => null}
            onInvalid={() => null}
            onValidSubmit={handlePropertySave}
          >
            <div className="row">
              {PROPERTY_PROFILE_FIELDS.map((field, index) =>
                (breProperty["property_type"] !== 9 &&
                  field.name === "agreement_type" &&
                  breProperty["property_type"] !== 13 &&
                  field.name === "agreement_type") ||
                (breProperty["property_type"] !== 11 &&
                  field.name === "builder_name_id" &&
                  breProperty["property_type"] !== 12 &&
                  field.name === "builder_name_id") ||
                (breProperty["property_type"] !== 11 &&
                  field.name === "project_name" &&
                  breProperty["property_type"] !== 12 &&
                  field.name === "project_name") ||
                (!breProperty["property_state"] &&
                  field.name === "property_city") ||
                (!breProperty["property_city"] &&
                  field.name === "builder_name_id") ||
                (!breProperty["builder_name_id"] &&
                  field.name === "project_name") ? null : [
                    "text",
                    "number-format",
                  ].includes(field.type) ? (
                  <fieldset className={"form-filed col-md-6"} key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={(breProperty && breProperty[field.name]) || ""}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      maxLength={field.maxLength}
                      label={field.label}
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={
                        isFormTouch[field.name] ? field.validations : null
                      }
                      validationError={
                        breProperty[field.name] || breProperty[field.name] == ""
                          ? isFormTouch[field.name]
                            ? field.validationError
                            : ""
                          : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                      onChange={
                        ["number-format"].includes(field.type)
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset
                    className={
                      field.fullWidth
                        ? "single-select col-md-12"
                        : "single-select col-md-6"
                    }
                    key={index}
                  >
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: [field.optionsKey],
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) => value == breProperty[field.name]
                          ),
                        isDisabled: field.readOnly,
                      }}
                      required={field.required}
                      value={breProperty && breProperty[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                    {field.name === "builder_name_id" &&
                      breProperty["builder_name_id"] && (
                        <>
                          <div
                            type="button"
                            className="fetchAadharbtn"
                            onClick={handleRemoveBuilder}
                          >
                            Reset
                          </div>
                        </>
                      )}
                  </fieldset>
                ) : null
              )}
            </div>
            {(breProperty["property_type"] === 11 ||
              breProperty["property_type"] === 12) &&
            breProperty["property_sub_type"] === 1 ? (
              <div className="brepropertycheck">
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  id={"check_oc_cc"}
                  name={"check_oc_cc"}
                  checked={breProperty["check_oc_cc"] === "yes" ? true : false}
                />
                <label htmlFor="credit">OC / CC Received</label>
              </div>
            ) : null}

            {breProperty["check_oc_cc"] === "yes" && (
              <div className="brepropertycheck">
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  id={"ready_for_registration"}
                  name={"ready_for_registration"}
                  checked={
                    breProperty["ready_for_registration"] === "yes"
                      ? true
                      : false
                  }
                />
                <label htmlFor="credit">Registration in Process</label>
              </div>
            )}

            <div className="btn-save-remarks">
              <button
                type="submit"
                className="btn-primary"
                disabled={offerBtnDisabled}
              >
                Save
              </button>
            </div>
          </Formsy>
        </div>
      ) : (
        <div className="btn-save-remarks">
          <button
            type="button"
            className="btn-primary"
            disabled={offerBtnDisabled}
            onClick={handlePropertySave}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default BreProperty;
