import React, { useEffect, useState } from "react";
import Select from 'react-select';
import MasterService from "../../services/MasterService.js";
import { NavLink,Link , Navigate} from 'react-router-dom';
import InputField from "../elements/InputField.js";
import DateFormate from 'dateformat';
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery.js";
import { SCHEME_LIST, SCHEME_LIST_WITH_FILTERS, FINEX_MASTER_LIST, SCHEME_COPY, GET_SCHEME_FINANCER_GROUPS, SCHEME_PARTNER_LISTING, UPDATE_SCHEME_STATUS } from "../../services/scheme.gql.js";
import { toast } from 'react-toastify';
// import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");
import { gql, useApolloClient } from '@apollo/client';
import secureStorage from '../../config/partnerEncrypt.js';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Pagination from "../elements/pagination.js";
import MultiSelect from "../elements/MultiSelect.js";
import verified from "../../webroot/images/verified.svg";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import ConfirmPopUp from "../elements/ConfirmPopUp.js";

const SchemeListing = (props) => {       
    const client = useApolloClient();
    const [partnerData, setPartnerData] = useState({ partner_status: 0, page_no: 1, search_by: "", search_text: "", is_subscribed: 1, scheme_id: props.schemedetailsData?.id });
    const [filterData, setFilterData] = useState({
        fulfillment_type: null,
        expiry_from: null,
        expiry_to: null,
        product_type: [],
        scheme_type: null,
        page_no: 1,
        scheme_name: null,
        approved_status: "0",
        city_ids: [],
        scheme_for: null,
        product_type_ids: [],
        financier_id: []
    });
    const [page_no, setPageNo] = useState(1);
    const [cityAllList, setcityAllList] = useState([]);
    const [selectedCityIds, setSelectedCityIds] = useState([]);
    const [schemeData, setSchemeList] = useState([]);
    const [paginationdata, setPaginations] = useState({});
    const [pageloading, setpageloading] = useState(false)
    const [financers, setFinancers] = useState([]);
    const [productType, setProductType] = useState([]);
    const [loanType, setLoanType] = useState([]);
    const [financersData, setFinancersData] = useState([]);
    const [fulfillmenttypes, setFulfillmentTypes] = useState([])
    const [selectedRow, setSelectedRow] = useState(null);
    const [rangeList, setRangeList] = useState([]);
    const [partnerListing, setpartnerListing] = useState([])
    const [filterOptions, setFilterOptions] = useState({});
    const [filter_params, setFilterParams] = useState({});
    const [is_notification, setIsSendNotifications] = useState('yes');
    const [statusClassname, setStatusClassname] = useState({
        'Pending': 'nav-item active',
        'Rejected': 'nav-item',
        'Approved': 'nav-item',
        // 'blacklist': 'nav-item',
        // 'pending': 'nav-item',
        // 'all': 'nav-item'
    });
    const [showConfirmBox, setShowConfirmation] = useState(false)
    const [schemeIDToApprove, setSchemeIdToApprove] = useState(0)
    const filterOption = [
        { value: "partner_id", label: "Partner ID" },
        { value: "scheme_id", label: "Scheme ID" },
        { value: "range_id", label: "Range ID" },
        { value: "financer_group_id", label: "Finance Group ID" },
        { value: "scheme_name", label: "Scheme Name" }
      ];

      const defaultArray = [
        { value: "1", label: "yes" },
        { value: "0", label: "No" }
       
      ];

      const schemeTypeArray = [
        { value: "standard", label: "Standard" },
        { value: "promotional", label: "Promotional" }
       
      ];

    let scheme_create =  IS_USER_ACCESS_PAGE("payout", "scheme_create")?.is_edit_access;
    let scheme_review =  IS_USER_ACCESS_PAGE("payout", "scheme_review")?.is_edit_access;
    let is_disable_notification =  IS_USER_ACCESS_PAGE("scheme_disable_notification")?.is_edit_access;
    
    const handleRowClick = (index, scheme_id) => {
        setSelectedRow(selectedRow === index ? null : index);
        GetSchemeRangesList(scheme_id)
        getPartnerListWithFilters(scheme_id)
    };
 
    const setNotifications = (e)=>{
        const checked = e.target.checked;
        if(checked){
            setIsSendNotifications("yes")
        }else{
            setIsSendNotifications("no")
        }
    }

    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Navigate to={"/login"} />);
        }
    }

    const afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Navigate to={"/"} />);
        }
    }

    const handleInputarray = (e) => {
        const { id, value } = e.target;
        setFilterParams((current) => ({
          ...current,
          [id]: value,
        }));
      };
      
    const handleSelectarry = (id, data) => {
        setFilterParams((current) => ({
          ...current,
          [id]: data.value || data.id || data.value,
        }));
      };
    const handleStatusClick = (sname, ovalue) => {
        const filterDatanew = { ...filterData };
        if (sname === 'Approved') {
            filterDatanew['approved_status'] = '1';
        } else if (sname === 'Pending') {
            filterDatanew['approved_status'] = '0';
        }else if (sname === 'Rejected') {
            filterDatanew['approved_status'] = '2';
        }
        //  else if (sname === 'all') {
        //     filterDatanew['approved_status'] = "all";
        // }
        setFilterData(filterDatanew);

        if (typeof props.statusClick === 'function') {
            props.statusClick(sname, ovalue);
            setStatusClassname({
                'Pending': 'nav-item',
                'Rejected': 'nav-item',
                'Approved': 'nav-item',
                [sname]: 'nav-item active'
            });
        }
        getSchemeListWithFilters(filterDatanew['approved_status'] ,1)
    }


    const handleCityOptionChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.id);
        const filterDataNew = { ...filterData }
        filterDataNew['city_ids'] = selectedIds
        setFilterData(filterDataNew)
        setSelectedCityIds(selectedIds);
    };



    const submitFilterForm = (event) => {
        event.preventDefault();
    };

    const resetFilterForm = (event) => {
        window.location.reload(false);
    };

    const getCityList = async () => {
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                if (response.data.status == 200) {
                    setcityAllList(response.data.data.city)
                    localStorage.setItem("citylist", JSON.stringify(response.data.data.city));
                }
            })
            .catch(function (response) {
            });
    }
    
    const getRangeLabel = (amount) => {
        if (amount >= 1000000000) return "100cr";
        if (amount >= 900000000) return "90cr";
        if (amount >= 800000000) return "80cr";
        if (amount >= 700000000) return "70cr";
        if (amount >= 600000000) return "60cr";
        if (amount >= 500000000) return "50cr";
        if (amount >= 400000000) return "40cr";
        if (amount >= 300000000) return "30cr";
        if (amount >= 200000000) return "20cr";
        if (amount >= 100000000) return "10cr";
        if (amount >= 90000000) return "9cr";
        if (amount >= 80000000) return "8cr";
        if (amount >= 70000000) return "7cr";
        if (amount >= 60000000) return "6cr";
        if (amount >= 50000000) return "5cr";
        if (amount >= 40000000) return "4cr";
        if (amount >= 30000000) return "3cr";
        if (amount >= 20000000) return "2cr";
        if (amount >= 10000000) return "1cr";
        return "0"; // Default case if amount is below the lowest range
      };
      
    const getSchemeListWithFilters = async (status, page_no) => {
      
        try {
            const filters = {...filter_params}
            filters.status = status ? status : filterData.approved_status
            filters.page_no = page_no;
            if(filter_params.filter_params){
                filters[filter_params.filter_params] = filter_params.filter_params == "scheme_name" ? filter_params.app_id.toString() : +filter_params.app_id
                delete filters.filter_params
                delete filters.app_id
            }
            
            const data = {};
            const variables = {
                schemefilterargs:filters,
            };
            data.addSchemeArgs = variables;
            const result = await executeGraphQLMutation(SCHEME_LIST_WITH_FILTERS,variables) ;

            if (result && result.data && result.data.scheme_list.length == 0) {
                //   toast.success("No records found!");
                setSchemeList([]);
                setPaginations({});
                setpageloading(false)

            }
            if (result.data.scheme_list.length > 0) {

                if (result.data.scheme_list && result.data.scheme_list.length > 0) {
                    setSchemeList(result.data.scheme_list[0].scheme_list);
                    setPaginations(result.data.scheme_list[0].pagination);
                    setpageloading(false)
                }

            }
        } catch (error) {
            toast.error("Failed");
        }
    }
   const searchFilter = async () =>{
       getSchemeListWithFilters("",1)
   }
    const GetSchemeRangesList = async (scheme_id) => {
        try {
            if (scheme_id && scheme_id > 0) {
                const schemeFGDataList = await executeGraphQLQuery(GET_SCHEME_FINANCER_GROUPS(scheme_id))
                if (schemeFGDataList && schemeFGDataList.data && schemeFGDataList.data.schemeFinancerGroupbySchemeId) {
                    const financerGroupListNew = schemeFGDataList.data.schemeFinancerGroupbySchemeId;
                    setRangeList(financerGroupListNew);
                }
            }
        } catch (error) {
        }
    };

    const showFinancers = (financer_id,) => {
        const financerobj = financers.find(o => o.id === financer_id);
        return (
            <div>
                {financerobj && financerobj.name}
            </div>
        )
    }
    const showProductType = (product_type,) => {
        const productName = productType.find(o => o.id === product_type);
        return (
            <div>
                {productName && productName.name}
            </div>
        )
    }

    const getPartnerListWithFilters = async (scheme_id) => {

        try {
            const result = await executeGraphQLMutation(SCHEME_PARTNER_LISTING(scheme_id, partnerData.search_by, partnerData.search_text, partnerData.partner_status, page_no, partnerData.is_subscribed=1));
            if (result.data.partners.length == 0) {
                setpartnerListing([]);
            }
            if (result) {
                if (result.data.partners && result.data.partners.length > 0) {
                    const partners = result.data.partners[0].dealers;
                    setpartnerListing(partners);
                  
                }
            } else {
                setpartnerListing([]);
            }
        } catch (error) {
            toast.error("Failed");
        }
    }

    const handleStatusVerified = async (scheme_id,approved_status_new) => {

        let confirmMessage = "Do you want to update scheme approved status!"

        if (!window.confirm(confirmMessage)) {
            return false
        } else {
            try {
                if (scheme_id && scheme_id > 0) {
                    const result = await executeGraphQLQuery(UPDATE_SCHEME_STATUS(scheme_id,approved_status_new))
                   window.location.reload();
                }
            } catch (error) {
            }
        }
    };

    

    const copyScheme = async (id) => {
        setpageloading(true)
        if (!window.confirm("Do you want to create a duplicate scheme of this scheme!")) {
            return false
        } else {
            try {
                const result = await executeGraphQLQuery(SCHEME_COPY(id));

                if (result?.data?.copy_scheme?.id) {
                    toast.success(result?.data?.copy_scheme?.msg)
                } else {
                    toast.error(result?.data?.copy_scheme?.msg || "Something went wrong!")
                }

            } catch (error) {
                toast.error("Failed");
            }
            setpageloading(false)
        }

    }

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST() ).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const producttypedata = []
            const fulfillmenttypeData = []
            const loantypedata = []
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
            const loanTypeArray = data.loan_sub_type && data.loan_sub_type.length > 0 ? data.loan_sub_type : []
            
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
            }
            if (product_type && product_type.length > 0) {
                product_type.map(ptype => {
                    producttypedata.push({ id: ptype.id.toString(), name: ptype.label })
                })
                setProductType(producttypedata)
            }
            if (loanTypeArray && loanTypeArray.length > 0) {
                loantypedata.push({ id: "all", name: "All" })
                loanTypeArray.map(ltype => {
                    loantypedata.push({ id: ltype.id.toString(), name: ltype.label })
                })
                setLoanType(loantypedata)
            }
            const fulfillmenttype = data.fulfillment_type && data.fulfillment_type.length > 0 ? data.fulfillment_type : []
            if (fulfillmenttype && fulfillmenttype.length > 0) {
                fulfillmenttype.map(ptype => {
                    fulfillmenttypeData.push({ id: ptype.id, name: ptype.label })
                })
                setFulfillmentTypes(fulfillmenttypeData)
            }
        }

    }
   

    useEffect(() => {
        getCityList();
        getSchemeListWithFilters(0,1)
        getFinexMasterData()
        updateReduxData()

    }, []);

    const onPaginate = (pageNumber) => {
        setFilterParams({ ...filter_params, page_no: pageNumber });
        getSchemeListWithFilters(filterData.approved_status, pageNumber)
    };


    const calculation = [
        { value: "count", label: "Count" },
        { value: "amount", label: "Amount" }
    ]

    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {

            case 'financier_id':
                let financier_ids = [];
                financier_ids = v.map(obj => { return obj.id });
               // setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
                setFilterParams({ ...filter_params, ['financier_id']: financier_ids });
                break;

        }
    }

    const handleApproveScheme = (schemeId, status) => {
        setSchemeIdToApprove(schemeId) 
        setShowConfirmation(true);
    }
    const confirmYes = async () => {
        setShowConfirmation(false);
        setpageloading(true)
        try {
            if (schemeIDToApprove && schemeIDToApprove > 0) {
                const result = await executeGraphQLQuery(UPDATE_SCHEME_STATUS(schemeIDToApprove,1,is_notification))
                window.location.reload();
            }
        } catch (error) {
            toast.error("Failed");
        }
        setpageloading(false)
    };

    const confirmNo = () => {
        setShowConfirmation(false);
    };
    return (
        <>
            <div className={(pageloading) ? "container-fluid loading" : "container-fluid"}>
                {/* {JSON.stringify(filter_params)} */}
                <h2 className="top-heading">Scheme Listing</h2>
                <div className="search-wrap">
                    <form onSubmit={submitFilterForm}>
                        <div className="row">
                            {/* <div className="col-sm-2 col-md-2">
                                <InputField
                                    inputProps={{
                                        id: "scheme_name",
                                        type: "text",
                                        name: "scheme_name",
                                        autocompleate: "off",
                                        label: "Scheme Name",

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div> */}
                        <div className="col-sm-2 col-md-2">
                            <label>Search By</label>
                            <Select
                                id="filter_params"
                                options={filterOption}
                                name="filter_params"
                                onChange={(data) => handleSelectarry("filter_params", data)}
                                values={filterOption.value}
                            />
                            </div>

                            <div className="col-sm-2 col-md-2">
                            <InputField
                                inputProps={{
                                id: "app_id",
                                type: "text",
                                name: "app_id",
                                autocompleate: "off",
                                label: "Search Value",
                                placeholder: "Search By",
                                }}
                                value={filter_params["app_id"]}
                                onChange={handleInputarray}
                            />
                            </div>
                            
                            <div className="col-sm-2">
                                <label>Fulfillment Type</label>
                                <Select
                                    id="fulfillment_type"
                                    onChange={(data) => handleSelectarry("fulfillment_type", data)}
                                    options={fulfillmenttypes}
                                    name="fulfillment_type"
                                    values={filterOption.value}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}

                                />
                            </div>
                            <div className="col-sm-2">
                                <label>Scheme For</label>
                                <Select
                                    id="scheme_for"
                                    name="scheme_for"
                                    options={calculation}
                                    values={filterOption.value}
                                    onChange={(data) => handleSelectarry("scheme_for", data)}
                                >
                                </Select>
                            </div>

                            <div className="col-sm-2">
                                <label>Product Type<span className="required" style={{ color: 'red' }}>*</span></label>
                                <Select
                                    id="product_type"
                                    onChange={(data) => handleSelectarry("product_type", data)}
                                    options={productType}
                                    label={"Select Product Type"}
                                    name="product_type"
                                    values={filterOption.value}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>
                            <div className="col-sm-2">
                                <label>Product Sub Type<span className="required" style={{ color: 'red' }}>*</span></label>
                                <Select
                                    id="loan_type"
                                    onChange={(data) => handleSelectarry("loan_type", data)}
                                    options={loanType}
                                    label={"Select Product Type"}
                                    name="loan_type"
                                    values={filterOption.value}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>
                            <div className="col-sm-1 col-md-1">
                            <label>Type</label>
                            <Select
                                id="scheme_type"
                                options={schemeTypeArray}
                                name="scheme_type"
                                onChange={(data) => handleSelectarry("scheme_type", data)}
                                values={filterOption.value}
                            />
                            </div>
                            <div className="col-sm-1 col-md-1">
                            <label>Default</label>
                            <Select
                                id="is_default"
                                options={defaultArray}
                                name="is_default"
                                onChange={(data) => handleSelectarry("is_default", data)}
                                values={filterOption.value}
                            />
                            </div>

                            

                            <div className="col-sm-2">
                            {/* <label>Expiry From Date</label> */}
                                <InputField
                                    inputProps={{
                                        id: "expiry_from",
                                        type: "date",
                                        name: "expiry_from",
                                        autocompleate: "off",
                                        label: ('Expiry From'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className="col-sm-2">
                            {/* <label>Expiry To Date</label> */}
                                <InputField
                                    inputProps={{
                                        id: "expiry_to",
                                        type: "date",
                                        name: "expiry_to",
                                        autocompleate: "off",
                                        label: ('Expiry To'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>

                            <div className="col-sm-2 col-md-2">
                                <label>Banks<span className="required" style={{ color: 'red' }}>*</span></label>
                                {/* <Select
                                    id="financer_id"
                                    onChange={(e) => handleFinancersOptionChange('financer_id', e)}
                                    options={financers}
                                    label={"Select Financers"}
                                    isMulti
                                
                                    name="financer_id"
                                    value={financers && financers.length > 0 && filterData.financersids.length > 0 && financers.filter(({ id }) => filterData.financersids.includes(id))}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                /> */}
                                <MultiSelect
                                    options={financers}
                                    placeholder={"Banks"}
                                    name={'financier_id'}
                                    optionLabel="name"
                                    optionValue="id"
                                    values={filter_params.financier_id}
                                    onChange={handleChange}
                                    isMulti
                                />
                            </div>
                           

                            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
                                <div className="btn-submit-reset top-btn-none pull-right">
                                <button type="submit" className="btn btn-primary mrg-r10" onClick={searchFilter}>{('Search')}</button>
                                <button type="reset" className="btn-reset"
                                    onClick={resetFilterForm}
                                >{('Reset')}</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>


                <div className="pad-15">
                    <div className="row">
                        <div className="col-sm-10">
                            <ul className="list-inline nav-tabs">
                                {/* <li className={statusClassname.active} onClick={() => handleStatusClick('active')}>
                                    <div>
                                        {('Active')}<span></span>
                                    </div>
                                </li>
                                <li className={statusClassname.inactive} onClick={() => handleStatusClick('inactive')}>
                                    <div>
                                        {('Inactive')}<span></span>
                                    </div>
                                </li>*/}
                                <li className={filterData.approved_status == '0' ? "tabs-item active" : "tabs-item"} onClick={() => handleStatusClick('Pending')}>
                                    <div>
                                        {('Pending')}<span></span>
                                    </div>
                                </li>
                                <li className={filterData.approved_status == '1' ? "tabs-item active" : "tabs-item"} onClick={() => handleStatusClick('Approved')}>
                                    {/* <div>
                                        {('All')}<span>({paginationdata.totalrecords ? paginationdata.totalrecords : 0})</span>
                                    </div> */}
                                    <div>
                                        {('Approved')}
                                    </div>
                                </li>
                                <li className={filterData.approved_status == '2' ? "tabs-item active" : "tabs-item"} onClick={() => handleStatusClick('Rejected')}>
                                    <div>
                                        {('Rejected')}<span></span>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="col-sm-2">
                        <div className="text-right">
                            {scheme_create === 1 && (
                                        <NavLink to="/schemes/addscheme" className="add-schemebtn">
                                        {('Add Scheme')}
                                    </NavLink>
                            )}
                          
                        </div>
                        </div>
                    </div>
                </div>


                <div className="clearfix">
                    <div className="table-responsive">
                        <table className="table table-bordered tablefontsize">
                            <thead>
                                <tr>
                                    <th>{('ID')}</th>
                                    <th>{('Name')}</th>
                                    <th>{('Fulfillment Type')}</th>
                                    <th>{('Type')}</th>
                                    <th>{('Scheme For')}</th>
                                    <th>{('Start Date')}</th>
                                    <th>{('End Date')}</th>
                                    <th>{('Default')}</th>
                                    <th>{('Financers')}</th>
                                    <th>{('Action')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schemeData && schemeData.map((scheme, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>{scheme.id}</td>
                                            <td>{scheme.scheme_name}</td>
                                            <td>{scheme.fulfillment_type}</td>
                                            <td>{scheme.scheme_type}</td>
                                            <td>{scheme.scheme_for}</td>
                                            <td>{DateFormate(new Date(scheme.start_date), "dS mmm yyyy")}</td>
                                            <td>{DateFormate(new Date(scheme.end_date), 'dS mmm yyyy')}</td>
                                            <td>{scheme.is_default == '1' ? "Yes" : "No"}</td>
                                            <td>{scheme.is_all_financers == '1' ? "All" : "Selective"}</td>
                                            <td>
                                                <div className="btn-group-flex">
                                                <div className="btn-group btn-group-small mrg-r5">
                                                    <div className="btn btn-default">
                                                        <i onClick={() => copyScheme(scheme.id)} className="ic-content_copy icons-normal" title="Copy Duplicate"></i>
                                                    </div>
                                                    {/* <Link to="#" className="btn btn-default" >Copy</Link> */}
                                                </div>

                                                <div className="btn-group btn-group-small mrg-r5">
                                                    <Link to={`/scheme/${scheme.scheme_code}/details?type=${scheme.id}&default=${scheme.is_default}`} style={{color:"gray"}} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                                </div>
                                                <div className="btn-group btn-group-small mrg-r5">
                                                    <div className="btn btn-default" title="Mark as View" onClick={() => handleRowClick(index, scheme.id)}><i className="ic-remove_red_eyevisibility icons-normal"></i></div>
                                                </div>
                                              {scheme_review === 1 && (
                                                <>
                                                 <div className="btn-group btn-group-small mrg-r5">
                                                 {scheme.approved_status === 0 ? (
                                                     <div className="btn btn-default" title="Mark as Verified" onClick={() => handleApproveScheme(scheme.id,1)}>
                                                         <i className="ic-check icons-normal"></i>
                                                     </div>
                                                 ) : null}
                                                 {scheme.approved_status === 1 ?(
                                                     <div className="btn btn-default" style={{cursor:"not-allowed"}}>
                                                          <i className="ic-check icons-normal"></i>
                                                             {/* <img src={verified} alt='icon' width={24} height={24} /> */}
                                                     {/* <img src={require('../../webroot/images/verified.svg')} alt="verfied" width={24} height={24} /> */}
                                                     </div>
                                                 ) : null}
                                             </div>
                                             {scheme.approved_status === 0 ?(
                                                <div className="btn-group btn-group-small mrg-r5">
                                                    <div className="btn btn-default" title="Mark as Reject" onClick={() => handleStatusVerified(scheme.id,2)}><i className="ic-clearclose icons-normal"></i></div>
                                                </div>
                                             ):null}
                                             </>
                                              )}
                                               
                                                </div>


                                            </td>
                                        </tr>
                                        {selectedRow === index ? (
                                            <tr>
                                                <td colSpan={10}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ flex: '60%', marginRight: '20px', width: '100%' }}>
                                                            <div style={{ overflow: 'hidden', overflowY: 'auto', height: '200px', width: '100%' }}>
                                                                <table className="table table-bordered" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="payouttable">Payout Ranges</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {rangeList && rangeList.length > 0 && rangeList.map((scheme, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <tr style={{ width: '100%' }}>
                                                                                    <td style={{ width: '40%' }}>
                                                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                                            {scheme.schemebanks.map((bank, idx) => (
                                                                                                <div key={idx} style={{ marginRight: '10px' }}>
                                                                                                    <span>{showFinancers(bank.financer_id)}</span>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ width: '25%' }}>{showProductType(scheme.product_type)}</td>
                                                                                    <td style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                                        <div style={{ marginRight: '10px' }}>
                                                                                            {scheme.schemerange.map((range, idx) => (
                                                                                                <div key={idx}>{getRangeLabel(range.start_range)} - {getRangeLabel(range.end_range)}</div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ width: '8%' }}>
                                                                                        <div>
                                                                                            {scheme.schemerange.map((range, idx) => (
                                                                                                <div key={idx}>{range.payout} % </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div style={{ flex: '40%', width: '100%' }}>
                                                            <div style={{ overflow: 'hidden', overflowY: 'auto', height: '200px', width: '100%' }}>
                                                                <table className="table table-bordered" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="payouttable">Partners</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                                <td style={{ width: '33%' }}>{"Partner ID"}</td>
                                                                                <td style={{ width: '33%' }}>{"Partner Name"}</td>
                                                                                <td style={{ width: '33%' }}>{"Organization"}</td>
                                                                            </tr>
                                                                        {partnerListing && partnerListing.length > 0 && partnerListing.map((payout, index) => (
                                                                            <tr key={payout.id}>
                                                                                <td style={{ width: '33%' }}>{payout.id}</td>
                                                                                <td style={{ width: '33%' }}>{payout.first_name}</td>
                                                                                <td style={{ width: '33%' }}>{payout.organization}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null}

                                    </React.Fragment>

                                ))}

                                {schemeData.length == 0 ?
                                    <tr>
                                        <td colSpan={8}>No scheme found!</td>
                                    </tr> : ""
                                }

                            </tbody>
                            <tr>
                                <td colSpan={7}>
                                    <div>
                                        <Pagination pageCount={paginationdata && paginationdata.totalpage ? paginationdata.totalpage : 0} activePage={filterData.page_no ? filterData.page_no : 1} onPaginate={onPaginate} />
                                    </div>
                                </td>
                                <td colSpan={1}> Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords : 0} </td>
                            </tr>
                        </table>
                    </div>

                </div>

            </div>
          
            {showConfirmBox && (
                <ConfirmPopUp
                    confirmNo={confirmNo}
                    confirmYes={confirmYes}
                    setNotifications={setNotifications}
                    is_notification = {is_disable_notification ? is_notification : 0}
                    is_disable_notification = {is_disable_notification}
                    title="Confirm & Share Scheme"
                    message="Are you sure your scheme is ready for approval?  <br/><br/>Once approved, the scheme will be shared with all the associated partners via Whatsapp and Email. <br/><br/>If you make any changes to the scheme post approval, you will have to re-approve the scheme and the updated scheme will again be sent."
                />
            )}
        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(SchemeListing));

